import { useState, useRef, useEffect } from "react";
import { Row, Tabs, Tab, Button } from "react-bootstrap";

import { useLocation } from "react-router-dom";
import { getbyrecordno } from "../../Services/wpq_groove-services";
import {
  detailprocess,
  MessageTypes,

  WPQHeader,
} from "./WPQObjects/WPQ_entity";

import { ToastContainer, toast } from "react-toastify";
import { Action, Status } from "./WPQObjects/WPQ_entity";
import WPQ_General from "./WPQ_General";
import WPQ_GetDetails from "./WPQ_GetDetails";
import { getdetailsById } from "../../Services/wpq_details-service";
import Test_and_Certificate from "./Test_and_Certificate";
import { GridLoader } from "react-spinners";
import { bluestyle, errorstyle, normalstyle } from "../../functions/welding-functions";

const WPQGrooveTransaction = () => {
  const [key, setKey] = useState(1);

  const firstChildRef = useRef();
  const secondChildRef = useRef();
  const thirdChildRef = useRef();

  const location = useLocation();

  let UserName = sessionStorage.getItem("UserName");
  let UserId = sessionStorage.getItem("UserId");

  const [copydata, setcopydata] = useState("");
  const [action, setaction] = useState(location.state?.action);
  const [originalaction, setoriginalaction] = useState(location.state?.action);
  const [WPStype, setWPStype] = useState(location.state?.WPStype);

  const [daysRemainingList, setDaysRemainingList] = useState([]);


  const [headerObj, setheaderObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
      ? location.state?.data
      : WPQHeader(WPStype)
  );




  let Band = sessionStorage.getItem("Band");

  let initiator = Band !== "P" && Band !== "T";

  let finalApprover = Band === "P" || Band === "T";

  const [loading, setLoading] = useState(true);
  const [allerror, setallerror] = useState([]);


  let readonly =
    location.state?.action === Action.ActionCopy ||
    (initiator && headerObj?.WPSH_Status === Status.StatusPending)
      ? true
      : false;

  var disableButton =
    initiator && headerObj?.WPSH_Status === Status.StatusPending ? true : false;
  const [isDisabled, setIsDisabled] = useState(disableButton);

  const [isReadonly, setIsReadonly] = useState(readonly);

  let Message = MessageTypes.Message;

  const [detailObj, setdetailObj] = useState([]);
  const [resultdata, setresultdata] = useState([]);

  const [testObj, setTestObj] = useState([]);

  const temparray = [];



  const testarray = [];



  useEffect(() => {
    if (action === Action.ActionAdd) {
      let temparray = createDetailObj(1);
      setdetailObj(temparray);
      detailObj.wld_createdon = resultdata.wld_createdon;

      let testarray = createTestObj(1);
      setheaderObj({ ...headerObj, testarray });
    }
  }, []);




  const [processObj, setprocessObj] = useState([]);

  function createDetailObj(start) {
    const temparray = [];

    for (let i = start; i <= 3; i++) {
      const objFromDetailProcess = detailprocess();
      const wldCreatedOnString = objFromDetailProcess?.wld_createdon;


      const newObj = { ...objFromDetailProcess };

      if (wldCreatedOnString) {
        const [day, month, year] = wldCreatedOnString.split("-").map(Number);
        const wldCreatedOnDate = new Date(year, month - 1, day);

        const lastWeldDate = new Date(
          wldCreatedOnDate.getTime() + 178 * 24 * 60 * 60 * 1000
        );
        const currentDate = new Date();

        const timeDiffMilliseconds = lastWeldDate - currentDate;
        const timeDiffDays = Math.ceil(
          timeDiffMilliseconds / (1000 * 3600 * 24)
        );


        newObj.remainingday = timeDiffDays < 0 ? "InActive" : "Active";
      } else {
        newObj.remainingday = "";
      }

      temparray.push(newObj);
    }

    return temparray;
  }

  function createTestObj(start) {
    for (let i = start; i <= 4; i++) {
      testarray.push({
        SK: "",
        PK: "",
        WPQ_WelderId: "",
        WPQ_RecordNo: "",

        WPQ_WP_TypeOfTest: "",
        WPQ_WP_Acceptance_Criteria: "",
        WPQ_WP_Result: "",
        WPQ_WP_Comments: "",
        WPQ_WP_Report_Nummber: "",

        status: "Active",
        wld_WPSType: WPStype,
      });
    }

    return testarray;
  }

  let wps_detail = detailObj.filter((item) => item.WPQ_Process !== "");

  if (wps_detail.length <= 0) {
    wps_detail = detailObj;
  }

  let detaildata = detailObj.filter((item) => item.WPQ_Process !== "");


  if (daysRemainingList.length > 0) {
    // Accessing and logging remainingday property of the first object in the array

  } else {

  }

  const [logObj, setlogObj] = useState({
    WPSH_Project_No_log:
      headerObj !== undefined ? headerObj?.WPSH_Project_No : "",
    WPSH_WPS_No_log: headerObj !== undefined ? headerObj?.WPSH_WPS_No : "",
    WPSH_User_Name_log: UserName,
    WPSH_User_Id_log: UserId,
    WPSH_Status_log: "",
  });
 
  const fetchprojectbyid = async (IdprojectPK, IdprojectSK) => {
    const result = await getbyrecordno(WPStype, IdprojectPK, IdprojectSK);


    if (result != undefined) setheaderObj(result[0]);
  };

  const fetchbyid = async (IddetailPK, IddetailSK) => {
    const result = await getdetailsById(IddetailPK, IddetailSK);
    setresultdata(result);

    if (action !== Action.ActionAdd) {
      const updatedDetailObj = result.map((item) => {
        const objFromDetailProcess = detailprocess();
        const wldCreatedOnString = item?.wld_createdon;

        const newObj = { ...item };

        if (wldCreatedOnString) {
          const [day, month, year] = wldCreatedOnString.split("-").map(Number);
          const wldCreatedOnDate = new Date(year, month - 1, day);

          const lastWeldDate = new Date(
            wldCreatedOnDate.getTime() + 178 * 24 * 60 * 60 * 1000
          );
          const currentDate = new Date();

          const timeDiffMilliseconds = lastWeldDate - currentDate;
          const timeDiffDays = Math.ceil(
            timeDiffMilliseconds / (1000 * 3600 * 24)
          );


          newObj.remainingday = timeDiffDays < 0 ? "InActive" : "Active";
        } else {
          newObj.remainingday = "";
        }

        return newObj;
      });

      var len = updatedDetailObj.length;
      var loop = 4 - len;
      let temparray1 = [];
      if (loop > 0) {
        temparray1 = createDetailObj(len + 1);
        setdetailObj([...updatedDetailObj, ...temparray1.slice(0, loop)]);
      } else {
        setdetailObj(updatedDetailObj.slice(0, 4));
      }
    }
  
  };

  useEffect(() => {
    if (action != Action.ActionAdd) {
      let IdprojectPK = location.state?.data.WPQ_WelderId;
      let IdprojectSK = location.state?.data.WPQ_RecordNo;

      fetchprojectbyid(IdprojectPK, IdprojectSK);



      fetchbyid(IdprojectPK, IdprojectSK);

    }
    setTimeout(() => {
      setLoading(false);
    }, 500);

    if (action === Action.ActionCopy) {
      setcopydata(true);
    }
  }, []);




  const next = async (e) => {
    var res = false;
    var val = false;

    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNext();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNext2();
        break;
      case 3:
        res = await thirdChildRef.current.funcOnNext();
        break;
      default:
        break;
    }

    setallerror(res)

    if (res == MessageTypes.Success) {
      toast.success("WPQ Data Added ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (key !== 3) setKey(+key + 1);
    } else if (res == MessageTypes.Fail) {
      toast.warning("Failed, WPQ Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning(process.env.REACT_APP_SPECIAL_CHARACTER_MSG, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }

    if (res[0] == MessageTypes.RequiredAll) {
      
      toast.warning(
        "Required Fields not filled.Please check and Fill Required Fields for all 3 Pages.",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
    if (res[1] == "wrong wps") {
      
      toast.warning(
        `Enter Approved Existing WpsRecordNo from WPS ${res[2]} Transaction`,
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
    if (res == MessageTypes.Band) {
      toast.warning(
        "Data Temporary Saved but P and T band welding engineers not present in User Master. Cannot sent for approval",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };



  const handleSelect = (e) => {
    Temp(e, "tab");
  };
  const Temp = async (e, tabaction) => {
    var res = false;
    var val = false;
    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNextTemp();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNextTemp2();
        break;
      case 3:
        res = await thirdChildRef.current.funcOnNextTemp();
        break;
      default:
        break;
    }

    if (res == MessageTypes.Temp) {
      toast.success("Temporary Saved WPQ Data Successfully", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (tabaction == "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction == "next") {
        if (key !== 3) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res == MessageTypes.None) {
      if (tabaction == "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction == "next") {
        if (key !== 3) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res == MessageTypes.Fail) {
      toast.warning("Failed, WPQ Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning(process.env.REACT_APP_SPECIAL_CHARACTER_MSG, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.Band) {
      toast.warning(
        " Cannot sent for approval, P and T band welding engineers not present, data saved temporarily",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  return (
    <>
    {loading && (
<div className="loader-overlay">
<GridLoader size={10} className="pageloader" />
</div>
)}
      <div className="position-absolute ms-3" style={{ top: "15px" ,display:"flex" }}>
        <strong>Add New</strong>        &nbsp;&nbsp;
        <span className="ms-1 warning-text" style={{ fontSize: "small",  position:"relative", top:"3px" }}>
          (All fields are mandatory)
        </span>
        &nbsp;
        <div  className="info_bg" style={{ height:"30px" , width:"425px"}}>
        <span className="ms-1 transnote info-text" style={{ fontSize: "small"}}>
        NOTE: Green color fields will be used for validation in WI session
        </span>
        </div>
      </div>

      <div style={{ border: "1px solid var(--col-ddd)" }}>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
      <div className="mt-4">
      {/* {action === Action.ActionAdd ? <>
        <div style={{borderBottom : "2px solid var(--col-ddd)",position:"relative",top:"34px", zIndex:"-2"}}></div></>
        :""} */}
        <div style={{borderBottom : "2px solid var(--col-ddd)",position:"relative",top:"34px", zIndex:"-2"}}></div>

         
        
        <Tabs
          activeKey={key}
          onSelect={(e) => handleSelect(e)}
          className="mb-2 w-100 border-end"
          fill
        >
           {WPStype === "Overlay" ?
          <Tab
            eventKey={1}
            title="General (Overlay)"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
         
            <WPQ_General
              ref={firstChildRef}
              headerObj={headerObj}
              daysRemainingList={daysRemainingList}
              setDaysRemainingList={setDaysRemainingList}
              setheaderObj={setheaderObj}
              createDetailObj={createDetailObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              normalstyle={normalstyle}
              errorstyle={errorstyle}
              bluestyle={bluestyle}
              WPStype={WPStype}
              setKey={setKey}
              tabno={key}
              toast={toast}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
          :
          <Tab
            eventKey={1}
            title="General(WPQ Groove)"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <WPQ_General
              ref={firstChildRef}
              headerObj={headerObj}
              daysRemainingList={daysRemainingList}
              setDaysRemainingList={setDaysRemainingList}
              setheaderObj={setheaderObj}
              createDetailObj={createDetailObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              normalstyle={normalstyle}
              errorstyle={errorstyle}
              bluestyle={bluestyle}
              WPStype={WPStype}
              setKey={setKey}
              tabno={key}
              toast={toast}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab> }

          <Tab
            eventKey={2}
            title="Get WPQ Detail"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            disabled={action == Action.ActionAdd ? true : false}
          >
            <WPQ_GetDetails
              ref={secondChildRef}
              processObj={processObj}
              setprocessObj={setprocessObj}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              daysRemainingList={daysRemainingList}
              setDaysRemainingList={setDaysRemainingList}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              wps_detail={wps_detail}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
          <Tab
            eventKey={3}
            title="Tests and Certification"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            disabled={action == Action.ActionAdd ? true : false}
          >
            <Test_and_Certificate
              ref={thirdChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              // daysRemainingList={daysRemainingList}
              setdetailObj={setdetailObj}
              testObj={testObj}
              setTestObj={setTestObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
        </Tabs>

        <Row style={{ padding: "5px 90px" }}>
          <div>
            {isDisabled ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary"
                className="me-2"
                disabled={isDisabled}
                style={{
                  display: key === 1 ? "none" : "  ",
                  width: "100px",
                  height: "38px",
                }}
                onClick={(e) => Temp(e, "prev")}
              >
                Prev
              </Button>
            )}

            {isDisabled ? (
              ""
            ) : (
              <Button
                size="sm"
                className="float-end  me-2 btn-primary"
                style={{
                  width: "100px",
                  height: "38px",
                }}
                onClick={(e) => next(e)}
              >
                {key === 3 ? "Done" : "Next"}
              </Button>
            )}

            {isDisabled ? (
              ""
            ) : originalaction !== Action.ActionAdd ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary "
                className="float-end  me-2"
                onClick={(e) => Temp(e, "next")}
                style={{ width: "100px", height: "38px" }}

                // onClick={() => setKey(+key + 1)}
              >
                {key === 3 ? "Temp Save" : "Temp Save"}
              </Button>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

export default WPQGrooveTransaction;
