//WCS SESSION
//1. THIS IS ADD EDIT PAGE OF WCS SESSION.
//2. USERS WITH BAND P OR T CAN APPROVE AND REJECT RECORDS AND OTHER BAND USERS CAN ONLY INITIATE RECORDS.
//3. WPS APPROVED RECORD NO. DATA IS FETCHED IN THE DROPDOWN OF  WPS NO.1, WPS NO.2, WPS NO.3, WPS NO.4 , MINIMUM 1 AND MAXIMUM 4 WPS NO. CAN BE ADDED WITH PROJECT NO. AND JOINT NUMBER.
//4. IN UPLOAD EXCEL FUNCTIONALITY EXCEL CAN BE UPLOADED WITH SAME FIELDS AND WITH APPROVED WPS NO. DATA.
//5. INLINE ROW EDIT IS GIVEN TO EDIT RECORDS.


import { useState, useEffect } from "react";
import { Col, Form, Modal, Row, Alert } from "react-bootstrap";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";

import { isAlphanumericWithDashSlash } from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonModal from "../Modals/buttonModal";
import DragDropRender from "../../utils/DragDropRender";
import { AddNewWCS } from "../../Services/wcs-services";
import { Action, Status } from "./WCSObjects/WCS_entity";
import { ModalSelect } from "../utility/search-select";
import viewNote from "../../icons/viewNote.svg";
import Delete_green from "../../icons/Delete_green.svg";
import Delete from "../../icons/Delete.svg";
import Tooltip from "../utility/Tooltip";
import Error from "../../icons/ErrorIcon.svg";
import { validateWps } from "./WCSObjects/WCS_common";
import { getWPS } from "../WPSTransaction/WPSObjects/WPS_Validations";
import {
  handleExcelUpload,
  handleDeleteDocument,
  handleFileSelect,
} from "../WCSTransaction/WCSObjects/WCS_entity";
import { errorstyle, greystyle } from "../../functions/welding-functions";

const AddEditWCS = (props) => {
  let selectCondition = props.selectProject;

  let action = props.action;
  let formName = "WCS";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [incChangeNo, setIncChangeNo] = useState("");
  const [WPSNoList, setWPSNoList] = useState([]);

  const Project = props.headerObj.WCS_Project_No;
  const [selectProject, setSelectProject] = useState("");

  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [excelData, setexcelData] = useState([]);
  const [disableExlUpload, setdisableExlUpload] = useState(false);
  const [isExcelUploaded, setIsExcelUploaded] = useState(false);
  const [disableMnlEntry, setdisableMnlEntry] = useState(false);
  const [isError, setisError] = useState(false);


  let userName = sessionStorage.getItem("UserName");
  let userEmailId = sessionStorage.getItem("User");
  let Band = sessionStorage.getItem("Band");

  let approver = Band === "P" || Band === "T";

  const [wcsHeader, setWcsHeader] = useState({
    PK: "WCS_HEADER",
    SK: props.headerObj.WCS_Project_No,
    WCS_Project_No: selectCondition,
    WCS_PreparedBy_Name: userName,
    WCS_PreparedBy: userEmailId,
    WCS_PreparedOn: "",
    WCS_ApprovedBy: "",
    WCS_ApprovedBy_Name: "",
    WCS_ApprovedOn: "",
    WCS_Status: Status.StatusTempSaved,
    WCS_Change_No: 0,
  });

  const [wcsDetail, setWcsDetail] = useState([
    {
      PK: selectCondition,
      SK: "",
      WCS_Project_No: selectCondition,
      WCS_Joint_No: "",
      WCS_WPS_No1: "",
      WCS_WPS_No2: "",
      WCS_WPS_No3: "",
      WCS_WPS_No4: "",
      WCS_ChangeDetail_No: 0,
      WCS_PreparedBy_Name: userName,
      WCS_PreparedBy: userEmailId,
      WCS_PreparedOn: "",
      WCS_ApprovedBy: "",
      WCS_ApprovedOn: "",
    },
  ]);
  const [WCSDetaillength, setWCSDetaillength] = useState(1);
  const [error, setError] = useState([
    {
      WCS_Project_No: {},
      WCS_Joint_No: [{}],
      WCS_WPS_No1: [{}],
      WCS_WPS_No2: [{}],
      WCS_WPS_No3: [{}],
      WCS_WPS_No4: [{}],
    },
  ]);

  const fetchWPSNo = async () => {
    var project;
    if (props.headerObj[0] !== undefined)
      project = props.headerObj[0].WCS_Project_No;
    else project = props.headerObj.WCS_Project_No;
    const result = await getWPS(project);
    setSelectProject(project);
    if (result !== undefined) setWPSNoList(result);
  };

  useEffect(() => {
    setSelectProject(Project);
    fetchWPSNo();
  }, []);

  const addTableRows = () => {
    const rowsInput = {
      PK: selectCondition,
      SK: "",
      WCS_Project_No: selectCondition,
      WCS_Joint_No: "",
      WCS_WPS_No1: "",
      WCS_WPS_No2: "",
      WCS_WPS_No3: "",
      WCS_WPS_No4: "",
      WCS_ChangeDetail_No: 0,
      WCS_PreparedBy_Name: userName,

      WCS_PreparedBy: userEmailId,
      WCS_PreparedOn: "",
      WCS_ApprovedBy: "",
      WCS_ApprovedOn: "",
    };

    setWcsDetail([...wcsDetail, rowsInput]);
    setWCSDetaillength(WCSDetaillength + 1);

    const err1 = {
      WCS_Project_No: { status: false, message: "" },
      WCS_Joint_No: [{ status: false, message: "" }],
      WCS_WPS_No1: [{ status: false, message: "" }],
      WCS_WPS_No2: [{ status: false, message: "" }],
      WCS_WPS_No3: [{ status: false, message: "" }],
      WCS_WPS_No4: [{ status: false, message: "" }],
    };
    let error1 = error;

    error1[error.length] = err1;
    setError(error1);
  };


  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };

  const handleChange = (valueIndex, e) => {
    const updatedDetailObj = [...wcsDetail];
    updatedDetailObj[valueIndex][e.target.name] = e.target.value;
  
    setWcsDetail(updatedDetailObj);
  };


  const handleDropDownChange = (valueIndex, id, e) => {
    const updatedDetailObj = [...wcsDetail];
    updatedDetailObj[valueIndex][id] = e.value;

    setWcsDetail(updatedDetailObj);
  };

  const resetForm = () => {
    if (action === Action.ActionAdd) {

      const updatedDetailObj = [...wcsDetail];
      for (let i = 0; i < updatedDetailObj.length; i++) {
        updatedDetailObj[i].WCS_Joint_No = "";
        updatedDetailObj[i].WCS_WPS_No1 = "";
        updatedDetailObj[i].WCS_WPS_No2 = "";
        updatedDetailObj[i].WCS_WPS_No3 = "";
        updatedDetailObj[i].WCS_WPS_No4 = "";
        setWcsDetail(updatedDetailObj);
      }
    }

    setFileName("");
    setFile([]);
    setexcelData([]);
    setdisableExlUpload(false);
    setdisableMnlEntry(false);
  };

  const validateWCS = async (action) => {
    
    let error1 = error;
    let isError = false;

    let WCSError = await Promise.resolve(validateWps(wcsDetail));
    for (let i = 0; i < wcsDetail.length; i++) {
      let message = "";
      let status = false;
      let jointnumber = wcsDetail[i]?.WCS_Joint_No;
      if (jointnumber.trim() === "") {
      
        isError = true;
        message = message + "Joint Number is required.";
        status = true;
      }
      var dupwithinarray =
        wcsDetail.map((object) => object.WCS_Joint_No).indexOf(jointnumber) !==
        i;
      var jointpresent;
      if (WCSError !== undefined) {
        jointpresent = WCSError.filter((i) => i == jointnumber);
      }
      if (
        dupwithinarray ||
        (jointpresent != undefined && jointpresent.length > 0)
      ) {
        message = message + "Joint Number is duplicate/or already mapped.";
        status = true;
      }

      if (isAlphanumericWithDashSlash(jointnumber.trim()).status) {
        wcsDetail[i].WCS_Joint_No = "";
        isError = true;

        status = true;
        message = message + "Special characters not allowed Joint Number.";
      }

      if (status) {
        error1[i].WCS_Joint_No = [
          {
            status: status,
            message: message,
          },
        ];
        isError = true;
      } else {
        error1[i].WCS_Joint_No = [
          {
            status: false,
            message: "",
          },
        ];
      }
      
      if (wcsDetail[i]?.WCS_WPS_No1 === "") {
        isError = true;
        error1[i].WCS_WPS_No1 =[
          {
            status: true,
            message: "WPS No. 1 is required.",
          }
        ]
        // error1[i].WCS_WPS_No1.status = true;
        // error1[i].WCS_WPS_No1.message = "WCS_WPS_No1 is required.";
      } else {
        error1[i].WCS_WPS_No1 = [
          {
            status: false,
            message: "",
          },
        ];
      }

      if (
        wcsDetail[i]?.WCS_WPS_No2 !== "" &&
        wcsDetail[i]?.WCS_WPS_No2 === wcsDetail[i]?.WCS_WPS_No1
      ) {
        isError = true;
       
        error1[i].WCS_WPS_No2 = [
          {
            status: true,
            message: "WCS_WPS_No2 is duplicate.",
          },
        ];
      } else {
        error1[i].WCS_WPS_No2 = [
          {
            status: false,
            message: "",
          },
        ];
      }

      if (
        wcsDetail[i]?.WCS_WPS_No3 !== "" &&
        (wcsDetail[i]?.WCS_WPS_No3 === wcsDetail[i]?.WCS_WPS_No1 ||
          wcsDetail[i]?.WCS_WPS_No3 === wcsDetail[i]?.WCS_WPS_No2)
      ) {
        isError = true;
        error1[i].WCS_WPS_No3 = [
          {
            status: true,
            message: "WCS_WPS_No3 is duplicate.",
          },
        ];
      } else {
        error1[i].WCS_WPS_No3 = [
          {
            status: false,
            message: "",
          },
        ];
      }

      if (
        wcsDetail[i].WCS_WPS_No4 !== "" &&
        (wcsDetail[i].WCS_WPS_No4 === wcsDetail[i].WCS_WPS_No1 ||
          wcsDetail[i].WCS_WPS_No4 === wcsDetail[i].WCS_WPS_No2 ||
          wcsDetail[i].WCS_WPS_No4 === wcsDetail[i].WCS_WPS_No3)
      ) {
        isError = true;
        error1[i].WCS_WPS_No4 = [
          {
            status: true,
            message: "WCS_WPS_No4 is duplicate.",
          },
        ];
      } else {
        error1[i].WCS_WPS_No4 = [
          {
            status: false,
            message: "",
          },
        ];
      }

      setError(error1);
      if (
        error1[i]?.WCS_Joint_No.status ||
        error1[i]?.WCS_WPS_No1.status ||
        error1[i]?.WCS_WPS_No2.status ||
        error1[i]?.WCS_WPS_No3.status ||
        error1[i]?.WCS_WPS_No4.status
      ) {
        isError = true;
      }
    } //end for
    //end for
    setisError(isError);
    return isError;
  };

  // const handleAdd = (e) => {
  const handleAdd = async (e) => {
    let result;
    let result1;
    if (action === Action.ActionAdd) {
      wcsHeader.SK = wcsDetail[0].WCS_Project_No;
      wcsHeader.WCS_Project_No = wcsDetail[0].WCS_Project_No;
      wcsHeader.WCS_PreparedOn = wcsDetail[0].WCS_PreparedOn;
      wcsHeader.WCS_UpdatedOn = wcsDetail[0].WCS_PreparedOn;
      if (incChangeNo) {
        wcsHeader.WCS_Change_No = wcsHeader.WCS_Change_No + 1;
      }

      let addNewRequest = { wcsHeader, wcsDetail };

      result = AddNewWCS(addNewRequest);
    } else {
      setShowForm(true);
    }
    result &&
      result
        .then((resp) => {
          if (action === Action.ActionEdit) {
            toast.success(`Selected WCS has been Updated successfully`, {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            });
          }

          if (resp.message === "Duplicate") {
            toast.warning(resp.message, {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            });
          } else if (action === Action.ActionAdd) {
            toast.success(`Selected WCS has been Added successfully`, {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            });
          }
          props.setReload(!props.reload);
          setShowForm(false);
          props.onHide();
        })
        .catch((error) => {
          console.log("Failed");
        });
  };

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    if (isExcelUploaded) {
      setshowConfirmModel(true);
    }
    let error = await validateWCS(action);
    if (!error) {
      setshowConfirmModel(true);
    }
  };



  let name1 = "WCS_WPS_No1";
  let name2 = "WCS_WPS_No2";
  let name3 = "WCS_WPS_No3";
  let name4 = "WCS_WPS_No4";

  const handlePress = (e: React.KeyboardEvent, index) => {
    if (e.keyCode === 9 && index + 1 === WCSDetaillength) {
      addTableRows();
    }
  };



  return (
    <>
      <SuccessAlertModal
        //clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed WCS successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={
          isExcelUploaded && !isError
            ? handleExcelUpload(
                wcsHeader,
                props,
                excelData,
                showForm,
                setShowForm
              )
            : handleAdd
        }
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>{action} WCS </Modal.Title> */}
          {action === Action.ActionAdd ? (
            <>
              <Modal.Title>{action} WCS </Modal.Title>
              &nbsp; &nbsp;
              <span
                // className="viewNoteLbl"
                //</Col>onClick={hanldeViewNote}
                style={{
                  fontSize: "small",
                  color: "var(--godrej-purple)",
                  backgroundColor: "var(--table-bg)",
                }}
              >
                <img src={viewNote}></img>
                &nbsp;
                <b>
                  Once you tab to next input field then new row will get added
                </b>
              </span>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </>
          ) : (
            <Modal.Title>
              Upload data for Project Number - <b>{props.selectProject}</b>{" "}
            </Modal.Title>
          )}
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            {action === Action.ActionAdd ? (
              <>
                {wcsDetail?.map((dt, index) => {
                 
                  return (
                    <div className="px-2" style={{position:"relative", left:"15px"}}>
                      <Row
                        key={index}
                        className="gx-3 wcsaddtextbox"
                        style={
                          index && index !== 0
                            ? { marginBottom: "1x" }
                            : { marginBottom: "" }
                        }
                      >
                        {isError && (
                          <div style={{ height: ".1vw" }}>
                            {(error[index]?.WCS_Joint_No[0] ||
                              error[index]?.WCS_WPS_No1[0] ||
                              error[index]?.WCS_WPS_No2[0] ||
                              error[index]?.WCS_WPS_No3[0] ||
                              error[index]?.WCS_WPS_No4[0]) &&
                              (error[index]?.WCS_Joint_No[0]?.status ||
                                error[index]?.WCS_WPS_No1[0]?.status ||
                                error[index]?.WCS_WPS_No2[0]?.status ||
                                error[index]?.WCS_WPS_No3[0]?.status ||
                                error[index]?.WCS_WPS_No4[0]?.status) && (
                                <Tooltip
                                  content={
                                    error[index]?.WCS_Joint_No[0]?.message ||
                                    error[index]?.WCS_WPS_No1[0]?.message ||
                                    error[index]?.WCS_WPS_No2[0]?.message ||
                                    error[index]?.WCS_WPS_No3[0]?.message ||
                                    error[index]?.WCS_WPS_No4[0]?.message
                                  }
                                >
                                  <img
                                    src={Error}
                                    alt="Error"
                                    className="ms-1"
                                  />
                                </Tooltip>
                              )}
                          </div>
                        )}
                        <Col
                          lg={2}
                          md={2}
                          style={{ position: "relative", left: "30px" }}
                        >
                          <Form.Group>
                            <div class="addwcs-input">
                              <input
                                type="text"
                                id={"WCS_Project_No"}
                                name={"WCS_Project_No"}
                                placeholder=" "
                                maxLength="15"
                                className={
                                  "addwcs-input placeHolderInput inputText allow-wcs"
                                }
                                value={dt?.WCS_Project_No}
                                disabled={disableMnlEntry}
                              />
                              <label
                                class="required-field"
                                for="WCS_Project_No"
                              >
                                Project Number
                              </label>
                            </div>
                          </Form.Group>
                        </Col>

                        <Col
                          lg={2}
                          md={2}
                          style={{ position: "relative", left: "30px" }}
                        >
                          <Form.Group>
                           
                            <div class="addwcs-input">
                              <input
                                id="WCS_Joint_No"
                                name="WCS_Joint_No"
                                type="text"
                                maxLength="16"
                                class="asterisk"
                                className={
                                  error[index]?.WCS_Joint_No[0]?.status
                                    ? "addwcs-input placeHolderInput inputText text-field-with-error allow-wcs"
                                    : "addwcs-input placeHolderInput inputText allow-wcs"
                                }
                                placeholder=" "
                                value={dt?.WCS_Joint_No}
                                onChange={(e) => handleChange(index, e)}
                              
                                disabled={disableMnlEntry}
                              />
                              <label class="required-field" for="WCS_Joint_No">
                                Joint Number
                              </label>
                              
                            </div>
                          </Form.Group>
                        </Col>

                        <Col
                          md={2}
                          className="mb-2"
                          style={{ position: "relative", left: "25px" }}
                        >
                          <Form.Group className="pro-input-select">
                            <div className="search-select">
                              <ModalSelect
                                data={WPSNoList}
                                stylegreen={
                                  error[index]?.WCS_WPS_No1[0]?.status
                                    ? errorstyle
                                    : greystyle
                                }
                                value={dt?.WCS_WPS_No1}
                                labelField={"WPSH_WPS_No"}
                                valueField={"WPSH_WPS_No"}
                                id={name1}
                                name={name1}
                                placeholder=""
                                className={
                                  error[index]?.WCS_WPS_No1[0]?.status
                                    ? "err-input"
                                    : ""
                                }
                                onChange={(e) =>
                                  handleDropDownChange(index, name1, e)
                                }
                                disabled={disableMnlEntry}
                              ></ModalSelect>
                            </div>
                            <label>WPS No 1.</label>
                          </Form.Group>
                          <div className="required-text-select"></div>
                        </Col>

                        <Col
                          md={2}
                          className="mb-2"
                          style={{ position: "relative", left: "20px" }}
                        >
                          <Form.Group className="pro-input-select">
                            <div className="search-select">
                              <ModalSelect
                                data={WPSNoList}
                                stylegreen={
                                  error[index]?.WCS_WPS_No2[0]?.status
                                    ? errorstyle
                                    : greystyle
                                }
                                value={dt?.WCS_WPS_No2}
                                labelField={"WPSH_WPS_No"}
                                valueField={"WPSH_WPS_No"}
                                id={name2}
                                name={name2}
                                placeholder=""
                                className={
                                  error[index]?.WCS_WPS_No2[0]?.status
                                    ? "err-input"
                                    : ""
                                }
                                onChange={(e) =>
                                  handleDropDownChange(index, name2, e)
                                }
                                disabled={disableMnlEntry}
                              ></ModalSelect>
                            </div>
                            <label>WPS No 2.</label>
                          </Form.Group>
                          <div className="required-text-select"></div>
                        </Col>

                        <Col
                          md={2}
                          className="mb-2"
                          style={{ position: "relative", left: "15px" }}
                        >
                          <Form.Group className="pro-input-select">
                            <div className="search-select">
                              <ModalSelect
                                data={WPSNoList}
                                stylegreen={
                                  error[index]?.WCS_WPS_No3[0]?.status
                                    ? errorstyle
                                    : greystyle
                                }
                                value={dt?.WCS_WPS_No3}
                                labelField={"WPSH_WPS_No"}
                                valueField={"WPSH_WPS_No"}
                                id={name3}
                                name={name3}
                                placeholder=""
                                className={
                                  error[index]?.WCS_WPS_No3[0]?.status
                                    ? "err-input"
                                    : ""
                                }
                                onChange={(e) =>
                                  handleDropDownChange(index, name3, e)
                                }
                                disabled={disableMnlEntry}
                              ></ModalSelect>
                            </div>
                            <label>WPS No 3.</label>
                          </Form.Group>
                          <div className="required-text-select"></div>
                        </Col>

                        <Col
                          md={2}
                          className="mb-2"
                          style={{ position: "relative", left: "10px" }}
                        >
                          <Form.Group className="pro-input-select">
                            <div className="search-select">
                              <ModalSelect
                                data={WPSNoList}
                                stylegreen={
                                  error[index]?.WCS_WPS_No4[0]?.status
                                    ? errorstyle
                                    : greystyle
                                }
                                value={dt?.WCS_WPS_No4}
                                labelField={"WPSH_WPS_No"}
                                valueField={"WPSH_WPS_No"}
                                id={name4}
                                name={name4}
                                placeholder=""
                                className={
                                  error[index]?.WCS_WPS_No4[0]?.status
                                    ? "err-input"
                                    : ""
                                }
                                onChange={(e) =>
                                  handleDropDownChange(index, name4, e)
                                }
                                type="text"
                                onBlur={(e) => handlePress(e, index)}
                                disabled={disableMnlEntry}
                              ></ModalSelect>
                            </div>
                            <label>WPS No 4.</label>
                          </Form.Group>
                          <div className="required-text-select"></div>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </>
            ) : null}

            {action === Action.ActionAdd ? (
              <hr class="hr-text" data-content="OR" />
            ) : null}
            <Row>
              <Col lg={12} md={12} xs={12}>

                  <Form.Label className="wcs-dropzone">Upload File</Form.Label>
                
              </Col>
              {(fileName === "" || fileName === undefined) ? (
                <Col lg={12} md={12} xs={12} className="container-dropzone">
                  <DragDropRender
                    disableExlUpload={disableExlUpload}
                    handleChangeEvent={(params) =>
                      handleFileSelect(
                        params,
                        setFileName,
                        setFile,
                        setIsExcelUploaded,
                        setdisableMnlEntry,
                        setexcelData,
                        props.selectProject,
                        setError
                      )
                    }
                  />
                </Col>
              ) : (
                <Col lg={12} md={12} xs={12} className="fileupload-name">
                  <label>
                    {fileName}
                    &nbsp;&nbsp;&nbsp;
                  </label>
                  {!approver && (
                    <img
                      onMouseOver={(e) => (e.currentTarget.src = Delete_green)}
                      onMouseOut={(e) => (e.currentTarget.src = Delete)}
                      src={Delete}
                      alt="delete"
                      onClick={(params) =>
                        handleDeleteDocument(
                          fileName,
                          setFile,
                          fileName,
                          setFileName,
                          isExcelUploaded,
                          setIsExcelUploaded,
                          disableMnlEntry,
                          setdisableMnlEntry
                        )
                      }
                      hidden={action === "View" ? true : false}
                    />
                  )}
                </Col>
              )}
            </Row>

            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>
          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>
      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditWCS;
