import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import { checkWPSinArray } from "../../WPSTransaction/WPSObjects/WPS_Validations";
import { uploadWCSexceldata } from "../../../Services/wcs-services";
export const MessageTypes = {
  Success: "Success",
  Fail: "Failure",
  Required: "Required",
  RequiredAll: "RequiredAll",
  Temp: "Temp",
  Band: "Band",
  None: "None",
};

export const Action = {
  ActionEdit: "Edit",
  ActionCopy: "Copy",
  ActionAdd: "Add",
  ActionUpload: "Upload",
  ActionDelete: "Delete",
  ActionApprove: "Approve",
  ActionReject: "Reject",
  ActionSend: "Send",
};

export const Status = {
  StatusPending: "Pending For Approval",
  StatusApproved: "Approved",
  StatusRejected: "Rejected",
  StatusTempSaved: "Temporary Saved",
};

export const validateFile = (file) => {
  //const validateFile = (file) => {
  
  let error = "";
  let fileName = file.name;
  let fileExtn =
    fileName.indexOf(".") > 0
      ? fileName.split(".").pop().toLowerCase()
      : "undefined";
  let fileSize = file.size;
  let allowedExtensions = process.env.REACT_APP_FILE_EXTENSIONS;
  if (fileName.match(/\./g).length !== 1) {
    error = error + "File name contains more than one dot. ";
  }
  if (!allowedExtensions.includes(fileExtn)) {
    error = error + "Unsupported file format. ";
  }
  if (fileSize > 5242880) {
    error = error + "File size is greater than 5MB.";
  }
  return error;
};

export const handleExcelUpload = async (
  wcsHeader,
  props,
  excelData,
  showForm,
  setShowForm
) => {
  let result;

  wcsHeader.SK = props.selectProject;
  let uploadExcelRequest = { excelData, wcsHeader };
  result = await uploadWCSexceldata(uploadExcelRequest);

  if (result?.status) {
    toast.success(`Selected WCS file data has been Uploaded successfully`, {
      position: toast.POSITION.TOP_LEFT,
      //className: "toast-message",
    });
    props.setReload(!props.reload);
    setShowForm(false);
    props.onHide();
  } else {
    toast.error(`Selected WCS file data is not Uploaded`, {
      position: toast.POSITION.TOP_LEFT,
      //className: "toast-message",
    });
  }
};

export const handleDeleteDocument = async (
  file,
  setFile,
  fileName,
  setFileName,
  isExcelUploaded,
  setIsExcelUploaded,
  disableMnlEntry,
  setdisableMnlEntry
) => {
  setFileName("");
  setFile([]);
  setIsExcelUploaded(false);
  setdisableMnlEntry(false);
};

function createAndDownloadReport(uploadReport, fileName) {
  
  const finalUploadReport = uploadReport.map((item) => ({
    "Project Number": item.WCS_Project_No,
    "Joint Number": item.WCS_Joint_No,
    "WPS Number 1": item.WCS_WPS_No1,
    "WPS Number 2": item.WCS_WPS_No2,
    "WPS Number 3": item.WCS_WPS_No3,
    "WPS Number 4": item.WCS_WPS_No4,
    Message: item.Message,
    //'Status': item.Status,
  }));
  const ws = XLSX.utils.json_to_sheet(finalUploadReport);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, fileName);
}

export const handleFileSelect = async (
  files,
  setFileName,
  setFile,
 
  setIsExcelUploaded,
  setdisableMnlEntry,
  setexcelData,
  selectProject,
  setError
) => {
  let isError = false;


  const newFile = files[0];
  var name = newFile.name;

  let fileError = validateFile(newFile);
  if (fileError !== "") {
    toast.error(fileError, {
      position: toast.POSITION.TOP_LEFT,
    
    });
    return;
  }
  let fileErr = false;
  const reader = new FileReader();
  reader.onload = async (e) => {

    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
   

    const ws = wb.Sheets[wsname];

    if (
      ws["A1"]?.v &&
      ws["B1"]?.v &&
      ws["C1"]?.v &&
      ws["D1"]?.v &&
      ws["E1"]?.v &&
      ws["F1"]?.v &&
      ws["A1"]?.v === "Project Number" &&
      ws["B1"]?.v === "Joint Number" &&
      ws["C1"]?.v === "WPS Number 1" &&
      ws["D1"]?.v === "WPS Number 2" &&
      ws["E1"]?.v === "WPS Number 3" &&
      ws["F1"]?.v === "WPS Number 4"
    ) {
    
    } else {
      toast.error("Columns were not in order or blank in excel .", {
        position: toast.POSITION.TOP_LEFT,
      });
      return;
    }

  
    const tempData = XLSX.utils.sheet_to_json(ws);

    const mappedData = tempData.map((item) => ({
      WCS_Project_No: item["Project Number"].toString(),
      WCS_Joint_No: item["Joint Number"].toString(),
      WCS_WPS_No1: item["WPS Number 1"] != undefined ? item["WPS Number 1"].toString() : "",
      WCS_WPS_No2: item["WPS Number 2"] != undefined ? item["WPS Number 2"].toString() : "",
      WCS_WPS_No3: item["WPS Number 3"] != undefined ? item["WPS Number 3"].toString() : "",
      WCS_WPS_No4: item["WPS Number 4"] != undefined ? item["WPS Number 4"].toString() : "",
      PK: item["Project Number"].toString(),
      SK: item["Joint Number"].toString(),
      WCS_PreparedBy: sessionStorage.getItem("User"),
      WCS_ChangeDetail_No: 0,
    })); 
   
    if (mappedData <= 0) {
      toast.error("Please select file with valid data.", {
        position: toast.POSITION.TOP_LEFT,
       
      });
      return;
    }
    if (mappedData.length > 1000) {
      toast.error(
        "Limit of excel upload option is 1000. Please upload records within limit.",
        {
          position: toast.POSITION.TOP_LEFT,
         
        }
      );
      return;
    }
    const uploadExcelReport = [];
    let arr_list = [];
    var wps1Arr = mappedData.map(function (item) {
      return { wps: item.WCS_WPS_No1 };
    });
    var wps2Arr = mappedData.map(function (item) {
      return { wps: item.WCS_WPS_No2 };
    });
    var wps3Arr = mappedData.map(function (item) {
      return { wps: item.WCS_WPS_No3 };
    });
    var wps4Arr = mappedData.map(function (item) {
      return { wps: item.WCS_WPS_No4 };
    });
    const allWpsArray = [...wps1Arr, ...wps2Arr, ...wps3Arr, ...wps4Arr];
    let wpsValueArr1 = [
      ...new Map(allWpsArray.map((item) => [item.wps, item])).values(),
    ];
    let wpsArr1 = wpsValueArr1.filter(({ wps }) => wps);

  
    let wpsData1 = {
    
      project: selectProject,
      WPSNo_Array: wpsArr1,
     
    };

    let validWPSArr = await checkWPSinArray(wpsData1);
   
    

    for (const dataObject of mappedData) {
      const notUploadReason = [];

      if (dataObject.WCS_Project_No !== selectProject) {
        notUploadReason.push(
          "Project Number not present in Project Master or multiple project numbers found."
        );
        fileErr = true;
      }
      if (
        dataObject.WCS_Project_No === "" ||
        dataObject.WCS_Project_No === undefined ||
        dataObject.WCS_Joint_No === "" ||
        dataObject.WCS_Joint_No === undefined ||
        dataObject.WCS_WPS_No1 === "" ||
        dataObject.WCS_WPS_No1 === undefined
      ) {
        fileErr = true;
        notUploadReason.push(
          "Mandatory data in Project Number, Joint Number and WPS Number 1 not present in at least one row. Cannot upload data."
        );
      }
      var duplicateJointArr = mappedData.filter(
        (item) => item.WCS_Joint_No === dataObject.WCS_Joint_No.toString()
      );
      
      if (duplicateJointArr.length > 1) {
        fileErr = true;
        notUploadReason.push(
          "Duplicate combination of project number and joint number. Cannot upload data."
        );
      }
      let wpsErr1 = true,
        wpsErr2 = true,
        wpsErr3 = true,
        wpsErr4 = true;
      

      if (validWPSArr?.length > 0) {
        
        if (!dataObject.WCS_WPS_No1 != undefined) {
          validWPSArr.some((element) => {
           
            if (element.trim() === dataObject.WCS_WPS_No1.toString()) {
             
              wpsErr1 = false;
              return;
            }
          });
        } else {
          wpsErr1 = false;
        }
       

        if (dataObject.WCS_WPS_No2 != undefined) {
          validWPSArr.some((element) => {
      
            if (element === dataObject.WCS_WPS_No2.toString()) wpsErr2 = false;
            return;
          });
        } else {
          wpsErr2 = false;
        }
        
        if (dataObject.WCS_WPS_No3 != undefined) {
          validWPSArr.some((element) => {
            if (element === dataObject.WCS_WPS_No3.toString()) wpsErr3 = false;
            return;
          });
        } else {
          wpsErr3 = false;
        }

        if (dataObject.WCS_WPS_No4 != undefined) {
          validWPSArr.some((element) => {
            if (element === dataObject.WCS_WPS_No4.toString()) wpsErr4 = false;
            return;
          });
        } else {
          wpsErr4 = false;
        }

      }


      if (wpsErr1 || wpsErr2 || wpsErr3 || wpsErr4) {
        fileErr = true;
        let msg = "";
        if (wpsErr1) msg += "WPS1, ";
        if (wpsErr2) msg += "WPS2, ";
        if (wpsErr3) msg += "WPS3, ";
        if (wpsErr4) msg += "WPS4, ";
        notUploadReason.push(msg + " not valid.");
      }
      
      const wpsArr = [
        dataObject.WCS_WPS_No1,
        dataObject.WCS_WPS_No2,
        dataObject.WCS_WPS_No3,
        dataObject.WCS_WPS_No4,
      ];
      const filteredWPSArr = wpsArr.filter(
        (item) => item !== undefined && item !== null
      );

      const duplicateWPSNo = filteredWPSArr.filter(
        (item, index) => wpsArr.indexOf(item) !== index
      );

      //
      if (duplicateWPSNo.length > 0) {


        fileErr = true;
        notUploadReason.push("WPS number is repeated.");
      }
      
      dataObject.Message = notUploadReason.join(", ");
      uploadExcelReport.push(dataObject);

    }
    

    if (fileErr) {
      setFileName("");
      setFile([]);

      createAndDownloadReport(uploadExcelReport, "WCSExcelUploadReport.xlsx");
      toast.warning(
        "Error while uploading file, Please check downloaded file.",
        {
          position: toast.POSITION.TOP_LEFT,

        }
      );
    } else {
      setFile(newFile);
      setFileName(name);
      setIsExcelUploaded(true);
      setdisableMnlEntry(true);

      setexcelData(mappedData);
    }
  };

  reader.readAsBinaryString(newFile);
  setError(isError);
  return isError;
};