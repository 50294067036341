import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "users";

//dev api
let INTERFACE_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
  INTERFACE_URL = process.env.REACT_APP_INTERFACE_API_URL + "/" + "PEDUsers";
} else {
  INTERFACE_URL = process.env.REACT_APP_API_URL + "interface/PEDUsers";
}





export async function authenticate(EmailId, accessToken) {



  const response = await axios
    .post(`${API_URL}/user/authenticate`, { EmailId: EmailId })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

export async function getAllUserMaster() {

  const response = await axios
    .get(API_URL + "/user")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

export async function getAllUsersByRole(roles) {

  const response = await axios
    .post(API_URL + "/user/getrolesusers", roles)
    .then(function (resp) {


      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function IsUserMasterBand() {

  const response = await axios
    .get(API_URL + "/band/ptband")
    .then(function (resp) {

      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response;
}

// export async function getPTBandUsers() {
export async function getPTUsers() {

  const response = await axios
    .get(API_URL + "/band/getbyBandPT")
    .then(function (resp) {


      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });


  return response;
}

export async function getPEDUsers() {

  const response = await axios
    .get(INTERFACE_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
  // return fetch(API_URL + "/ene").then((response) => response.json());
}

export const AddNewUser = async (User) => {

  let url = `${API_URL}/user`;
  const response = await axios
    .post(url, User)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateUser = async (User) => {

  let url = `${API_URL}/user/updateuser`;

  const response = await axios
    .post(url, User)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};



export async function DeleteUser(um_id) {

  let url = `${API_URL}/user/${um_id}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });


  return resp;
}

export async function DeleteUser1(um_id) {
  let url = `${API_URL}/user/deleteuser/${um_id}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });


  return resp;
}

export async function deletefromuserlocation(UserId) {
  let url = `${API_URL}/user/${UserId}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}

export async function getUsersL1(salaryCode) {

  let url = `${INTERFACE_URL}/salaryCode`;

  const resp = await axios
    .post(url, salaryCode)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });


  return resp.data;
}


export async function getbyuserid(UserId) {
  
  let url = `${API_URL}/user/${UserId}`;

  const resp = await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}
