// ---------------------------------------------------------------------------------------------------------------//

//Date - 28/3/2024.
//Author - Sagar Gawade.
//Session - PQR Transaction(Tube to Tubesheet) - Page_3.
//Contains - ADD / EDIT / GET API'S of PQR Tube to Tubesheet Session.
//Workflow - 1) There is no approval process,user can select Welding Engg Name and Witness ByClicks on save button to save the data.
//         - 2) Initiator Can Edit Record in both Freezed and Temp Saved Status.
//         - 3) In 2st Page API is called in such a way that  it will only update data in batches(UpdatePQRHeaderComplete)
//              and it will be save in Header of respective tables as there is no field on this page relataid to detail or PQR Process

// ---------------------------------------------------------------------------------------------------------------//
import { Row, Col,  Form } from "react-bootstrap";
import { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import defaultimg from "../../icons/noimage.png";
import { Action, Status, MessageTypes} from "./PQRObjects/PQR_entity";
import { Single_Input } from "../utility/CommonComponents/Inputs_Transaction"
import { getPresignedURL } from "../../Services/Welder-services";

import { PQRHeader } from "./PQRObjects/PQRHeader";
import {
  PQR_Page3_Table_ColumnN
} from "../utility/CommonComponents/wpq_Page3_table_components";
import {
  check_Pqr_Page4_OT,
  check_Pqr_Page4_Radio,
  check_Pqr_Page4_Tear,
  check_Pqr_Page4_PullOut,
  check_Pqr_Page4_PullOut_Table,
  check_Pqr_Page4_Hardness,
  check_Pqr_Pag3_VEX,
  check_Pqr_Pag3_LPEX,AddUpdatePQRHeader
} from "./PQRObjects/PQR_Validations";
import {
  PQR_tubePage1,
  PQR_tubePage3,
  PQR_Detail_TubePage1,
  PQR_tubePage2,
} from "./PQRObjects/PQR_entity";

import { SearchSelectProject } from "../utility/search-select";
import { getAllUserMaster ,IsUserMasterBand} from "../../Services/user-service";

import {  Date_InputUser } from "../utility/CommonComponents/Date_Component";
import { emptyError } from "./PQRObjects/PQR_common";
import { normalstyle,errorstyle,bluestyle } from "../../functions/welding-functions";
import { DotLoader } from "react-spinners";
const PQRTubesheetPage3 = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      copydata,
      WPStype,
    },
    ref
  ) => {
    const location = useLocation();
    const avatar = { defaultimg };
    const [users, setUsers] = useState(null);



    let folderwld = "wld_signatures";
    let sessionName = "PQRTubesheet";

 
    const[Tear_Test_Disabled,setTear_Test_Disabled] = useState(false)

    const[PullOut_Test_Disabled,setPullOut_Test_Disabled] = useState(false)

    const[PullOut_Test_Table_Disabled,setPullOut_Test_Table_Disabled] = useState(false)

    const[Hardness_Test_Disabled,setHardness_Test_Disabled] = useState(false)

    const[Radiography_Tests_Disabled,setRadiography_Tests_Disabled] = useState(false)

    const[Other_Test_Disabled,setOther_Test_Disabled] = useState(false)


    const [tearTest, settearTest] = useState(true);
    const [pulloutTest, setpulloutTest] = useState(true);
    const [hardnessTest, sethardnessTest] = useState(true);
    const [radioTest, setradioTest] = useState(true);
    const [otherTest, setotherTest] = useState(true);

    const [loading, setLoading] = useState(false);









    const [previewuser, setPreviewuser] = useState(avatar.defaultimg
    );

    const [previewapproved, setPreviewapproved] = useState( avatar.defaultimg
    );





    const [IsEdited, setIsEdited] = useState(false);
    const [action, setaction] = useState(location.state?.action);

    let Visual_Examination = headerObj?.Visual_Examination;
    let Liquid_Penetrant_Examination = headerObj?.Liquid_Penetrant_Examination;
  

    let Tear_Test = headerObj?.Tear_Test;
    let PullOut_Test = headerObj?.PullOut_Test;
    let Hardness_Test = headerObj?.Hardness_Test;
    let Radiography_Test = headerObj?.Radiography_Test;
    let Other_Test = headerObj?.Other_Test;


    let PullOut_Test_Table = headerObj?.PullOut_Test_Table;


  



    const fetchUserMaster = async () => {
      const result = await getAllUserMaster();
      const active = result.filter((item) => item.um_status === "Active")
      setUsers(active);

    };

    const [error, setError] = useState({
      PQR_Tear_TubeNo: {},
      PQR_Tear_Observation: {},
      PQR_Tear_Results: {},
      PQR_Tear_Notes: {},
      PQR_PullOut_Location: {},
      PQR_PullOut_Load: {},
      PQR_PullOut_Fracture: {},

      PQR_PullOut_TubeNo: {},
      PQR_PullOut_Acceptance_Criteria: {},
      PQR_PullOut_Observation: {},
      PQR_PullOut_Results: {},
      PQR_PullOut_Notes: {},
      // PQR_PullOut_Calculation: {},
      PQR_PullOut_MinBreakLoad: {},
      PQR_PullOut_AreaTubeMaterial: {},
      PQR_PullOut_MinTensilStrength: {},
      PQR_PullOut_MinBreakStress: {},
      PQR_PullOut_FrValue: {},

      PQR_Radio_TubeNo: {},
      PQR_Radio_Acceptance_Criteria: {},
      PQR_Radio_Observation: {},
      PQR_Radio_Results: {},
      PQR_Radio_Notes: {},

      PQR_OT_TypeofTest: {},
      PQR_OT_Acceptance_Criteria: {},
      PQR_OT_Results: {},
      PQR_OT_Remarks: {},
      PQR_OT_Notes: {},

      WPQ_MLP_Tube_No: {},
      WPQ_MLP_LocA: {},
      WPQ_MLP_LocB: {},
      WPQ_MLP_LocC: {},
      WPQ_MLP_LocD: {},

      PQR_HT_TypeofTest: {},
      PQR_HT_Tube_No: {},
      PQR_HT_BM_Tubesheet: {},
      PQR_HT_HAZ_Tubesheet: {},
      PQR_HT_Weld: {},
      PQR_HT_HAZ_Tube: {},
      PQR_HT_BM_Tube: {},
      PQR_HT_Results: {},
      PQR_HT_Notes: {},
      PQR_PullOut_AccCriteria_Notes: {},

      WPSH_PreparedBy_Name: {},
      WPSH_PreparedBy_Date: {},
      WPSH_PreparedBy_Remark: {},

      WPSH_WitnessBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_Approval_Status: {},
      WPSH_ApprovedBy_Remark: {},

      WPSH_WeldingEnggBy_Date: {},
      WPSH_WeldingEnggBy_Name: {},
      WPSH_WeldingEnggBy: {},
      WPSH_WitnessBy_Date: {},
      // WPSH_WitnessBy_Name: {},
      WPSH_WitnessBy: {},
    });

    useEffect(() =>{
      let filledval
      if(headerObj.Tear_Test != undefined){
        filledval = headerObj.Tear_Test.slice(0,1).filter((i) => {
      
        return( i.PQR_Tear_TubeNo == "" ||
        i.PQR_Tear_Observation == "" ||
       i.PQR_Tear_Results == "" )
      } )
      setTear_Test_Disabled(filledval.length > 0 ? true : false)
    }

    if(headerObj.PullOut_Test != undefined){
      filledval = headerObj.PullOut_Test.slice(0,1).filter((i) => {
    
      return( i.PQR_PullOut_TubeNo == "" ||
      i.PQR_PullOut_Location == "" ||
     i.PQR_PullOut_Load == "" ||
     i.PQR_PullOut_Fracture == "" )
    } )
    setPullOut_Test_Disabled(filledval.length > 0 ? true : false)
  }

  if(headerObj.PullOut_Test_Table != undefined){
    filledval = headerObj.PullOut_Test_Table.slice(0,1).filter((i) => {
  
    return( i.PQR_PullOut_Acceptance_Criteria == "" ||
    i.PQR_PullOut_Observation == "" ||
   i.PQR_PullOut_Results == "" )
  } )
  setPullOut_Test_Table_Disabled(filledval.length > 0 ? true : false)
}

if(headerObj.Hardness_Test != undefined){
  filledval = headerObj.Hardness_Test.slice(0,1).filter((i) => {

  return( i.PQR_HT_TypeofTest == "" ||
      i.PQR_HT_Tube_No == "" ||
      i.PQR_HT_BM_Tubesheet == "" ||
      i.PQR_HT_HAZ_Tubesheet == "" ||
      i.PQR_HT_Weld == "" ||
      i.PQR_HT_HAZ_Tube == "" ||
      i.PQR_HT_BM_Tube == "" ||
      i.PQR_HT_Results == "" )
} )
setHardness_Test_Disabled(filledval.length > 0 ? true : false)
}

if(headerObj.Radiography_Test != undefined){
  filledval = headerObj.Radiography_Test.slice(0,1).filter((i) => {

  return( i.PQR_Radio_TubeNo == "" ||
      i.PQR_Radio_Acceptance_Criteria == "" ||
      i.PQR_Radio_Observation == "" ||
      i.PQR_Radio_Results == ""  )
} )
setRadiography_Tests_Disabled(filledval.length > 0 ? true : false)
}


if(headerObj.Other_Test != undefined){
  filledval = headerObj.Other_Test.slice(0,1).filter((i) => {

  return( i.PQR_OT_TypeofTest == "" ||
      i.PQR_OT_Acceptance_Criteria == "" ||
      i.PQR_OT_Results == "" ||
      i.PQR_OT_Remarks == ""  )
} )
setOther_Test_Disabled(filledval.length > 0 ? true : false)
}

  },[headerObj])



    useEffect(() => {
      fetchUserMaster();
    }, []);

   
  

    const validate = async () => {
      
      let tubePage1 = Object.entries(PQR_tubePage1);

      let tubePage1_detail = Object.entries(PQR_Detail_TubePage1);

      let tubePage2 = Object.entries(PQR_tubePage2);

      let tubePage3 = Object.entries(PQR_tubePage3);

      let isError = false;

      tubePage1.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      tubePage1_detail.map(([key, val]) => {
        if (detailObj?.[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      tubePage2.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      tubePage3.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

       let norows = check_Pqr_Page4_Tear(Tear_Test);

      if (norows < 1) {
        Tear_Test[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Tear_Test[0].error = true;
      }
      else{

        Tear_Test[0].errormessage = ""
        Tear_Test[0].error = false;
      }

       norows = check_Pqr_Page4_PullOut(PullOut_Test);

      if (norows < 1) {
        PullOut_Test[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        PullOut_Test[0].error = true;
      }
      else{

        PullOut_Test[0].errormessage = ""
        PullOut_Test[0].error = false;
      }


       norows = check_Pqr_Page4_PullOut_Table(PullOut_Test_Table);


      if (norows < 1) {
        PullOut_Test_Table[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        PullOut_Test_Table[0].error = true;
      }
      else{

        PullOut_Test_Table[0].errormessage = ""
        PullOut_Test_Table[0].error = false;
      }

       norows = check_Pqr_Page4_Hardness(Hardness_Test);

      if (norows < 1) {
        Hardness_Test[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Hardness_Test[0].error = true;
      }
      else{
        Hardness_Test[0].errormessage = ""
        Hardness_Test[0].error = false;
      }

       norows = check_Pqr_Page4_Radio(Radiography_Test);

      if (norows < 1) {
        Radiography_Test[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Radiography_Test[0].error = true;
      }
      else{
        Radiography_Test[0].errormessage = ""
        Radiography_Test[0].error = false;
      }

      
       norows = check_Pqr_Pag3_VEX(Visual_Examination, 4);

      if (norows < 1) {
        Visual_Examination[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Visual_Examination[0].error = true;
      }
      else{

        Visual_Examination[0].errormessage = ""
        Visual_Examination[0].error = false;
      }

      
      for (let i = 0; i < 3; i++) {
        if(Visual_Examination[i].errormessage !== ""){
          isError = true;
        }
      }

       norows = check_Pqr_Pag3_LPEX(Liquid_Penetrant_Examination);

      if (norows < 1) {
        Liquid_Penetrant_Examination[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Liquid_Penetrant_Examination[0].error = true;
      }
      else{

        Liquid_Penetrant_Examination[0].errormessage = ""
        Liquid_Penetrant_Examination[0].error = false;
      }

      
      for (let i = 0; i < 3; i++) {
        if(Liquid_Penetrant_Examination[i].errormessage !== ""){
          isError = true;
        }
      }


      

       norows = check_Pqr_Page4_OT(Other_Test, 4);

      if (norows < 1) {
        Other_Test[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Other_Test[0].error = true;
      }
      else{
        Other_Test[0].errormessage = ""
        Other_Test[0].error = false;
      }


      setError((prevState) => ({
        ...prevState,
        ...error,
      }));
      return isError;
    };

    const handleAdd = async (approvalstatus, flag) => {
      let result = true;

      headerObj.WPSH_Status = approvalstatus;


      let TEAR = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        Tear_Test: headerObj.Tear_Test,
        tearCheck: headerObj.tearCheck,

      };
      let PULL = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PullOut_Test: headerObj.PullOut_Test,
        pullout_check: headerObj.pullout_check,
  
      };
  
      let PULL_Table = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PullOut_Test_Table: headerObj.PullOut_Test_Table,
  
      };
      let PULL_FIELDS = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        // PQR_PullOut_Calculation: headerObj.PQR_PullOut_Calculation,
        PQR_PullOut_MinBreakLoad: headerObj.PQR_PullOut_MinBreakLoad,
        PQR_PullOut_AreaTubeMaterial: headerObj.PQR_PullOut_AreaTubeMaterial,
        PQR_PullOut_MinTensilStrength: headerObj.PQR_PullOut_MinTensilStrength,
        PQR_PullOut_MinBreakStress: headerObj.PQR_PullOut_MinBreakStress,
        PQR_PullOut_FrValue: headerObj.PQR_PullOut_FrValue,
      };
      let HARD = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        Hardness_Test: headerObj.Hardness_Test,
        hardnessCheck: headerObj.hardnessCheck,
      };
      let RADIO = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        Radiography_Test: headerObj.Radiography_Test,
        radioCheck: headerObj.radioCheck,
      };
  
      let OTHER = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        Other_Test: headerObj.Other_Test,
        otherCheck: headerObj.otherCheck,
      };
  
      let PAGE3_NOTES = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_Tear_Notes: headerObj.PQR_Tear_Notes,
        PQR_PullOut_Notes: headerObj.PQR_PullOut_Notes,
        PQR_HT_Notes: headerObj.PQR_HT_Notes,
        PQR_PullOut_AccCriteria_Notes: headerObj.PQR_PullOut_AccCriteria_Notes,
        PQR_Radio_Notes: headerObj.PQR_Radio_Notes,
        PQR_OT_Notes: headerObj.PQR_OT_Notes,
      };
  

    

      let PQR_SignatureApproved = headerObj.PQR_SignatureApproved || ""
      let WPSH_WitnessBy_Name = headerObj.WPSH_WitnessBy_Name || ""
      let WPSH_WitnessBy = headerObj.WPSH_WitnessBy || ""
      let WPSH_WeldingEnggBy_Name =  headerObj.WPSH_WeldingEnggBy_Name || ""
      let PQR_SignaturePrepared = headerObj.PQR_SignaturePrepared || ""
      let REMAIN

      if(flag === "tempsave"){
       REMAIN = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        WPSH_PreparedBy_Remark: headerObj.WPSH_PreparedBy_Remark,
        WPSH_ApprovedBy_Remark: headerObj.WPSH_ApprovedBy_Remark,
        WPSH_WeldingEnggBy_Date: headerObj.WPSH_WeldingEnggBy_Date,
        WPSH_WeldingEnggBy_Name: WPSH_WeldingEnggBy_Name,
        WPSH_WeldingEnggBy: headerObj.WPSH_WeldingEnggBy,
        WPSH_WitnessBy_Date: headerObj.WPSH_WitnessBy_Date,
        PQR_SignatureApproved: PQR_SignatureApproved,
        WPSH_WitnessBy_Name: WPSH_WitnessBy_Name,
        WPSH_ApprovedBy_Date: headerObj.WPSH_ApprovedBy_Date,
        WPSH_WitnessBy: WPSH_WitnessBy,
        WPSH_PreparedBy_Name:  headerObj.WPSH_PreparedBy_Name,
        WPSH_PreparedBy_Date: headerObj.WPSH_PreparedBy_Date,
        PQR_SignaturePrepared: PQR_SignaturePrepared,
        WPSH_PreparedBy: sessionStorage.getItem("User"),
        WPSH_Status: Status.StatusTempSaved,
        WPSH_Approval_Status: headerObj.WPSH_Approval_Status,
      };
      
    }
    else{
       REMAIN = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        WPSH_PreparedBy_Remark: headerObj.WPSH_PreparedBy_Remark,
        WPSH_ApprovedBy_Remark: headerObj.WPSH_ApprovedBy_Remark,
        WPSH_WeldingEnggBy_Date: headerObj.WPSH_WeldingEnggBy_Date,
        WPSH_WeldingEnggBy_Name: WPSH_WeldingEnggBy_Name,
        WPSH_WeldingEnggBy: headerObj.WPSH_WeldingEnggBy,
        WPSH_WitnessBy_Date: headerObj.WPSH_WitnessBy_Date,
        PQR_SignatureApproved: PQR_SignatureApproved,
        WPSH_WitnessBy_Name: headerObj.WPSH_WitnessBy_Name,
        WPSH_ApprovedBy_Date: headerObj.WPSH_ApprovedBy_Date,
        WPSH_WitnessBy: WPSH_WitnessBy,
        WPSH_PreparedBy_Name: headerObj.WPSH_PreparedBy_Name,
        WPSH_PreparedBy_Date: headerObj.WPSH_PreparedBy_Date,
        PQR_SignaturePrepared: PQR_SignaturePrepared,
        WPSH_PreparedBy: sessionStorage.getItem("User"),
        WPSH_Status: Status.StatusFreezed,
        WPSH_Approval_Status: headerObj.WPSH_Approval_Status,
      };
    }

      const postList = [
        TEAR,
        PULL,
        PULL_Table,
        PULL_FIELDS,
        HARD,
        RADIO,
        OTHER,
        PAGE3_NOTES,
        REMAIN,
      ];
      await AddUpdatePQRHeader(undefined,postList)
        .catch((e) => {
          result = false;
        })
        .then(async (r) => {
          //alert(r)
          if(r == MessageTypes.SpecialCharacters)
            result = MessageTypes.SpecialCharacters
        });
      
      return Promise.resolve(result);
    };

    const checkIfUsernameExists = async () => {
      let res = await IsUserMasterBand();
      if (res !== undefined) return res.data.message;
      else return false;
    };



    const validateTemp = async () => {
      debugger
      let isError = false;

       let temperror = emptyError(error, setError, isError);


      return {isError ,temperror};
    };

    const handleSubmitTemp = async () => {
      let result = false;
      var val = await validateTemp();
    
          setIsEdited(false);
          if (val.isError === false){
            if ( val.temperror === false) {
              setLoading(true)
              result = await handleAdd(headerObj?.WPSH_Status,"tempsave");
              setLoading(false)

              if (result == true) {
                return MessageTypes.Temp;
              }
              else if(result == MessageTypes.SpecialCharacters){
                return MessageTypes.SpecialCharacters
              }
               else {
                return MessageTypes.Fail;
              }
      
            }
            return MessageTypes.SpecialCharacters;
          }
          return MessageTypes.Required;

    };

    const handleSubmit = async () => {
      setIsEdited(false);
      if ((await validate())) {
        setIsEdited(true);
      }
      let result = false;

      const isUsernamePresent = await checkIfUsernameExists();
      let status
      if (!isUsernamePresent) {
        status = Status.StatusTempSaved;
      } else {
        status = Status.StatusFreezed;
      }
      if (!(await validate())) {
        setLoading(true)

        result = await handleAdd(status, "freezed");
        setLoading(false)

        if (result == true) {
          return MessageTypes.Success;
        } 
        else if(result == MessageTypes.SpecialCharacters){
          return MessageTypes.SpecialCharacters
        }
        else {
          return MessageTypes.Fail;
        }
      } else {
        setTimeout(() => {
          if (action === Action.ActionAdd) {
            alert("Please Temp Save Data before leaving this Page")
          }
        }, 500);
       
        return MessageTypes.RequiredAll;
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext3: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp3: async () => {
        var res = false;
        res = await handleSubmitTemp();
        return res;
      },
    }));

  
    const handleChangeTearTestarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Tear_Test[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangePullTestarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.PullOut_Test[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangePullTestTablearray = (Index, key, newValue) => {
      
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.PullOut_Test_Table[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangeHadnesstestarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Hardness_Test[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangetestarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Radiography_Test[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangeOther_Testarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Other_Test[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangeHeader = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const fetchbyuserprofile = async (userprofile) => {
      try {
        if (userprofile !=="") {
          const result = await getPresignedURL(folderwld, userprofile);

          const geturl = result.url;
          setPreviewuser(geturl);
        }
        else{

          setPreviewuser(avatar.defaultimg);

        }
      } catch (error) {console.log("Error",error)}
    };


    const fetchapproveUserProfile = async (userprofile) => {
      
      try {
        if (userprofile !== "") {
          const result = await getPresignedURL(folderwld, userprofile);
          const geturl = result.url;
          setPreviewapproved(geturl);
        }
        else{
          setPreviewapproved(avatar.defaultimg);

        }
      } catch (error) {console.log("Error",error)}
    };

    useEffect(() => {
      
            if(action === Action.ActionEdit && headerObj?.PQR_SignaturePrepared !== ""){
      
              fetchbyuserprofile(headerObj?.PQR_SignaturePrepared)
            }
      
            if(action === Action.ActionEdit && headerObj?.PQR_SignatureApproved !== ""){
            fetchapproveUserProfile(headerObj?.PQR_SignatureApproved)
            }
      
          }, []);


          const handleCheckboxChange = (e,field,obj) => {
        debugger
            if (action === Action.ActionEdit) setIsEdited(true);
            if(e.target.checked === true){
      
              setheaderObj((header) => ({
                ...header,
                [field]: "Yes",
      
              }));    
            }
            if(e.target.checked === false){
      
              setheaderObj((header) => ({
                ...header,
                [field]: "No",
      
              }));       }
              obj(e.target.checked)
         
          
          }

          
        

    return (
      <Row className="font">
          {loading && (
      <div className="loader-overlay">
        <DotLoader size={80} className="pageloader" />
      </div>
    )}
        <PQRHeader headerObj={headerObj} WPStype={WPStype}></PQRHeader>

        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>

            <div className="checkboxproject">
                  <input
                  style={{position:"relative", bottom:"4px"}}
                    type="checkbox"
                    placeholder=" "
                    className="form-check-input"
                    id="chkcode"
                    onClick={(e) => {
                      if(e.target.checked === false){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Tear_Test:
                          prevState.Tear_Test.map((test,i) =>
                          {
                            
                            if(i==0){
                    
                              return {
                                ...test,
                                PQR_Tear_Observation: "Not Applicable",
                                PQR_Tear_Results: "Not Applicable",
                                PQR_Tear_TubeNo: "Not Applicable",
                          
                                errormessage:"",
                                error:false,

                              }
                            }
                            else{
                              return {
                                ...test,
                                PQR_Tear_Observation: "",
                                PQR_Tear_Results: "",
                                PQR_Tear_TubeNo: "",
          
                                errormessage:"",
                                error:false,
                              }

                            }
                            
                        
                          })                       
                                }));
                      }
                      if(e.target.checked === true){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Tear_Test:
                          prevState.Tear_Test.map((test,i) =>
                          {
                           
                                return {
                                ...test,
                                PQR_Tear_Observation: "",
                                PQR_Tear_Results: "",
                                PQR_Tear_TubeNo: "",
                                errormessage:"",
                                error:false
                               }
                              
                          
                        
                          })                       
                                }));
                      }
                    }}
                    // onChange={handleCheckboxChange}
                    onChange={(e) => handleCheckboxChange(e,"tearCheck",settearTest)}

                    

                    checked={headerObj.Tear_Test && headerObj.Tear_Test[0] &&  headerObj?.Tear_Test[0]?.PQR_Tear_TubeNo ?  headerObj?.Tear_Test[0]?.PQR_Tear_TubeNo === "Not Applicable" ? false  : tearTest : tearTest}

                  />{" "}
                  <span className="WPS-blue-text" style={{position:"relative",bottom:"2px", left:"5px"}}>Tear Test</span>
                </div>
              {/* <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Tear Test </p> */}

              <PQR_Page3_Table_ColumnN
                columns={3}
                sessionName={sessionName}
                maxLength={[30,40,30]}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                array={Tear_Test}
                setheaderObj={setheaderObj}
                setError={setError}
                title={["Tube No.","Observation","Results"]}
                name={["PQR_Tear_TubeNo","PQR_Tear_Observation","PQR_Tear_Results"]}
                status={[error.PQR_Tear_TubeNo.status,error.PQR_Tear_Observation.status,error.PQR_Tear_Results.status]}
                message={[error.PQR_Tear_TubeNo.message,error.PQR_Tear_Observation.message,error.PQR_Tear_Results.message]}
                handleChangetestarray={handleChangeTearTestarray}
                // disabled={Tear_Test_Disabled}
                disabled={headerObj?.tearCheck === "No" ? true : Tear_Test_Disabled }
                readOnly={headerObj?.tearCheck === "No"}
                numberofrowsdisabled={1}
              />
            </Col>

            <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Notes</span>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_Tear_Notes"
                type="text"
                maxLength={100}
                status={error.PQR_Tear_Notes.status}
                value={headerObj?.PQR_Tear_Notes}
                onChange={handleChangeHeader}
                setError={setError}
                  error={error}
                label="Notes"
                message={error.PQR_Tear_Notes.message}
               
              ></Single_Input>
            </Col>
          </Row>
        </Row>

        <Row style={{ padding: "20px 60px" }} className="PQR-Page-row-padding">
          <Row className="mb-1">
            <Col md={12}>
            <div className="checkboxproject">
                  <input
                     style={{position:"relative", bottom:"4px"}}
                    type="checkbox"
                    placeholder=" "
                    className="form-check-input"
                    id="chkcode"
                    onClick={(e) => {
                      if(e.target.checked === false){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          PullOut_Test:
                          prevState.PullOut_Test.map((test,i) =>
                          {
                            
                            if(i==0){
                    
                              return {
                                ...test,
                                PQR_PullOut_TubeNo: "Not Applicable",
                                PQR_PullOut_Location: "Not Applicable",
                                PQR_PullOut_Load: "Not Applicable",
                                PQR_PullOut_Fracture: "Not Applicable",

                          
                                errormessage:"",
                                error:false,

                              }
                            }
                            else{
                              return {
                                ...test,
                                PQR_PullOut_TubeNo: "",
                                PQR_PullOut_Location: "",
                                PQR_PullOut_Load: "",
                                PQR_PullOut_Fracture: "",
                                errormessage:"",
                                error:false,
                              }

                            }
                            
                        
                          })                       
                                }));
                      }
                      if(e.target.checked === true){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          PullOut_Test:
                          prevState.PullOut_Test.map((test,i) =>
                          {
                           
                                return {
                                ...test,
                                PQR_PullOut_TubeNo: "",
                                PQR_PullOut_Location: "",
                                PQR_PullOut_Load: "",
                                PQR_PullOut_Fracture: "",
                                errormessage:"",
                                error:false
                               }
                              
                          
                        
                          })                       
                                }));
                      }
                    }}
                    // onChange={handleCheckboxChange}
                    onChange={(e) => handleCheckboxChange(e,"pullout_check",setpulloutTest)}

                    

                    checked={headerObj.PullOut_Test && headerObj.PullOut_Test[0] &&  headerObj?.PullOut_Test[0]?.PQR_PullOut_TubeNo ?  headerObj?.PullOut_Test[0]?.PQR_PullOut_TubeNo === "Not Applicable" ? false  : pulloutTest : pulloutTest}

                  />{" "}
                  <span className="WPS-blue-text" style={{position:"relative",bottom:"2px", left:"5px"}}>Pull Out Test</span>
                </div>
              {/* <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Pull Out Test </p> */}
            
              <PQR_Page3_Table_ColumnN
                columns={4}
                sessionName={sessionName}
                maxLength={[25,20,20,25]}
                width  ={245}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                array={PullOut_Test}
                setheaderObj={setheaderObj}
                setError={setError}
                title={["Tube No.","Location","Load","Fracture"]}
                name={["PQR_PullOut_TubeNo","PQR_PullOut_Location","PQR_PullOut_Load","PQR_PullOut_Fracture"]}
                status={[error.PQR_PullOut_TubeNo.status,error.PQR_PullOut_Location.status,error.PQR_PullOut_Load.status,error.PQR_PullOut_Fracture.status]}
                message={[error.PQR_PullOut_TubeNo.message,error.PQR_PullOut_Location.message,error.PQR_PullOut_Load.message,error.PQR_PullOut_Fracture.message]}
                handleChangetestarray={handleChangePullTestarray}
                // disabled={PullOut_Test_Disabled}
                numberofrowsdisabled={1}  
                disabled={headerObj?.pullout_check === "No" ? true : PullOut_Test_Disabled }
                readOnly={headerObj?.pullout_check === "No"}
              />
            </Col>

            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_PullOut_Notes"
                type="text"
                maxLength={98}
                status={error.PQR_PullOut_Notes.status}
                value={headerObj?.PQR_PullOut_Notes}
                onChange={handleChangeHeader}
                setError={setError}
                  error={error}
                label="Notes"
                message={error.PQR_PullOut_Notes.message}
                
              ></Single_Input>
            </Col>
    <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Calculation for Fr Value </p>
            
         
            <Col md={6}>
              <Single_Input
                disabled={copydata}
                name="PQR_PullOut_MinBreakLoad"
                type="text"
                maxLength={50}
                status={error.PQR_PullOut_MinBreakLoad.status}
                value={headerObj?.PQR_PullOut_MinBreakLoad}
                onChange={handleChangeHeader}
                setError={setError}
                  error={error}
                label="Minimum Breaking Load(KN)"
                message={error.PQR_PullOut_MinBreakLoad.message}
       
              ></Single_Input>
            </Col>

            <Col md={6}>
              <Single_Input
                disabled={copydata}
                name="PQR_PullOut_AreaTubeMaterial"
                type="text"
                maxLength={50}
                status={error.PQR_PullOut_AreaTubeMaterial.status}
                value={headerObj?.PQR_PullOut_AreaTubeMaterial}
                onChange={handleChangeHeader}
                label="Area of Tube Material(mm^2)"
                message={error.PQR_PullOut_AreaTubeMaterial.message}
                setError={setError}
                error={error}
               
              ></Single_Input>
            </Col>

            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_PullOut_MinTensilStrength"
                type="text"
                maxLength={50}
                status={error.PQR_PullOut_MinTensilStrength.status}
                value={headerObj?.PQR_PullOut_MinTensilStrength}
                onChange={handleChangeHeader}
                label="Minimum Specified Tensil Strength of Tube(Map)"
                message={error.PQR_PullOut_MinTensilStrength.message}
                setError={setError}
                error={error}
              ></Single_Input>
            </Col>

            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_PullOut_MinBreakStress"
                type="text"
                maxLength={50}
                status={error.PQR_PullOut_MinBreakStress.status}
                value={headerObj?.PQR_PullOut_MinBreakStress}
                onChange={handleChangeHeader}
                label="Minimum Breaking Stress( = Minimum Breaking Load in KN/Area of Tube Material in mm^2)"
                message={error.PQR_PullOut_MinBreakStress.message}
                setError={setError}
                  error={error}
              ></Single_Input>
            </Col>

            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_PullOut_FrValue"
                type="text"
                maxLength={50}
                status={error.PQR_PullOut_FrValue.status}
                value={headerObj?.PQR_PullOut_FrValue}
                onChange={handleChangeHeader}
                label="Fr Value( = Minimum Breaking Stress/Minimum Tensil Strength of Tube)"
                message={error.PQR_PullOut_FrValue.message}
                setError={setError}
                error={error}
              ></Single_Input>
            </Col>
          </Row>
          <Row style={{ padding: "1px 13px" }}>
          <Row className="mb-1">
            <Col md={12}>
               <PQR_Page3_Table_ColumnN
                columns={3}
                sessionName={sessionName}
                maxLength={[30,35,30]}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                array={PullOut_Test_Table}
                setheaderObj={setheaderObj}
                setError={setError}
                title={["Acceptance Criteria","Observation","Results"]}
                name={["PQR_PullOut_Acceptance_Criteria","PQR_PullOut_Observation","PQR_PullOut_Results"]}
                status={[error.PQR_PullOut_Acceptance_Criteria.status,error.PQR_PullOut_Observation.status,error.PQR_PullOut_Results.status]}
                message={[error.PQR_PullOut_Acceptance_Criteria.message,error.PQR_PullOut_Observation.message,error.PQR_PullOut_Results.message]}
                handleChangetestarray={handleChangePullTestTablearray}
                disabled={PullOut_Test_Table_Disabled}
                numberofrowsdisabled={1}
               
                error={error}
              />
            </Col>
            <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Notes</span>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_PullOut_AccCriteria_Notes"
                type="text"
                maxLength={100}
                status={error.PQR_PullOut_AccCriteria_Notes.status}
                value={headerObj?.PQR_PullOut_AccCriteria_Notes}
                onChange={handleChangeHeader}
                label="Notes"
                message={error.PQR_PullOut_AccCriteria_Notes.message}
                setError={setError}
                error={error}
              ></Single_Input>
            </Col>
          </Row>
        </Row>
        </Row>
        

        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>

            <div className="checkboxproject">
                  <input
                     style={{position:"relative", bottom:"4px"}}

                    type="checkbox"
                    placeholder=" "
                    className="form-check-input"
                    id="chkcode"
                    onClick={(e) => {
                      if(e.target.checked === false){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Hardness_Test:
                          prevState.Hardness_Test.map((test,i) =>
                          {
                            
                            if(i==0){
                    
                              return {
                                ...test,
                                PQR_HT_TypeofTest: "Not Applicable",
                                PQR_HT_BM_Tube: "Not Applicable",
                                PQR_HT_BM_Tubesheet: "Not Applicable",
                                PQR_HT_HAZ_Tube: "Not Applicable",
                                PQR_HT_HAZ_Tubesheet: "Not Applicable",
                                PQR_HT_Results: "Not Applicable",
                                PQR_HT_Tube_No: "Not Applicable",
                                PQR_HT_Weld: "Not Applicable",
                                errormessage:"",
                                error:false,

                              }
                            }
                            else{
                              return {
                                ...test,
                                PQR_HT_TypeofTest: "",
                                PQR_HT_BM_Tube: "",
                                PQR_HT_BM_Tubesheet: "",
                                PQR_HT_HAZ_Tube: "",
                                PQR_HT_HAZ_Tubesheet: "",
                                PQR_HT_Results: "",
                                PQR_HT_Tube_No: "",
                                PQR_HT_Weld: "",
          
                                errormessage:"",
                                error:false,
                              }

                            }
                            
                        
                          })                       
                                }));
                      }
                      if(e.target.checked === true){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Hardness_Test:
                          prevState.Hardness_Test.map((test,i) =>
                          {
                           
                                return {
                                ...test,
                                PQR_HT_TypeofTest: "",
                                PQR_HT_BM_Tube: "",
                                PQR_HT_BM_Tubesheet: "",
                                PQR_HT_HAZ_Tube: "",
                                PQR_HT_HAZ_Tubesheet: "",
                                PQR_HT_Results: "",
                                PQR_HT_Tube_No: "",
                                PQR_HT_Weld: "",
                                errormessage:"",
                                error:false
                               }
                              
                          
                        
                          })                       
                                }));
                      }
                    }}
                    // onChange={handleCheckboxChange}
                    onChange={(e) => handleCheckboxChange(e,"hardnessCheck",sethardnessTest)}

                    

                    checked={headerObj.Hardness_Test && headerObj.Hardness_Test[0] &&  headerObj?.Hardness_Test[0]?.PQR_HT_TypeofTest ?  headerObj?.Hardness_Test[0]?.PQR_HT_TypeofTest === "Not Applicable" ? false  : hardnessTest : hardnessTest}

                  />{" "}
              <span className="WPS-blue-text" style={{position:"relative",bottom:"2px", left:"5px"}}>Hardness Test </span>
              </div>
              {/* <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Hardness Test </p> */}
            
              <PQR_Page3_Table_ColumnN
                columns={8}
                sessionName={sessionName}
                maxLength={[15,12,12,12,12,12,12,15]}
                width  ={125}
                width1 ={125}
                width2 ={125}
                width3 ={125}
                array={Hardness_Test}
                setheaderObj={setheaderObj}
                setError={setError}
                title={["Type","Tube No","BM-Tubesheet","HAZ-Tubesheet","Weld","HAZ-Tube","BM-Tube","Results"]}
                name={["PQR_HT_TypeofTest","PQR_HT_Tube_No","PQR_HT_BM_Tubesheet","PQR_HT_HAZ_Tubesheet","PQR_HT_Weld","PQR_HT_HAZ_Tube","PQR_HT_BM_Tube","PQR_HT_Results"]}
                status={[error.PQR_HT_TypeofTest.status,error.PQR_HT_Tube_No.status,error.PQR_HT_BM_Tubesheet.status,error.PQR_HT_HAZ_Tubesheet.status,error.PQR_HT_Weld.status,error.PQR_HT_HAZ_Tube.status,error.PQR_HT_BM_Tube.status,error.PQR_HT_Results.status]}
                message={[error.PQR_HT_TypeofTest.message,error.PQR_HT_Tube_No.message,error.PQR_HT_BM_Tubesheet.message,error.PQR_HT_HAZ_Tubesheet.message,error.PQR_HT_Weld.message,error.PQR_HT_HAZ_Tube.message,error.PQR_HT_BM_Tube.message,error.PQR_HT_Results.message]}
                handleChangetestarray={handleChangeHadnesstestarray}
                // disabled={Hardness_Test_Disabled}
                disabled={headerObj?.hardnessCheck === "No" ? true : Hardness_Test_Disabled }
                readOnly={headerObj?.hardnessCheck === "No"}
              
                numberofrowsdisabled={1}  
              />
            </Col>

            <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Notes</span>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_HT_Notes"
                type="text"
                maxLength={98}
                status={error.PQR_HT_Notes.status}
                value={headerObj?.PQR_HT_Notes}
                onChange={handleChangeHeader}
                label="Notes"
                message={error.PQR_HT_Notes.message}
                setError={setError}
                error={error}
                
              ></Single_Input>
            </Col>
          </Row>
        </Row>

        <Row style={{padding: "20px 60px" }} className="PQR-Page-row-padding">
          <Row className="mb-1">
            <Col md={12}>

            <div className="checkboxproject">
                  <input
                    style={{position:"relative", bottom:"4px"}}
                    type="checkbox"
                    placeholder=" "
                    className="form-check-input"
                    id="chkcode"
                    onClick={(e) => {
                      if(e.target.checked === false){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Radiography_Test:
                          prevState.Radiography_Test.map((test,i) =>
                          {
                            
                            if(i==0){
                    
                              return {
                                ...test,
                                PQR_Radio_TubeNo: "Not Applicable",
                                PQR_Radio_Results: "Not Applicable",
                                PQR_Radio_Observation: "Not Applicable",
                                PQR_Radio_Acceptance_Criteria: "Not Applicable",
                               
                                errormessage:"",
                                error:false,

                              }
                            }
                            else{
                              return {
                                ...test,
                                PQR_Radio_TubeNo: "",
                                PQR_Radio_Results: "",
                                PQR_Radio_Observation: "",
                                PQR_Radio_Acceptance_Criteria: "",
                         
          
                                errormessage:"",
                                error:false,
                              }

                            }
                            
                        
                          })                       
                                }));
                      }
                      if(e.target.checked === true){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Radiography_Test:
                          prevState.Radiography_Test.map((test,i) =>
                          {
                           
                                return {
                                ...test,
                                PQR_Radio_TubeNo: "",
                                PQR_Radio_Results: "",
                                PQR_Radio_Observation: "",
                                PQR_Radio_Acceptance_Criteria: "",
                                PQR_HT_Weld: "",
                                errormessage:"",
                                error:false
                               }
                              
                          
                        
                          })                       
                                }));
                      }
                    }}
                    // onChange={handleCheckboxChange}
                    onChange={(e) => handleCheckboxChange(e,"radioCheck",sethardnessTest)}

                    

                    checked={headerObj.Radiography_Test && headerObj.Radiography_Test[0] &&  headerObj?.Radiography_Test[0]?.PQR_Radio_TubeNo ?  headerObj?.Radiography_Test[0]?.PQR_Radio_TubeNo === "Not Applicable" ? false  : radioTest : radioTest}

                  />{" "}
              <span className="WPS-blue-text" style={{position:"relative",bottom:"2px", left:"5px"}}>Radiography Test </span>
              </div>
              {/* <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Radiography Test </p> */}
            
              <PQR_Page3_Table_ColumnN
                columns={4}
                sessionName={sessionName}
                maxLength={[25,25,25,25]}
                width  ={245}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                array={Radiography_Test}
                setheaderObj={setheaderObj}
                setError={setError}
                title={["Tube No.","Acceptance Criteria","Observation","Results"]}
                name={["PQR_Radio_TubeNo","PQR_Radio_Acceptance_Criteria","PQR_Radio_Observation","PQR_Radio_Results"]}
                status={[error.PQR_Radio_TubeNo.status,error.PQR_Radio_Acceptance_Criteria.status,error.PQR_Radio_Observation.status,error.PQR_Radio_Results.status]}
                message={[error.PQR_Radio_TubeNo.message,error.PQR_Radio_Acceptance_Criteria.message,error.PQR_Radio_Observation.message,error.PQR_Radio_Results.message]}
                handleChangetestarray={handleChangetestarray}

                disabled={headerObj?.radioCheck === "No" ? true : Radiography_Tests_Disabled }
                readOnly={headerObj?.radioCheck === "No"}
              
                numberofrowsdisabled={1}  
              />
            </Col>

            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_Radio_Notes"
                type="text"
                maxLength={100}
                status={error.PQR_Radio_Notes.status}
                value={headerObj?.PQR_Radio_Notes}
                onChange={handleChangeHeader}
                label="Notes"
                message={error.PQR_Radio_Notes.message}
                setError={setError}
                error={error}
              ></Single_Input>
            </Col>
          </Row>
        </Row>

        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>

            <div className="checkboxproject">
                  <input
                   style={{position:"relative", bottom:"4px"}}
                    type="checkbox"
                    placeholder=" "
                    className="form-check-input"
                    id="chkcode"
                    onClick={(e) => {
                      if(e.target.checked === false){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Other_Test:
                          prevState.Other_Test.map((test,i) =>
                          {
                            
                            if(i==0){
                    
                              return {
                                ...test,
                                PQR_OT_TypeofTest: "Not Applicable",
                                PQR_OT_Results: "Not Applicable",
                                PQR_OT_Remarks: "Not Applicable",
                                PQR_OT_Acceptance_Criteria: "Not Applicable",
                               
                                errormessage:"",
                                error:false,

                              }
                            }
                            else{
                              return {
                                ...test,
                                PQR_OT_TypeofTest: "",
                                PQR_OT_Results: "",
                                PQR_OT_Remarks: "",
                                PQR_OT_Acceptance_Criteria: "",
                         
          
                                errormessage:"",
                                error:false,
                              }

                            }
                            
                        
                          })                       
                                }));
                      }
                      if(e.target.checked === true){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Other_Test:
                          prevState.Other_Test.map((test,i) =>
                          {
                           
                                return {
                                  PQR_OT_TypeofTest: "",
                                  PQR_OT_Results: "",
                                  PQR_OT_Remarks: "",
                                  PQR_OT_Acceptance_Criteria: "",
                                errormessage:"",
                                error:false
                               }
                              
                          
                        
                          })                       
                                }));
                      }
                    }}
                    // onChange={handleCheckboxChange}
                    onChange={(e) => handleCheckboxChange(e,"otherCheck",sethardnessTest)}

                    

                    checked={headerObj.Other_Test && headerObj.Other_Test[0] &&  headerObj?.Other_Test[0]?.PQR_OT_TypeofTest ?  headerObj?.Other_Test[0]?.PQR_OT_TypeofTest === "Not Applicable" ? false  : otherTest : otherTest}

                  />{" "}
             <span className="WPS-blue-text" style={{position:"relative",bottom:"2px", left:"5px"}}>Other Test </span>
              </div>
              {/* <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Other Test </p> */}
           
              <PQR_Page3_Table_ColumnN
                columns={4}
                sessionName={sessionName}
                maxLength={[25,25,25,25]}
                width  ={245}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                array={Other_Test}
                setheaderObj={setheaderObj}
                setError={setError}
                title={["Type of Test","Acceptance Criteria","Results","Remarks"]}
                name={["PQR_OT_TypeofTest","PQR_OT_Acceptance_Criteria","PQR_OT_Results","PQR_OT_Remarks"]}
                status={[error.PQR_OT_TypeofTest.status,error.PQR_OT_Acceptance_Criteria.status,error.PQR_OT_Results.status,error.PQR_OT_Remarks.status]}
                message={[error.PQR_OT_TypeofTest.message,error.PQR_OT_Acceptance_Criteria.message,error.PQR_OT_Results.message,error.PQR_OT_Remarks.message]}
                handleChangetestarray={handleChangeOther_Testarray}
                
                disabled={headerObj?.otherCheck === "No" ? true : Other_Test_Disabled }
                readOnly={headerObj?.otherCheck === "No"}
              
                numberofrowsdisabled={1}  
              />
            </Col>

            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_OT_Notes"
                type="text"
                maxLength={100}
                status={error.PQR_OT_Notes.status}
                value={headerObj?.PQR_OT_Notes}
                onChange={handleChangeHeader}
                label="Notes"
                message={error.PQR_OT_Notes.message}
                setError={setError}
                error={error}
              ></Single_Input>
            </Col>
          </Row>

      
          {/* -------------------------prepared by --------------------------------*/}

          <Row>
            <Row className="mb-4" style={{padding:"5px 24px"}}>
              <Col>
                <span className="WPS-blue-text" style={{position:"relative",right:"10px",top:"10px"}}>Welding Engineer</span>
              </Col>
              <Col>
                <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Witness By</span>
              </Col>
            </Row>
            <Row style={{padding:"0px 16px"}}>
              <Col md={3}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                    <SearchSelectProject
                      id="WPSH_WeldingEnggBy_Name"
                      name="WPSH_WeldingEnggBy_Name"
                      className={
                        error.WPSH_WeldingEnggBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_WeldingEnggBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      
                      valueField={"um_name"}
                      value={headerObj?.WPSH_WeldingEnggBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(ProjectList) => {
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_WeldingEnggBy_Name: ProjectList.value,
                        }));
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_WeldingEnggBy_Name: {},
                        }));

                        let a = users.filter((b) => {
                          return b.um_name === ProjectList.value;
                        });
                        fetchbyuserprofile(
                         a[0].wld_profile
                        );

                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_WeldingEnggBy_Name: a[0].um_name,
                          PQR_SignaturePrepared: a[0].wld_profile,
                          WPSH_WeldingEnggBy: a[0].SK,
                        }));

                      }}
                    
                    ></SearchSelectProject>
                     
                 
                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_WeldingEnggBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_WeldingEnggBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3}>
               
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_PreparedBy_Date"
                                  
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_PreparedBy_Date.status}
                  message={error.WPSH_PreparedBy_Date.message}
                 
                />
              </Col>

              <Col md={3}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                    <SearchSelectProject
                      id="WPSH_WitnessBy_Name"
                      name="WPSH_WitnessBy_Name"
                      className={
                        error.WPSH_WitnessBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_WitnessBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      
                      valueField={"um_name"}
                      value={headerObj?.WPSH_WitnessBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(ProjectList) => {
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_WitnessBy_Name: ProjectList.value,
                        }));
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_WitnessBy_Name: {},
                        }));

                        let a = users.filter((b) => {
                          return b.um_name === ProjectList.value;
                        });
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_WitnessBy_Name: a[0].um_name,
                          PQR_SignatureApproved: a[0].wld_profile,
                          WPSH_WitnessBy: a[0].SK,
                        }));
                        fetchapproveUserProfile(a[0].wld_profile)

                      }}
                    
                    ></SearchSelectProject>

                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_WitnessBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_WitnessBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3}>
              
                  <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_ApprovedBy_Date"
           
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_ApprovedBy_Date.status}
                  message={error.WPSH_ApprovedBy_Date.message}
                  // datevalue={datevalue}
                />
              </Col>

              <Row className="mb-4">
                <Col>
                  <span style={{position:"relative",left:"13px"}}>Signature Image</span>
                </Col>
                <Col>
                  <span  style={{position:"relative",left:"20px"}}>Signature Image</span>
                </Col>
              </Row>

              <Row style={{padding:"0px 13px",position:"relative", bottom:"10px"}}>
                <Col md={6}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewuser} />
                    </div>
                  </div>
                </Col>

                <Col md={6} style={{position:"relative", left:"13px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewapproved} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Row>
          </Row>
          
        </Row>
      </Row>
    );
  }
);

export default PQRTubesheetPage3;
