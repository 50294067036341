// ---------------------------------------------------------------------------------------------------------------//

//Date - 28/3/2024.
//Author - Sagar Gawade.
//Session - PQR Transaction(Tube to Tubesheet) - Page_1.
//Contains - ADD / EDIT / GET API'S of PQR Tube to Tubesheet Session.
//Workflow - 1) There is no approval process,user can select Welding Engg Name and Witness By Clicks on save button to save the data.
//         - 2) Initiator Can Edit Record in both Freezed and Temp Saved Status.
//         - 3) In 1st Page API is called in such a way that 1st it will add some record create structure at backend
//              for database schema,then it updates the further data in batches(AddUpdatePQRHeader,AddNewPQR,UpdatePQRHeader,AddNewDetail)
//         - 4) Record can be Tempsaved (Project Number , Atleast one Process Mandtory).

// Important Status :
//   StatusApproved: "Approved",
//   StatusRejected: "Rejected",
//   StatusTempSaved: "Temporary Saved",
//   StatusFreezed: "Freezed" -pdf can be downloaded only if data is freezed,
// ---------------------------------------------------------------------------------------------------------------//

import { Row, Col, Form, Table, Tabs } from "react-bootstrap";
import {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  isAlphanumericAllSpecialCharacters,
} from "../../functions/validations";
import {
  MessageTypes,
  PQR_tubePage1,
  PQR_Tube_Page1_Process,
  PQR_Detail_TubePage1,
  Plant_Location,
  pqrJointType,
  
  PQR_tubesheet_pg1_array,
} from "./PQRObjects/PQR_entity";
import { getbyprojectno } from "../../Services/pqr-header-service";
import {
  AddNewDetail,
} from "../../Services/pqr-details-service";
import { useLocation} from "react-router-dom";
import { getPNOActive } from "../../Services/pcategory-service";
import { getActiveAno } from "../../Services/ano-services";
import { getActiveProcess } from "../../Services/weldingprocess-services";
import { getActiveSfa } from "../../Services/sfa-service";
import { getActiveFno } from "../../Services/fno-services";
import {
  checkPQRTubeProcessAdded,
  AddUpdatePQRHeader,
} from "./PQRObjects/PQR_Validations";
import { emptyError,SetErrorValueAValueB } from "./PQRObjects/PQR_common";
import { Action, Status } from "./PQRObjects/PQR_entity";
import { Single_Input,General_Single_Input_select } from "../utility/CommonComponents/Inputs_Transaction"

import {
  
  PQR_TTS_Process_Grid_Input,
  PQR_Process_Grid_InputProcess,
  PQR_Process_Grid_Input_2,
} from "./PQRObjects/PQR_Grid_Tubesheet";
import {
  getPresignedURL,
} from "../../Services/Welder-services";
import { BaseMetalTable_PQRTube } from "./PQRObjects/BaseMetalTable_PQR";
import { PQR_Filler_metals } from "./PQRObjects/PQR_Filler_metals";

import axios from "axios";
import { Date_Input } from "../utility/CommonComponents/Date_Component";
import { WPS_Image } from "../WPSTransaction/WPSObjects/WPS_Image";
import {
  WPQ_PQR_Process_Grid_Input2_tube,
} from "../WPQTransaction/WPQObjects/WPQ_Grid_Tubesheet";
import {
  Single_Input_textaraNotes,
} from "../utility/CommonComponents/Inputs_Transaction"
import { maxDate, todayDate } from "../utility/CommonComponents/TodayDate";
import { DotLoader } from "react-spinners";
const PQRTubesheetPage1 = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      errorstyle,
      blyestyle,
      WPStype,
      isDisabled,
      isReadonly,
      copydata,
    },
    ref
  ) => {
    const location = useLocation();

    let username = sessionStorage.getItem("UserName");
    let folderPQR = "PQR_Tubesheet";


    const [PNoActive, setPNoActive] = useState([]);

    const [IsEdited, setIsEdited] = useState(false);

    const [ImageView1, setImageView1] = useState(false);
    const [ImageView2, setImageView2] = useState(false);
    const [ImageView3, setImageView3] = useState(false);

    const [SfaActive, setSfaActive] = useState([]);
    const [FnoActive, setFnoActive] = useState([]);
    const [AnoActive, setAnoActive] = useState([]);
    const [chemicalAnaError, setChemicalAnaError] = useState([]);

    const [checkwpsdata, setcheckwpsdata] = useState([]);

    const [classError, setClassError] = useState([]);

    const [action, setaction] = useState(location.state?.action);

    const [Readonly, setReadonly] = useState("");

    let isoverlay = WPStype === "Groove" ? false : true;

    let grooveclass = WPStype === "Groove" ? "row-margin" : "d-none";
    var overclass = WPStype !== "Groove" ? "row-margin" : "d-none";

    const [isOverlay, setOverlay] = useState(isoverlay);
    const [Grooveclass, setoverlayclass] = useState(grooveclass);
    const [overlaytext, setoverlaytext] = useState(overclass);

    const [profile, setProfile] = useState("");
    const [profile1, setProfile1] = useState("");
    const [profile2, setProfile2] = useState("");



    const [previewUrl1, setPreviewUrl1] = useState();
    const [previewUrl2, setPreviewUrl2] = useState();
    const [previewUrl3, setPreviewUrl3] = useState();

    const [loading, setLoading] = useState(false);


    const [isChecked, setIsChecked] = useState(true);
    const [fieldPWHT, setFieldPWHT] = useState(false);
    const FilemaxSize = 1 * 1024 * 1024;

    const [error, setError] = useState({
      PQR_RecordNo: {},
      PQR_Date: {},
      PQR_Qualificationcode: {},
      PQR_pWPSNo: {},
      PQR_WPS_ReviNo: {},
      PQR_LastUpdate_Date: {},
      PQR_Last_UpdatedBy: {},
      // PQR_MatchStatus: {},
      PQR_Last_WeldDate: {},
      PQR_Address: {},

      PQR_BM_BM_ProductForm: {},
      PQR_BM_BM_Spec: {},
      PQR_BM_BM_Pno: {},
      PQR_BM_BM_Gno: {},
      PQR_BM_BM_Thk: {},
      PQR_BM_BM_Dia: {},

      PQR_BM_WTBM_ProductForm: {},
      PQR_BM_WTBM_Spec: {},
      PQR_BM_WTBM_Pno: {},
      PQR_BM_WTBM_Gno: {},
      PQR_BM_WTBM_Thk: {},
      PQR_BM_WTBM_Dia: {},

      PQR_JD_JointType: {},
      PQR_JD_TubePitch: {},
      PQR_JD_TubeProj: {},
      PQR_JD_Pattern: {},
      PQR_JD_GrooveAngle: {},
      PQR_JD_HoleId: {},
      PQR_JD_GrooveDepth: {},
      PQR_JD_WidthLigament: {},
      

      PQRD_WP_LayerNo: [{ Value_A: [""] }, { Value_B: [""] }],
      PQRD_WP_FillerMetalSize: [{ Value_A: [""] }, { Value_B: [""] }],
      PQRD_WP_Amperes: [{ Value_A: [""] }, { Value_B: [""] }],
      PQRD_WP_DC_Pulsing_current: [{ Value_A: [""] }, { Value_B: [""] }],
      PQRD_WP_DC_Pulsing_current_Others: [{ Value_A: [""] }, { Value_B: [""] }],
      PQRD_WP_Volatage_Volts: [{ Value_A: [""] }, { Value_B: [""] }],
      PQRD_WP_Travel_Speed: [{ Value_A: [""] }, { Value_B: [""] }],
      PQRD_WP_Heat_Input: [{ Value_A: [""] }, { Value_B: [""] }],
      PQRD_WP_PolarityOrCurrent: [{ Value_A: [""] }, { Value_B: [""] }],


      PQR_JD_Notes: {},

      wld_pdf_image: {},
      PQR_Image_Notes: {},

      PQR_Process: {},
      WPSD_FM_SFA: {},
      WPSD_FM_Classification: {},
      WPSD_FM_FNo: {},
      WPSD_FM_Ano: {},
      WPSD_FM_ChemicalAnalysis_TradeName: {},

      PQRD_WP_MinimumPreheatTemp: {},
      PQRD_WP_MaximumInterpassTemp: {},
      PQRD_WP_PostHeating: {},
      PQRD_WP_TungstenType: {},
      PQRD_WP_WeldPosition: {},
      PQRD_WP_WeldProgression: {},
      PQRD_WP_NoofPasses: {},

      PQRD_WP_ArcVoltageControl: {},
      PQRD_WP_ShieldingGasType: {},
      PQRD_WP_ShieldingFlowRate: {},
      PQRD_WP_Auxiliarygas: {},
      PQRD_WP_Expansion: {},
      PQRD_WP_MethodofCleaning: {},

      PQR_PWHeatTre_Temperature: {},
      PQR_PWHeatTre_Time: {},
      PQR_PWHeatTre_Type: {},
      PQR_PWHeatTre_Heating_Rate: {},
      PQR_PWHeatTre_Cooling_Rate: {},
      PQR_PWHeatTre_Notes: {},

      PQR_Certification_Welder_Name: {},
      PQR_Certification_ID: {},
      PQR_Certification_Process: {},
      PQR_Certification_ConductedBy: {},
      PQR_Certification_Mechanical_TestsBy: {},
      PQR_Certification_Lab_RefNo: {},
    });


    const handle_2Input_ChangeA = (key, id, valueIndex, newValue) => {
      
      const updatedProcessObj = { ...detailObj };
      
      updatedProcessObj[key][id].Value_A[valueIndex] = newValue;
      
      setdetailObj(updatedProcessObj);
    };

    const handle_2Input_ChangeB = (key, id, valueIndex, newValue) => {

      
      const updatedProcessObj = { ...detailObj };
      
        updatedProcessObj[key][id].Value_B[valueIndex] = newValue;
     
      setdetailObj(updatedProcessObj);
    };

    const handleChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    
    const [valueNotes, setValueNotes] = useState("");
    const maxNewLines =3; // Maximum allowed new lines

    const handleChangeNotes = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
    
      const { name, value } = e.target;
      
      // Split the input text into lines based on existing newlines
      const lines = value.split('\n');
      
      // Limit each line to 100 characters
      const formattedLines = lines.map(line => {
        if (line.length > 100) {
          // Break the line into chunks of 100 characters
          return line.match(/.{1,100}/g).join('\n');
        }
        return line;
      });
    
      // Join the lines back together
      let formattedValue = formattedLines.join('\n');
      
      // Count the number of new lines
      const newLinesCount = (formattedValue.match(/\n/g) || []).length;
      
      if (newLinesCount <= maxNewLines) {
        setValueNotes(formattedValue);
    
        // Update the header object state with the new value
        setheaderObj((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      } else {
        e.preventDefault(); // Prevent further input if the new line limit is reached
      }
    };



    const validate = async () => {
      let entries;
      let entries1;
      entries = Object.entries(PQR_tubePage1);
      entries1 = Object.entries(PQR_Tube_Page1_Process);
      let tubePage1 = Object.entries(PQR_Detail_TubePage1);

      let isError = false;

      if (headerObj?.PQR_RecordNo.trim() === "") {
        headerObj.PQR_RecordNo = "";
        isError = true;
        error.PQR_RecordNo = {
          status: true,
          message: "Please enter PQR Record number",
        };
      }

      let norows = checkPQRTubeProcessAdded(detailObj);
      if (norows) {
        detailObj.errormessage =
          "Tubesheet SFA Type and F.No.,A.NO. are required";
        isError = true;
        detailObj.error = true;

        if (
          detailObj.PQR_Process !== "" &&
          detailObj.WPSD_FM_FNo !== "" &&
          detailObj.WPSD_FM_Ano !== "" &&
          detailObj.WPSD_FM_SFA !== ""
        ) {
          detailObj.error = false;
        }
      }

      entries.map(([key]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }

        return isError;
      });

      entries1.map(([key]) => {
        if (detailObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }

        return isError;
      });

      tubePage1.map(([key]) => {
        if (detailObj?.[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }

        if (error[key].message === "Special Characters not allowed") {
          isError = true;
          error[key] = {
            status: true,
            message: `Enter Valid Characters`,
          };
        }
        return isError;
      });

  
      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {

        if (
          await checkDuplicate(
            WPStype,
            headerObj.Wld_createdon_year,
            headerObj.PQR_RecordNo
          )
        ) {
          isError = true;
          error.PQR_RecordNo.status = true;
          error.PQR_RecordNo.message = "PQR Record No. already exists";
        } else {
          error.PQR_RecordNo.status = false;
          error.PQR_RecordNo.message = "";
        }
      }

      
      var errortype = PQR_tubesheet_pg1_array;

      errortype.forEach((cols) => {
      
        let data = { ...cols.Data };

 

        let value = "";
        var value1 = "";

        var msg = "";
        if (cols.Type === "S") {
          value = detailObj[cols.Column][0].Value_A[0];
          value1 = detailObj[cols.Column][1].Value_B[0];

          msg = `This field is required`;

        }


        if (
          value === undefined ||
          value === "" ||
          value === null ||
          value1 === undefined ||
          value1 === "" ||
          value1 === null
        ) {
     

          isError = true;
          setError((prevState) => ({
            ...error,
            ...prevState,

            [cols.Column]: [
              {
                Value_A: [
                  {
                    status: true,
                    message: msg,
                  },
                ],
              },
              {
                Value_B: [
                  {
                    status: true,
                    message: msg,
                  },
                ],
              },
            ],
            
          }));
        } else {

          setError((prevState) => ({
            ...prevState,
            [cols.Column]: [
              {
                Value_A: [
                  {
                    status: false,
                    message: "",
                  },
                ],
              },
              {
                Value_B: [
                  {
                    status: false,
                    message: "",
                  },
                ],
              },
            ],
          }));
        }

      var regExp = /^[A-Za-z0-9\s,:.@$()*!/_]+$/;


        if (
          error[cols.Column][0].Value_A[0].message ===
            "Special Characters not allowed" ||
          !value.match(regExp) ||
          !value1.match(regExp)
        ) {
          
          isError = true;

          setError((prevState) => ({
            ...prevState,

            [cols.Column]: [
              {
                Value_A: [
                  {
                    status: true,
                    message: "Enter Valid Characters",
                  },
                ],
              },
              {
                Value_B: [
                  {
                    status: true,
                    message: "Enter Valid Characters",
                  },
                ],
              },
            ],
         
          }));
        }
      });
    

    
      return isError;
    };


    const checkDuplicate = async (type, proj, revno) => {
      let reqParam = { groovetype: type, pkid: proj, skid: revno };
      let result = await getbyprojectno(reqParam);
      return !(result[0] === undefined);
    };

   
    const validateTemp = async () => {
      let isError = false;

      let temperror = emptyError(error, setError, isError);

      if (headerObj?.PQR_RecordNo.trim() === "") {
        headerObj.PQR_RecordNo = "";
        isError = true;
        error.PQR_RecordNo = {
          status: true,
          message: "Please enter PQR Record number",
        };
      }

      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        if (
          await checkDuplicate(
            WPStype,
            headerObj.Wld_createdon_year,
            headerObj.PQR_RecordNo
          )
        ) {
          isError = true;
          error.PQR_RecordNo.status = true;
          error.PQR_RecordNo.message = "PQR Record No. already exists";
        } else {
          error.PQR_RecordNo.status = false;
          error.PQR_RecordNo.message = "";
        }
      }

      let norows = checkPQRTubeProcessAdded(detailObj);

      if (norows) {
        detailObj.errormessage =
          "Tubesheet SFA Type is required";

        isError = true;
        detailObj.error = true;

        if (
          detailObj.PQR_Process !== "" &&
          detailObj.WPSD_FM_FNo !== "" &&
          detailObj.WPSD_FM_Ano !== "" &&
          detailObj.WPSD_FM_SFA !== ""
        ) {
          detailObj.error = false;
        }
      }
      

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return {isError ,temperror};
    };

    
    const handleCheckboxChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsChecked(e.target.checked)
      setFieldPWHT(!e.target.checked)
      setheaderObj((header) => ({
        ...header,
        CheckboxPostWHT: e.target.checked
      }));
    
    }


    const handleChangedetailsarray = (e, i) => {
      const { name, value } = e.target;
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChangeFillerMetal = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

   


    const handleAdd = async (approvalstatus) => {
    
      let result = true;

      let PG1Update = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_Date: headerObj.PQR_Date,
        PQR_Qualificationcode: headerObj.PQR_Qualificationcode,
        PQR_pWPSNo: headerObj.PQR_pWPSNo,
        PQR_WPS_ReviNo: headerObj.PQR_WPS_ReviNo,
        PQR_LastUpdate_Date: headerObj.PQR_LastUpdate_Date,
        PQR_Last_UpdatedBy: headerObj.PQR_Last_UpdatedBy,
        // PQR_MatchStatus: headerObj.PQR_MatchStatus,
        PQR_Last_WeldDate: headerObj.PQR_Last_WeldDate,
        CheckboxPostWHT: headerObj.CheckboxPostWHT,
        PQR_Address: headerObj.PQR_Address,
      };
  
      let PG1Images = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_Image_Notes: headerObj.PQR_Image_Notes,
        Pdf_images: headerObj?.Pdf_images,
        wld_pdf_image: headerObj?.wld_pdf_image,
      };
  
      let PG1BM = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_BM_BM_ProductForm: headerObj.PQR_BM_BM_ProductForm,
        PQR_BM_BM_Spec: headerObj.PQR_BM_BM_Spec,
        PQR_BM_BM_Pno: headerObj.PQR_BM_BM_Pno,
        PQR_BM_BM_Gno: headerObj.PQR_BM_BM_Gno,
        PQR_BM_BM_Thk: headerObj.PQR_BM_BM_Thk,
        PQR_BM_BM_Dia: headerObj.PQR_BM_BM_Dia,
        PQR_BM_WTBM_ProductForm: headerObj.PQR_BM_WTBM_ProductForm,
        PQR_BM_WTBM_Spec: headerObj.PQR_BM_WTBM_Spec,
        PQR_BM_WTBM_Pno: headerObj.PQR_BM_WTBM_Pno,
        PQR_BM_WTBM_Gno: headerObj.PQR_BM_WTBM_Gno,
        PQR_BM_WTBM_Thk: headerObj.PQR_BM_WTBM_Thk,
        PQR_BM_WTBM_Dia: headerObj.PQR_BM_WTBM_Dia,
  
      };
  
      let PG1JD = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_JD_JointType: headerObj.PQR_JD_JointType,
        PQR_JD_TubePitch: headerObj.PQR_JD_TubePitch,
        PQR_JD_TubeProj: headerObj.PQR_JD_TubeProj,
        PQR_JD_Pattern: headerObj.PQR_JD_Pattern,
        PQR_JD_GrooveAngle: headerObj.PQR_JD_GrooveAngle,
        PQR_JD_HoleId:headerObj.PQR_JD_HoleId ,
        PQR_JD_GrooveDepth:headerObj.PQR_JD_GrooveDepth ,
        PQR_JD_WidthLigament:headerObj.PQR_JD_WidthLigament ,
     
        PQR_JD_Notes: headerObj.PQR_JD_Notes,
      };
  
      let PG1PWHT = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        CheckboxPostWHT: headerObj.CheckboxPostWHT,
        PQR_PWHeatTre_Temperature: headerObj.PQR_PWHeatTre_Temperature,
        PQR_PWHeatTre_Time: headerObj.PQR_PWHeatTre_Time,
        PQR_PWHeatTre_Type: headerObj.PQR_PWHeatTre_Type,
        PQR_PWHeatTre_Heating_Rate: headerObj.PQR_PWHeatTre_Heating_Rate,
        PQR_PWHeatTre_Cooling_Rate: headerObj.PQR_PWHeatTre_Cooling_Rate,
        PQR_PWHeatTre_Notes: headerObj.PQR_PWHeatTre_Notes,
  
      };
  
      let CERTI = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_Certification_Welder_Name: headerObj.PQR_Certification_Welder_Name,
        PQR_Certification_ID: headerObj.PQR_Certification_ID,
        PQR_Certification_Process: headerObj.PQR_Certification_Process,
        PQR_Certification_ConductedBy: headerObj.PQR_Certification_ConductedBy,
        PQR_Certification_Mechanical_TestsBy:
          headerObj.PQR_Certification_Mechanical_TestsBy,
        PQR_Certification_Lab_RefNo: headerObj.PQR_Certification_Lab_RefNo,
        WPSH_Status: Status.StatusTempSaved,
        WPSH_Approval_Status: Status.StatusTempSaved,
      };

      if (
        checkwpsdata !== undefined ||
        checkwpsdata !== "" ||
        checkwpsdata !== null
      ) {
        headerObj.WPS_Key = checkwpsdata?.SK;
      }

      headerObj.PQR_Last_UpdatedBy = username;

      headerObj.WPSH_Status = approvalstatus;


      var WPSH_PQR_Process = "";
      var WPSH_PQR_Type = "";

      detailObj.wld_WPSType = headerObj?.wld_WPSType;
      detailObj.PQR_RecordNo = headerObj?.PQR_RecordNo;
      
      detailObj.PK = headerObj?.wld_WPSType + "#" + headerObj?.PQR_RecordNo;

      WPSH_PQR_Process += detailObj?.PQR_Process;
      WPSH_PQR_Type += detailObj?.WPSD_FM_SFA;

      headerObj.WPSH_PQR_Process = WPSH_PQR_Process;
      headerObj.WPSH_PQR_Type = WPSH_PQR_Type;

      detailObj.wld_WPSType = headerObj?.wld_WPSType;

      detailObj.SK =
        headerObj?.WPSH_PQR_Process + "#" + headerObj?.WPSH_PQR_Type;

      let PG1Add = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,

        WPSH_PQR_Process: detailObj?.PQR_Process,
        WPSH_PQR_Type: detailObj?.WPSD_FM_SFA,
        WPSH_Status: headerObj.WPSH_Status,
        Pdf_images: headerObj?.Pdf_images,
        wld_pdf_image: headerObj?.wld_pdf_image,
        CheckboxPostWHT: headerObj?.CheckboxPostWHT,
      };
      
      const postAdd = [PG1Add];
      const postUpdate = [PG1Update, PG1Images, PG1BM, PG1JD, PG1PWHT, CERTI];
      await AddUpdatePQRHeader(postAdd, postUpdate, action,setaction)
        .catch((err) => {
           // alert("here")
            result = MessageTypes.Fail

        })
        .then(async (r) => {
       
          try {
           // alert(r)
            
            if(r == MessageTypes.SpecialCharacters)
                result = MessageTypes.SpecialCharacters

            
            
            if(profile !== ""){

            if (headerObj?.Pdf_images[0]?.tube_Hole_ligament[0] !== "" ) {
            
              const result = await getPresignedURL(
                folderPQR,
                headerObj.Pdf_images[0].tube_Hole_ligament[0]
              );
              const posturl = result.url;
              setPreviewUrl1(posturl);
              await axios.put(posturl, profile);
              setImageView1(true)

            }
          }
          if(profile1 !== ""){

            if (headerObj?.Pdf_images[1].tube_Hole[0] !== "") {
              
              const result = await getPresignedURL(
                folderPQR,
                headerObj?.Pdf_images[1].tube_Hole[0]
              );
              const posturl = result.url;
              setPreviewUrl2(posturl);
              await axios.put(posturl, profile1);
              setImageView2(true)

            }
          }
          if(profile2 !== ""){

            if (headerObj?.Pdf_images[2].tube_Weld_Joint[0] !== "") {
              
              const result = await getPresignedURL(
                folderPQR,
                headerObj?.Pdf_images[2].tube_Weld_Joint[0]
              );
              const posturl = result.url;
              setPreviewUrl3(posturl);
              await axios.put(posturl, profile2);
              setImageView3(true)

            }
          }
          } catch (error) {
            console.log("Imgae upload failed")
          }
   
          
           await AddNewDetail(detailObj)
            .catch((err) => {
              
              
            
              result = MessageTypes.Fail
            })
            .then((r) => {
              console.log("Detail error",r)
              if(r !=undefined && r.data != undefined && r.data.message == MessageTypes.SpecialCharacters)
                result = MessageTypes.SpecialCharacters
        
             
              
            });
            
        });
        
      ///alert(result)
      return result;
    };



    const fetchPNo = async () => {
      const result = await getPNOActive();

      setPNoActive(result);
    };

    const fetchSfa = async () => {
      const result = await getActiveSfa();
      setSfaActive(result);
    };
    const fetchAno = async () => {
      const result = await getActiveAno();
      setAnoActive(result);
    };
    const fetchFno = async () => {
      const result = await getActiveFno();
      setFnoActive(result);
    };

    useEffect(() => {
    
      fetchPNo();
      fetchSfa();
      fetchAno();
      fetchFno();
    }, []);

     
    const fetchImagestube1 = async (item) => {
      
          if (item) {
            
            const result = await getPresignedURL(
              folderPQR,
              item
            );
            const posturl = result.url;
            setPreviewUrl1(posturl);
          }
      
        }
      
        const fetchImagestube2 = async (item) => {
          
              if (item) {
               
                const result = await getPresignedURL(
                  folderPQR,
                  item
                );
                const posturl = result.url;
                setPreviewUrl2(posturl);
              }
          
            }
      
            const fetchImagestube3 = async (item) => {
              
                  if (item) {
                     const result = await getPresignedURL(
                      folderPQR,
                      item
                    );
                    const posturl = result.url;
                    setPreviewUrl3(posturl);
                  }
              
                }
    
      
          useEffect(() => {
            
            if (action === Action.ActionEdit && headerObj?.Pdf_images[0]?.tube_Hole_ligament[0] !== "")
              {
              fetchImagestube1(headerObj?.Pdf_images[0]?.tube_Hole_ligament[0])
              }
      
              if (action === Action.ActionEdit && headerObj?.Pdf_images[1]?.tube_Hole[0] !== "")
                {
                fetchImagestube2(headerObj?.Pdf_images[1]?.tube_Hole[0])
                }
              if (action === Action.ActionEdit && headerObj?.Pdf_images[2]?.tube_Weld_Joint[0] !== "")
                {
                fetchImagestube3(headerObj?.Pdf_images[2]?.tube_Weld_Joint[0])
                }
            
          }, []);
      
      
          
          useEffect(() => {
            
            if (ImageView1 === true && headerObj?.Pdf_images[0]?.tube_Hole_ligament[0] !== "")
            {
              fetchImagestube1(headerObj?.Pdf_images[0]?.tube_Hole_ligament[0])
            }
          }, [ImageView1]);
      
          useEffect(() => {
            if (ImageView2 === true && headerObj?.Pdf_images[1]?.tube_Hole[0] !== "")
            {
              fetchImagestube2(headerObj?.Pdf_images[1]?.tube_Hole[0])
            }
          }, [ImageView2]);
      
          useEffect(() => {
          if (ImageView3 === true && headerObj?.Pdf_images[2]?.tube_Weld_Joint[0] !== "")
            {
              fetchImagestube3(headerObj?.Pdf_images[2]?.tube_Weld_Joint[0])
            }
          }, [ImageView3]);

    const fileInputRef = useRef(null);




    const handleSubmit = async () => {

      let result = false;
      if (!(await validate())) {
        setLoading(true)
        result = await handleAdd(Status.StatusTempSaved);
        setLoading(false)

        if (result == true) {
          return MessageTypes.Success;
        }
        else if(result == MessageTypes.SpecialCharacters){
          return MessageTypes.SpecialCharacters
        }
         else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.Required;
      }
    };

    const handleSubmitTemp = async () => {
      
      let result = false;
      var val = await validateTemp();
   
          setIsEdited(false);
          if (val.isError === false){
            if ( val.temperror === false) {
            
              setLoading(true)

                result = await handleAdd(headerObj?.WPSH_Status,"");
                setLoading(false)

                if (result == true) {
                  return MessageTypes.Temp;
                }
                else if(result == MessageTypes.SpecialCharacters){
                  return MessageTypes.SpecialCharacters
                }
                 else {
                  return MessageTypes.Fail;
                }
      
            }
            return MessageTypes.SpecialCharacters;
          }
          return MessageTypes.Required;
        

    };

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp: async () => {
        var res = false;
        res = await handleSubmitTemp();
        return res;
      },
    }));



    const handleFile1 = (file,index, e) => {
      
      const maxSize = FilemaxSize;
      if (file.size > maxSize) {
        alert("File size exceeds the allowed limit. Add File Less Than 1 Mb");
        file = null;
        return;
      }

      let uploadextension = file.name.split(".");
      
      if (
        uploadextension[1] !== "jpeg" &&
        uploadextension[1] !== "png" &&
        uploadextension[1] !== "jpg"
      ) {
        alert("Please Upload Jpeg,png or jpg images");
        file = null;
        return;
      }
      if(index == 0 ){
      setProfile(file);
      setPreviewUrl1(URL.createObjectURL(file));
      headerObj.Pdf_images[0].tube_Hole_ligament[0] = file.name;
      }
      else if(index == 1){
      setProfile1(file);
      setPreviewUrl2(URL.createObjectURL(file));
      headerObj.Pdf_images[1].tube_Hole[0] = file.name;
      }
      else if(index == 2){
      setProfile2(file);
      setPreviewUrl3(URL.createObjectURL(file));
      headerObj.Pdf_images[2].tube_Weld_Joint[0] = file.name;
      }
    };
   

    const fetchUserProfiles = async () => {
      try {
        if (headerObj.Pdf_images[0].tube_Hole_ligament[0] !== "") {
          const result = await getPresignedURL(
            folderPQR,
            headerObj.Pdf_images[0].tube_Hole_ligament[0]
          );
          const geturl = result.url;
          setPreviewUrl1(geturl);
        }

        if (headerObj.Pdf_images[1].tube_Hole[0] !== "") {
          const result = await getPresignedURL(
            folderPQR,
            headerObj.Pdf_images[1].tube_Hole[0]
          );
          const geturl = result.url;
          setPreviewUrl2(geturl);
        }
        if (headerObj.Pdf_images[2].tube_Weld_Joint[0] !== "") {
          const result = await getPresignedURL(
            folderPQR,
            headerObj.Pdf_images[2].tube_Weld_Joint[0]
          );
          const geturl = result.url;
          setPreviewUrl3(geturl);
        }
      } catch (error) {console.log("Error",error)}
    };
    useEffect(() => {
      fetchUserProfiles();
    }, []);

    return (
      <Row className="font">
           {loading && (
      <div className="loader-overlay">
        <DotLoader size={80} className="pageloader" />
      </div>
    )}
        <Row style={{padding: "20px 60px" }} className="PQR-Page-row-padding">
          <Row className="mb-2">
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="PQR_RecordNo"
                  type="text"
                  maxLength={8}
                  readOnly={isReadonly}
                  status={error.PQR_RecordNo.status}
                  value={headerObj?.PQR_RecordNo}
                  disabled={action === Action.ActionEdit ? true : false}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="PQR Record No."
                  message={error.PQR_RecordNo.message}
                 
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Date_Input
                divClass="T-input w-100"
                label="Date"
                className="groove_datecolor"
                name="PQR_Date"
                               todayDate={todayDate}
                maxDate={maxDate}
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
                status={error.PQR_Date.status}
                message={error.PQR_Date.message}
              />
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="PQR_Qualificationcode"
                  type="text"
                  maxLength={20}
                  readOnly={isReadonly}
                  status={error.PQR_Qualificationcode.status}
                  value={headerObj?.PQR_Qualificationcode}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="Qualification Code"
                  message={error.PQR_Qualificationcode.message}
                  
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="PQR_pWPSNo"
                  type="text"
                  maxLength={20}
                  status={error.PQR_pWPSNo.status}
                  value={headerObj?.PQR_pWPSNo}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="pWPSNo(optional)"
                  message={error.PQR_pWPSNo.message}
                  
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="PQR_WPS_ReviNo"
                  type="text"
                  maxLength={2}
                  readOnly={isReadonly}
                  status={error.PQR_WPS_ReviNo.status}
                  value={headerObj?.PQR_WPS_ReviNo}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="WPS Revision No."
                  message={error.PQR_WPS_ReviNo.message}
                 
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Date_Input
                divClass="T-input w-100"
                label="Last Update Date"
                className="groove_datecolor"
                name="PQR_LastUpdate_Date"
                               todayDate={todayDate}
                maxDate={maxDate}
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
                status={error.PQR_LastUpdate_Date.status}
                message={error.PQR_LastUpdate_Date.message}
              />
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="PQR_Last_UpdatedBy"
                  type="text"
                  maxLength={20}
                  status={error.PQR_Last_UpdatedBy.status}
                  value={headerObj?.PQR_Last_UpdatedBy}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Last Updated By"
                  message={error.PQR_Last_UpdatedBy.message}
                 
                ></Single_Input>
              </Form.Group>
            </Col>

           
            <Col md={3}>
              <Date_Input
                divClass="T-input w-100"
                label="Last Weld Date"
                className="groove_datecolor"
                name="PQR_Last_WeldDate"
                               todayDate={todayDate}
                maxDate={maxDate}
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
                status={error.PQR_Last_WeldDate.status}
                message={error.PQR_Last_WeldDate.message}
              />
            </Col>

            <Col md={3}>
              <Form.Group>
                <General_Single_Input_select
                  name="PQR_Address"
                  type="text"
                  maxLength={20}
                  readOnly={isReadonly}
                  status={error.PQR_Address.status}
                  value={headerObj?.PQR_Address}
                  handleSelectChange={handleChange}
                  label="Address"
                  message={error.PQR_Address.message}
                  array={Plant_Location}
                ></General_Single_Input_select>
              </Form.Group>
            </Col>
          </Row>
        </Row>

        <Row style={{ background: "white", padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative",top:"10px"}}>BASE METALS ( QW-403) </p>
            
              <BaseMetalTable_PQRTube
                headerObj={headerObj}
                setheaderObj={setheaderObj}
                error={error}
                isReadonly={isReadonly}
                isDisabled={isDisabled}
                handleChange={handleChange}
                setError={setError}
                PNoActive={PNoActive}
                blyestyle={blyestyle}
                errorstyle={errorstyle}
              ></BaseMetalTable_PQRTube>
            </Col>
          </Row>
        </Row>

        <Row style={{padding: "20px 60px" }} className="PQR-Page-row-padding">
          <span className="WPS-blue-text" style={{position:"relative",top:"10px",right:"10px"}}>Joint Detail</span>
          <Row className="mb-4">
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="PQR_JD_JointType"
                  name="PQR_JD_JointType"
                  value={pqrJointType[0].value}
                  readOnly
                  placeholder=" "
                />
                <label for="PQR_JD_JointType">Joint Type</label>
              </div>
            </Col>

            <Col>
            <Single_Input
                  name="PQR_JD_TubePitch"
                  type="text"
                  maxLength={20}
                  status={error.PQR_JD_TubePitch.status}
                  value={headerObj?.PQR_JD_TubePitch}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Tube Pitch"
                  message={error.PQR_JD_TubePitch.message}
                 
                ></Single_Input>

            </Col>
            <Col>
            <Single_Input
                  name="PQR_JD_TubeProj"
                  type="text"
                  maxLength={20}
                  status={error.PQR_JD_TubeProj.status}
                  value={headerObj?.PQR_JD_TubeProj}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Tube Projection"
                  message={error.PQR_JD_TubeProj.message}
                 
                ></Single_Input>
           
            </Col>
            <Col>
            <Single_Input
                  name="PQR_JD_Pattern"
                  type="text"
                  maxLength={20}
                  status={error.PQR_JD_Pattern.status}
                  value={headerObj?.PQR_JD_Pattern}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Pattern"
                  message={error.PQR_JD_Pattern.message}
                 
                ></Single_Input>

            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={3}>
            <Single_Input
                  name="PQR_JD_HoleId"
                  type="text"
                  maxLength={20}
                  status={error.PQR_JD_HoleId.status}
                  value={headerObj?.PQR_JD_HoleId}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Hole ID(mm)"
                  message={error.PQR_JD_HoleId.message}
                 
                ></Single_Input>
  
            </Col>
            <Col md={3}>
            <Single_Input
                  name="PQR_JD_GrooveDepth"
                  type="text"
                  maxLength={20}
                  status={error.PQR_JD_GrooveDepth.status}
                  value={headerObj?.PQR_JD_GrooveDepth}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Groove Depth(mm)"
                  message={error.PQR_JD_GrooveDepth.message}
                 
                ></Single_Input>
     
            </Col>
            <Col md={3}>
            <Single_Input
                  name="PQR_JD_WidthLigament"
                  type="text"
                  maxLength={20}
                  status={error.PQR_JD_WidthLigament.status}
                  value={headerObj?.PQR_JD_WidthLigament}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Width of the Ligament(mm)"
                  message={error.PQR_JD_WidthLigament.message}
                 
                ></Single_Input>
        
            </Col>
                    <Col md={3}>
                    <Single_Input
                  name="PQR_JD_GrooveAngle"
                  type="text"
                  maxLength={20}
                  status={error.PQR_JD_GrooveAngle.status}
                  value={headerObj?.PQR_JD_GrooveAngle}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Groove Angle(°)/Radius(mm)"
                  message={error.PQR_JD_GrooveAngle.message}
                 
                ></Single_Input>
                        </Col>
          </Row>
          <Row className="mb-4" style={{position:"relative",bottom:"7px"}}>
            <Col md={12}>
            <Single_Input
                  name="PQR_JD_Notes"
                  type="text"
                  maxLength={20}
                  status={error.PQR_JD_Notes.status}
                  value={headerObj?.PQR_JD_Notes}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Notes"
                  message={error.PQR_JD_Notes.message}
                 
                ></Single_Input>
           
            </Col>
          </Row>
        </Row>

        <Row style={{padding: "20px 60px" , position:"relative", bottom:"50px" }} className="PQR-Page-row-padding">
        <div style={{display:"flex",  position:"relative", top:"10px", paddingLeft: "0"}}>
          <span className="WPS-blue-text col-md-4">Tube Hole Ligament Detail</span>

            <span className="WPS-blue-text col-md-4">Tube Hole Detail</span>
         
            <span className="WPS-blue-text col-md-4"> Tube to Tubesheet Weld Joint Detail </span>
          </div>

<WPS_Image
            handleFile={handleFile1}
           
            previewUrl1={previewUrl1}
            previewUrl2={previewUrl2}
            previewUrl3={previewUrl3}
            headerObj={headerObj}
            setheaderObj={setheaderObj}
  
            name="fileInput"
            name1="fileInput1"
            name2="fileInput2"
            name3="fileInput3"
            type="file"
            accept="image/*"
            fileInputRef={fileInputRef}
            readOnly={Readonly}
            disabled={isDisabled}
          ></WPS_Image>

          <Col
            md={12}
            style={{ position: "relative", top: "10px", right: "10px" }}
          >
            <div class="T-input w-100">
              <textarea
                type="text"
                name="PQR_Image_Notes"
                style={{ height: "100px" }}
                id="PQR_Image_Notes"
                readOnly={isReadonly}
                maxLength={90}
                placeholder=" "
                className={
                  error.PQR_Image_Notes.status
                    ? "T-input placeHolderInput inputText text-field-with-error"
                    : "T-input placeHolderInput inputText"
                }
                value={headerObj?.PQR_Image_Notes}
                onChange={handleChange}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_Image_Notes: error,
                  }));
                }}
              />
              <label for="WPQ_Page2_Notes">Notes</label>
            </div>
            <div className="required-text1">
              {error.PQR_Image_Notes.status && (
                <Form.Text className="text-danger">
                  {error.PQR_Image_Notes.message}
                </Form.Text>
              )}
            </div>
          </Col>
        </Row>

        <Row style={{ padding: "20px 60px", position:"relative", bottom:"55px"  }}>
          <Col md={12}>
            <p className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Filler Metals (QW-404)</p>
            <PQR_Filler_metals
              headerObj={headerObj}
              detailObj={detailObj}
              disabled={isDisabled}
              error={error}
              readOnly={Readonly}
              handleChangedetailsarray={handleChangedetailsarray}
              handleChangeFillerMetal={handleChangeFillerMetal}
              setError={setError}
              setdetailObj={setdetailObj}
              SfaActive={SfaActive}
              AnoActive={AnoActive}
              FnoActive={FnoActive}
              classError={classError}
              setClassError={setClassError}
              chemicalAnaError={chemicalAnaError}
              setChemicalAnaError={setChemicalAnaError}
              isOverlay={isOverlay}
              setOverlay={setOverlay}
              Grooveclass={Grooveclass}
              setoverlayclass={setoverlayclass}
              overlaytext={overlaytext}
              action={action}
              setaction={setaction}
            ></PQR_Filler_metals>
          </Col>
        </Row>

        <Row className="mb-4" style={{ padding: "0px 60px",position:"relative",bottom:"65px" }}>
          <span className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Welding Procedure</span>
          <Col>
            <Table
              bordered
              responsive="sm"
              className="text-center tube-col-widths"
              style={{position:"relative", bottom:"10px"}}
            >
              <tbody>
              <PQR_Process_Grid_InputProcess
                    detailObj={detailObj}
                     readOnly={isReadonly}
                     disabled={isDisabled}
                    ColumnHeader="Welding Process & Type"
                    type="text"
                    name="PQRD_WP_WeldingProcess"                 
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></PQR_Process_Grid_InputProcess>

                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="Minimunm Preheat Temp"
                  Columndimension="°C"
                  width1={275}
                  status={error.PQRD_WP_MinimumPreheatTemp.status}
                  message={error.PQRD_WP_MinimumPreheatTemp.message}
                  type="text"
                  name="PQRD_WP_MinimumPreheatTemp"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_MinimumPreheatTemp: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>

                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={10}
                  width1={275}
                  ColumnHeader=" Maximum Interpass Temp"
                  Columndimension="°C"
                  status={error.PQRD_WP_MaximumInterpassTemp.status}
                  message={error.PQRD_WP_MaximumInterpassTemp.message}
                  type="text"
                  name="PQRD_WP_MaximumInterpassTemp"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_MaximumInterpassTemp: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>

                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={20}
                  width1={275}
                  ColumnHeader="Post Heating/DHT"
                  status={error.PQRD_WP_PostHeating.status}
                  message={error.PQRD_WP_PostHeating.message}
                  type="text"
                  name="PQRD_WP_PostHeating"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_PostHeating: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>

                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={20}
                  width1={275}
                  ColumnHeader="Tungsten Type and Size"
                  Columndimension="mm"
                  status={error.PQRD_WP_TungstenType.status}
                  message={error.PQRD_WP_TungstenType.message}
                  type="text"
                  name="PQRD_WP_TungstenType"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_TungstenType: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>
                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={20}
                  width1={275}
                  ColumnHeader="Welding Position"
                  status={error.PQRD_WP_WeldPosition.status}
                  message={error.PQRD_WP_WeldPosition.message}
                  type="text"
                  name="PQRD_WP_WeldPosition"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_WeldPosition: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>
                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={20}
                  width1={275}
                  ColumnHeader="Progression"
                  status={error.PQRD_WP_WeldProgression.status}
                  message={error.PQRD_WP_WeldProgression.message}
                  type="text"
                  name="PQRD_WP_WeldProgression"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_WeldProgression: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>
                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={4}
                  width1={275}
                  ColumnHeader="No of Passes"
                  status={error.PQRD_WP_NoofPasses.status}
                  message={error.PQRD_WP_NoofPasses.message}
                  type="text"
                  name="PQRD_WP_NoofPasses"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_NoofPasses: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>
              </tbody>
            </Table>
            
            <Table
              bordered
              responsive="sm"
              className="text-center tube-col-widths"
              style={{ position: "relative", bottom: "30px" }}
            >
              <tbody>
                <WPQ_PQR_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="Layer Number"
                  status={error.PQRD_WP_LayerNo[0]?.Value_A[0]?.status}
                  message={error.PQRD_WP_LayerNo[0]?.Value_A[0]?.message}
                  status1={error.PQRD_WP_LayerNo[1]?.Value_B[0]?.status}
                  message1={error.PQRD_WP_LayerNo[1]?.Value_B[0]?.message}
                  type="text"
                  name="PQRD_WP_LayerNo"
                  setError={setError}
                  error={error}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPQ_PQR_Process_Grid_Input2_tube>

                <WPQ_PQR_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="Filler Metal Size"
                  Columndimension="mm"
                  status={error.PQRD_WP_FillerMetalSize[0]?.Value_A[0]?.status}
                  message={
                    error.PQRD_WP_FillerMetalSize[0]?.Value_A[0]?.message
                  }
                  status1={error.PQRD_WP_FillerMetalSize[1]?.Value_B[0]?.status}
                  message1={
                    error.PQRD_WP_FillerMetalSize[1]?.Value_B[0]?.message
                  }
                  type="text"
                  setError={setError}
                  name="PQRD_WP_FillerMetalSize"
                  error={error}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPQ_PQR_Process_Grid_Input2_tube>

                <WPQ_PQR_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="Amperes Amps"
                  status={error.PQRD_WP_Amperes[0]?.Value_A[0]?.status}
                  message={error.PQRD_WP_Amperes[0]?.Value_A[0]?.message}
                  status1={error.PQRD_WP_Amperes[1]?.Value_B[0]?.status}
                  message1={error.PQRD_WP_Amperes[1]?.Value_B[0]?.message}
                  type="text"
                  name="PQRD_WP_Amperes"
                  setError={setError}
                  error={error}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPQ_PQR_Process_Grid_Input2_tube>

                <WPQ_PQR_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="DC Pulsing Current"
                  Columndimension="Amps"
                  status={error.PQRD_WP_DC_Pulsing_current[0]?.Value_A[0]?.status}
                  message={
                    error.PQRD_WP_DC_Pulsing_current[0]?.Value_A[0]?.message
                  }
                  status1={
                    error.PQRD_WP_DC_Pulsing_current[1]?.Value_B[0]?.status
                  }
                  message1={
                    error.PQRD_WP_DC_Pulsing_current[1]?.Value_B[0]?.message
                  }
                  type="text"
                  name="PQRD_WP_DC_Pulsing_current"
                  setError={setError}
                  error={error}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPQ_PQR_Process_Grid_Input2_tube>
                <WPQ_PQR_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="DC Pulsing Current - Others"
                  Columndimension="Amps"
                  status={error.PQRD_WP_DC_Pulsing_current_Others[0]?.Value_A[0]?.status}
                  message={
                    error.PQRD_WP_DC_Pulsing_current_Others[0]?.Value_A[0]?.message
                  }
                  status1={
                    error.PQRD_WP_DC_Pulsing_current_Others[1]?.Value_B[0]?.status
                  }
                  message1={
                    error.PQRD_WP_DC_Pulsing_current_Others[1]?.Value_B[0]?.message
                  }
                  type="text"
                  error={error}
                  name="PQRD_WP_DC_Pulsing_current_Others"
                  setError={setError}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPQ_PQR_Process_Grid_Input2_tube>

                <WPQ_PQR_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="Voltage Volts"
                  Columndimension="Kj/mm"
                  status={error.PQRD_WP_Volatage_Volts[0]?.Value_A[0]?.status}
                  message={error.PQRD_WP_Volatage_Volts[0]?.Value_A[0]?.message}
                  status1={error.PQRD_WP_Volatage_Volts[1]?.Value_B[0]?.status}
                  message1={error.PQRD_WP_Volatage_Volts[1]?.Value_B[0]?.message}
                  type="text"
                  name="PQRD_WP_Volatage_Volts"
                  setError={setError}
                  error={error}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPQ_PQR_Process_Grid_Input2_tube>

                <WPQ_PQR_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="Travel Speed"
                  Columndimension="mm/min"
                  status={error.PQRD_WP_Travel_Speed[0]?.Value_A[0]?.status}
                  message={error.PQRD_WP_Travel_Speed[0]?.Value_A[0]?.message}
                  status1={error.PQRD_WP_Travel_Speed[1]?.Value_B[0]?.status}
                  message1={error.PQRD_WP_Travel_Speed[1]?.Value_B[0]?.message}
                  type="text"
                  name="PQRD_WP_Travel_Speed"
                  setError={setError}
                  error={error}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPQ_PQR_Process_Grid_Input2_tube>

                <WPQ_PQR_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="Heat Input"
                  Columndimension="Kj/mm"
                  status={error.PQRD_WP_Heat_Input[0]?.Value_A[0]?.status}
                  message={error.PQRD_WP_Heat_Input[0]?.Value_A[0]?.message}
                  status1={error.PQRD_WP_Heat_Input[1]?.Value_B[0]?.status}
                  message1={error.PQRD_WP_Heat_Input[1]?.Value_B[0]?.message}
                  type="text"
                  name="PQRD_WP_Heat_Input"
                  setError={setError}
                  error={error}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPQ_PQR_Process_Grid_Input2_tube>

                <WPQ_PQR_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={10}
                  ColumnHeader="Polarity or Current type"
                  status={error.PQRD_WP_PolarityOrCurrent[0]?.Value_A[0]?.status}
                  message={
                    error.PQRD_WP_PolarityOrCurrent[0]?.Value_A[0]?.message
                  }
                  status1={error.PQRD_WP_PolarityOrCurrent[1]?.Value_B[0]?.status}
                  message1={
                    error.PQRD_WP_PolarityOrCurrent[1]?.Value_B[0]?.message
                  }
                  type="text"
                  name="PQRD_WP_PolarityOrCurrent"
                  setError={setError}
                  error={error}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPQ_PQR_Process_Grid_Input2_tube>
              </tbody>
            </Table>

            <Table
              bordered
              responsive="sm"
              className="text-center tube-col-widths"
              style={{ position: "relative", bottom: "50px" }}
            >
              <tbody>
                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={20}
                  width1={275}
                  ColumnHeader="Automatic Arc Voltage control"
                  status={error.PQRD_WP_ArcVoltageControl.status}
                  message={error.PQRD_WP_ArcVoltageControl.message}
                  type="text"
                  name="PQRD_WP_ArcVoltageControl"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_ArcVoltageControl: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>

                <PQR_Process_Grid_Input_2
                  detailObj={detailObj}
                  maxLength={50}
                  width1={275}
                  mainheading="Shielding"
                  ColumnHeader="Gas Type - % comp"
                  ColumnHeader1="Flow Type"
                  Columndimension="I/min"
                  status={
                    error.PQRD_WP_ShieldingGasType.status ||
                    error.PQRD_WP_ShieldingFlowRate.status
                  }
                  message={
                    error.PQRD_WP_ShieldingGasType.message ||
                    error.PQRD_WP_ShieldingFlowRate.message
                  }
                  type="text"
                  name="PQRD_WP_ShieldingGasType"
                  name1="PQRD_WP_ShieldingFlowRate"
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_ShieldingGasType: error,
                    }));
                  }}
                  onInput1={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_ShieldingFlowRate: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_Process_Grid_Input_2>

                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={50}
                  width1={275}
                  ColumnHeader="Auxiliarygas shield system"
                  status={error.PQRD_WP_Auxiliarygas.status}
                  message={error.PQRD_WP_Auxiliarygas.message}
                  type="text"
                  name="PQRD_WP_Auxiliarygas"
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_Auxiliarygas: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>

                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={10}
                  width1={275}
                  ColumnHeader="Expansion"
                  status={error.PQRD_WP_Expansion.status}
                  message={error.PQRD_WP_Expansion.message}
                  type="text"
                  name="PQRD_WP_Expansion"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_Expansion: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>

                <PQR_TTS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={50}
                  width1={275}
                  ColumnHeader="Method of Cleaning"
                  status={error.PQRD_WP_MethodofCleaning.status}
                  message={error.PQRD_WP_MethodofCleaning.message}
                  type="text"
                  name="PQRD_WP_MethodofCleaning"
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      PQRD_WP_MethodofCleaning: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></PQR_TTS_Process_Grid_Input>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row style={{padding: "20px 90px" , position:"relative",bottom:"85px"}} className="PQR-Page-row-padding">
          <Row>
            <Row>
              <div className="checkboxproject">
              <input
                  type="checkbox"
                  placeholder=" "
                  id="chkcode"
                  name="CheckboxPostWHT"
                  onClick={() => {
                    if(isChecked === true || headerObj?.CheckboxPostWHT === "true"){
                      setheaderObj((prevState) => ({
                        ...prevState,
                        PQR_PWHeatTre_Temperature: "Not Applicable",
                        PQR_PWHeatTre_Time: "Not Applicable",
                        PQR_PWHeatTre_Type: "Not Applicable",
                        PQR_PWHeatTre_Heating_Rate: "Not Applicable",
                        PQR_PWHeatTre_Heating_Method: "Not Applicable",
                        PQR_PWHeatTre_Cooling_Rate: "Not Applicable",
                        PQR_PWHeatTre_Cooling_Method: "Not Applicable",
                        PQR_PWHeatTre_Notes: "Not Applicable",
                        PQR_PWHeatTre_Maintenance: "Not Applicable",
                              }));

                              setError((prevState) => ({
                                ...prevState,
                                PQR_PWHeatTre_Temperature:{status:false,message:""},
                                PQR_PWHeatTre_Time:{status:false,message:""},
                                PQR_PWHeatTre_Type:{status:false,message:""},
                                PQR_PWHeatTre_Heating_Rate:{status:false,message:""},
                                PQR_PWHeatTre_Heating_Method:{status:false,message:""},
                                PQR_PWHeatTre_Cooling_Rate:{status:false,message:""},
                                PQR_PWHeatTre_Cooling_Method:{status:false,message:""},
                                PQR_PWHeatTre_Notes:{status:false,message:""},
                                PQR_PWHeatTre_Maintenance:{status:false,message:""},
                              }));
                    }
                    
                    if(isChecked === false || headerObj?.CheckboxPostWHT === "false"){
                      setheaderObj((prevState) => ({
                        ...prevState,
                        PQR_PWHeatTre_Temperature: "",
                        PQR_PWHeatTre_Time: "",
                        PQR_PWHeatTre_Type: "",
                        PQR_PWHeatTre_Heating_Rate: "",
                        PQR_PWHeatTre_Heating_Method: "",
                        PQR_PWHeatTre_Cooling_Rate: "",
                        PQR_PWHeatTre_Cooling_Method: "",
                        PQR_PWHeatTre_Notes: "",
                        PQR_PWHeatTre_Maintenance: "",
                              }));
                    }
                  }}
                  onChange={handleCheckboxChange}
                  value={headerObj?.CheckboxPostWHT === "false" ? false : isChecked  }
                  checked={headerObj?.CheckboxPostWHT === "false" ? false : isChecked  }
 
                />{" "}
                <span className="WPS-blue-text" style={{position:"relative",left:"5px",bottom:"2px"}}>
                  Post Weld Heat Treatment Tubesheet (QW-407)
                </span>
              </div>
            </Row>
            <Row className="mb-1" style={{ position: "relative", top: "18px" }}>
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="PQR_PWHeatTre_Temperature"
                  type="text"
                  maxLength={10}
                  status={error.PQR_PWHeatTre_Temperature.status}
                  value={headerObj?.PQR_PWHeatTre_Temperature}
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  onChange={handleChange}
                  label="Temperarture(°C)"
                  setError={setError}
                  error={error}
                  message={error.PQR_PWHeatTre_Temperature.message}
                                 ></Single_Input>
              </Col>

              <Col className="mb-1" md={3}>
                <Single_Input
                  name="PQR_PWHeatTre_Time"
                  type="text"
                  maxLength={20}
                  status={error.PQR_PWHeatTre_Time.status}
                  value={headerObj?.PQR_PWHeatTre_Time}
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  onChange={handleChange}
                  label="Time (Minutes)"
                  message={error.PQR_PWHeatTre_Time.message}
                  setError={setError}
                  error={error}
                ></Single_Input>
              </Col>

              <Col className="mb-1" md={3}>
                <Single_Input
                  name="PQR_PWHeatTre_Type"
                  type="text"
                  maxLength={10}
                  status={error.PQR_PWHeatTre_Type.status}
                  value={headerObj?.PQR_PWHeatTre_Type}
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  onChange={handleChange}
                  label="Type"
                  message={error.PQR_PWHeatTre_Type.message}
                  setError={setError}
                  error={error}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
              <Single_Input
                  name="PQR_PWHeatTre_Heating_Rate"
                  type="text"
                  maxLength={10}
                  status={error.PQR_PWHeatTre_Heating_Rate.status}
                  value={headerObj?.PQR_PWHeatTre_Heating_Rate}
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  onChange={handleChange}
                  label="Type"
                  message={error.PQR_PWHeatTre_Heating_Rate.message}
                  setError={setError}
                  error={error}
                ></Single_Input>
               {/*  <div class="T-input w-100">
                  <input
                    type="text"
                    id="PQR_PWHeatTre_Heating_Rate"
                    name="PQR_PWHeatTre_Heating_Rate"
                    disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                    maxLength={10}
                    placeholder=" "
                    className={
                      error.PQR_PWHeatTre_Heating_Rate.status
                        ? " T-input placeHolderInput inputText text-field-with-error"
                        : "T-input placeHolderInput inputText"
                    }
                    value={headerObj?.PQR_PWHeatTre_Heating_Rate}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        PQR_PWHeatTre_Heating_Rate: error,
                      }));
                    }}
                  />
                  <label for="PQR_PWHeatTre_Heating_Rate">
                    Heating Rate (C/Hr)
                  </label>
                </div>
                <div className="required-text1">
                  {error.PQR_PWHeatTre_Heating_Rate.status && (
                    <Form.Text className="text-danger">
                      {error.PQR_PWHeatTre_Heating_Rate.message}
                    </Form.Text>
                  )}
                </div> */}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="PQR_PWHeatTre_Cooling_Rate"
                  type="text"
                  maxLength={10}
                  disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                  status={error.PQR_PWHeatTre_Cooling_Rate.status}
                  value={headerObj?.PQR_PWHeatTre_Cooling_Rate}
                  onChange={handleChange}
                  label=" Cooling Rate (C/Hr)"
                  message={error.PQR_PWHeatTre_Cooling_Rate.message}
                  setError={setError}
                  error={error}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="mb-1" md={12}>

                 <Single_Input_textaraNotes
                name="PQR_PWHeatTre_Notes"
                type="text"
                style={{ height: "70px", resize: "none" }}
                maxLength={500}
                className="textarea-notes"
                disabled={headerObj?.CheckboxPostWHT === "false" ? true : fieldPWHT }
                status={error.PQR_PWHeatTre_Notes.status}
                value={headerObj.PQR_PWHeatTre_Notes ? headerObj.PQR_PWHeatTre_Notes : valueNotes}
                handleChange={handleChangeNotes}
                label="Notes"
                message={error.PQR_PWHeatTre_Notes.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_PWHeatTre_Notes: error,
                  }));
                }}
              ></Single_Input_textaraNotes>
              </Col>
            </Row>
          </Row>
        </Row>

            
        <Row style={{ padding: "0 60px", position:"relative",bottom:"65px"}}>
          <span className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Certification</span>
          <Col md={6}>
            <Single_Input
              disabled={copydata}
              name="PQR_Certification_Welder_Name"
              type="text"
              maxLength={30}
              status={error.PQR_Certification_Welder_Name.status}
              value={headerObj?.PQR_Certification_Welder_Name}
              onChange={handleChange}
              label="Welder's Name"
              message={error.PQR_Certification_Welder_Name.message}
              setError={setError}
              error={error}
            ></Single_Input>
          </Col>
          <Col md={3}>
            <Single_Input
              disabled={copydata}
              name="PQR_Certification_ID"
              type="text"
              maxLength={10}
              status={error.PQR_Certification_ID.status}
              value={headerObj?.PQR_Certification_ID}
              onChange={handleChange}
              label="ID"
              message={error.PQR_Certification_ID.message}
              setError={setError}
              error={error}
            ></Single_Input>
          </Col>
          <Col md={3}>
            <Single_Input
              disabled={copydata}
              name="PQR_Certification_Process"
              type="text"
              maxLength={10}
              status={error.PQR_Certification_Process.status}
              value={headerObj?.PQR_Certification_Process}
              onChange={handleChange}
              label="Process"
              message={error.PQR_Certification_Process.message}
              setError={setError}
              error={error}
            ></Single_Input>
          </Col>
          <Col md={3}>
            <Single_Input
              disabled={copydata}
              name="PQR_Certification_ConductedBy"
              type="text"
              maxLength={20}
              status={error.PQR_Certification_ConductedBy.status}
              value={headerObj?.PQR_Certification_ConductedBy}
              onChange={handleChange}
              setError={setError}
                  error={error}
              label="Tests Conducted by"
              message={error.PQR_Certification_ConductedBy.message}
              
            ></Single_Input>
          </Col>
          <Col md={3}>
            <Single_Input
              disabled={copydata}
              name="PQR_Certification_Mechanical_TestsBy"
              type="text"
              maxLength={20}
              status={error.PQR_Certification_Mechanical_TestsBy.status}
              value={headerObj?.PQR_Certification_Mechanical_TestsBy}
              onChange={handleChange}
              label="Mechanical Tests by"
              message={error.PQR_Certification_Mechanical_TestsBy.message}
              setError={setError}
              error={error}
            ></Single_Input>
          </Col>
          <Col md={3}>
            <Single_Input
              disabled={copydata}
              name="PQR_Certification_Lab_RefNo"
              type="text"
              maxLength={20}
              status={error.PQR_Certification_Lab_RefNo.status}
              value={headerObj?.PQR_Certification_Lab_RefNo}
              onChange={handleChange}
              label="Laboratory Ref.Number"
              message={error.PQR_Certification_Lab_RefNo.message}
              setError={setError}
              error={error}
            ></Single_Input>
          </Col>
        </Row>
      </Row>
    );
  }
);

export default PQRTubesheetPage1;
