
import "../Css/groove_pdf.css";


const WI_PDF_TTS_1 = ({ wi, rowdata, wpsde, setpdf }, props, wld) => {


  let header = rowdata[0];
  let detail = rowdata[1];


  let slipDate = header?.WI_SlipDate;
  let date = new Date(slipDate);
  let formattedDate =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

 

const tradeName = detail?.WPSD_FM_ChemicalAnalysis_TradeName || '';
const firstPart = tradeName?.slice(0,18);
const secondPart = tradeName?.slice(17);



const AllNotes = header?.WI_NOTES.split('\n');

const Notes = AllNotes.slice(0, 6)


const WeldDepThick = header?.WI_WeldDepThick.split("|")
let WI_WeldDepThick 

if(WeldDepThick[0] === "9999.00")
{
    WI_WeldDepThick = "NO Limit"
}
else{
    WI_WeldDepThick =  WeldDepThick[0]
}


  return (
    <div className="MainPDF">
      <header>
        <table class="tablenoborder">
          <div style={{ display: "flex" }}>
            <tr>
              <td class="tablenoborder godrejTitle" align="left" valign="top" style={{fontWeight:"200"}}>
                Godrej & Boyce Mfg. Co. Ltd
              </td>
            </tr>
            <tr>
              <td
                class="tablenoborder godrejAddress"
                align="right"
                valign="top"
                style={{ position: "relative", left: "390px" }}
              >
                {header?.WI_print >= 1
                  ? `Reprinted On: ${new Date().toLocaleDateString("en-GB")}`
                  : ""}{" "}
              </td>
            </tr>
          </div>

          <tr>
            <td
              class="tablenoborder godrejAddress "
              align="right"
              style={{ position: "relative", right: "50px" }}
            >
               Creation Date : {formattedDate}
            </td>
          </tr>

          <tr>
          
            <td
              class="tablenoborder pageTitle"
              align="center"
              valign="top"
              style={{ verticalAlign: "top", padding: 0, height: ".1%" }}
            >
              Welder Consumable Requisition /Instruction Slip
            </td>

          </tr>
          <div className="borderline"></div>

        </table>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersleft" align="left">
              Welder:
            </div>
            <div
              className="Reportdataleft"
              align="left"
              style={{ width: "250px" }}
            >
              {header?.WI_Welder_Id}/{header?.WI_Welder_Name}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="Reportslipheadright" align="right">
              Slip No:
            </div>
            <div className="Reportslipnodata" align="right">
              PED/{header?.WI_plant}/{header?.SK}
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersleft" align="left">
              Project:
            </div>
            <div
              className="Reportdataleft"
              align="left"
              style={{ width: "120px" }}
            >
              {header?.WI_Project_No}
            </div>
          </div>
          
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersright" align="right">
              Seam No:
            </div>
            <div className="Reportdataright" align="right">
              {header?.WI_Joint_No}
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersleft" align="left">
              WPS No:
            </div>
            <div
              className="Reportdataleft"
              align="left"
              style={{ width: "120px" }}
            >
              {detail?.WPSH_WPS_No}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersmiddle" align="left">
              {" "}
              Rev No:
            </div>
            <div
              className="Reportdatamiddle"
              align="left"
              style={{ width: "65px" }}
            >
              {" "}
              {header?.WPS_Rev_no}{" "}
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="ReportHeadersright1" align="right">
              Welding Position:
            </div>
            <div className="Reportdataright1" align="right">
              {header?.WI_Welding_Position}
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
            
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersright2tts" align="right">
            Joint Design:
            </div>
            <div className="Reportdataright2tts" align="right">
            As per drawing
            </div>
          </div>
         
         
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersrightProcesstts" align="right">
              Process:
            </div>
            <div className="ReportdatarightProcesstts" align="right">
              {detail?.WPSD_Process}
            </div>
          </div>
        </div>

      

        <div>
          <br></br>
          <div>
            <div className="ReportHeadersleft" align="left">
              Instructions:
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersleft" align="left">
              (1) Min. Preheat Temp.:
            </div>
            <div
              className="Reportdataleft"
              align="left"
              style={{ width: "100px" }}
            >
              {detail.WPSD_WP_MinimumPreheatTemp} °C
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="ReportHeadersright3tts" align="right">
              (3) Post Heat Temp.:
            </div>
            <div className="Reportdataright3tts" align="right">
            {header.WI_Pre_Heat_Temp}<b>°C for</b> {header.WI_Pre_Heat_Time} <b>min</b>
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersleft" align="left">
              (2) Max. Interpass Temp.:
            </div>
            <div
              className="Reportdataleft"
              align="left"
              style={{ width: "100px" }}
            >
              {detail.WPSD_WP_MaximumInterpassTemp} °C
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="ReportHeadersright4tts" align="right">
              (4) Wrap in Insulation Material :{" "}
            </div>
            <div className="Reportdataright4tts" align="right">
              {header?.WI_Wrap_Insulation}
            </div>
          </div>
        </div>

      

        <br></br>
        <table className="Table50">
          <tbody>
            <tr>
              <th
                className="wider-cell1"
                style={{
                
                  border: "none",
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  width: "330px",
                }}
              >
            
                  <span className="tabledata" style={{width:"70px"}}>Filler Metal: AWS:</span>
                <td className="tableheadingd" style={{borderLeft:"0px",fontWeight:"100"}}>{header.WI_FM_AWS}</td>

              </th>
              <th style={{ textAlign: "center", borderRight:"0"}}>
                <span className="tableheading">
                  Diameter <br></br>(mm){" "}
                </span>
              </th>
              <th style={{ textAlign: "center",width:"190px",borderRight:"0"}}>
                <span
                  className="tableheading"
                  style={{ paddingBottom: "10px" }}
                >
                  Batch No
                </span>
              </th>
              <th style={{ textAlign: "center", borderRight:"0"}}>
                <span className="tableheading">Layer</span>
              </th>
              <th style={{ textAlign: "center" ,borderRight:"0"}}>
                <span className="tableheading">Current Polarity</span>
              </th>
              <th style={{ textAlign: "center",borderRight:"0" }}>
                <span className="tableheading">Current (Ampere)</span>
              </th>
              <th style={{ textAlign: "center" ,borderRight:"0"}}>
                <span className="tableheading">Voltage (Volts)</span>
              </th>
              <th style={{ textAlign: "center",borderRight:"0" }}>
                <span className="tableheading">
                  Speed <br></br>(mm/min)
                </span>
              </th>
              <th style={{ textAlign: "center", width: "30px" }}>
                <span className="tableheading">Qty Iss.</span>
              </th>
            </tr>

            <tr>
            
              <td className="tabledata" style={{width:"70px", borderLeft:"1px solid black"}} >
              Brand:
              </td>
              <td
                className="tableheadingd"
                style={{ borderLeft: "1px solid black" }}
              >
                {header?.WI_FillerMetalSize[0]}
              </td>
              <td className="tableheadingd">{header?.WI_WP_BatchNumber[0]} </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_LayerNumber[0]
                  ? detail?.WPSD_WP_LayerNumber[0]
                  : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_CurrentPolarity[0]
                  ? detail?.WPSD_WP_CurrentPolarity[0]
                  : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_Amperes[0] ? detail?.WPSD_WP_Amperes[0] : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_Volts[0] ? detail?.WPSD_WP_Volts[0] : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_TravelSpeed[0]
                  ? detail?.WPSD_WP_TravelSpeed[0]
                  : ""}
              </td>
              <td className="tableheadingd"></td>
            </tr>
            <tr>
              <td className="tableheadingd"> {firstPart}</td>
              <td
                className="tableheadingd"
                style={{ borderLeft: "1px solid black" }}
              >
                {header?.WI_FillerMetalSize[1]}
              </td>
              <td className="tableheadingd">{header?.WI_WP_BatchNumber[1]}</td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_LayerNumber[1]
                  ? detail?.WPSD_WP_LayerNumber[1]
                  : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_CurrentPolarity[1]
                  ? detail?.WPSD_WP_CurrentPolarity[1]
                  : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_Amperes[1] ? detail?.WPSD_WP_Amperes[1] : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_Volts[1] ? detail?.WPSD_WP_Volts[1] : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_TravelSpeed[1]
                  ? detail?.WPSD_WP_TravelSpeed[1]
                  : ""}
              </td>
              <td className="tableheadingd"></td>
            </tr>
            <tr>
              <td
                className="tableheadingd">
                    {secondPart ? secondPart : ""} 
              
              </td>
              <td
                className="tableheadingd"
                style={{ borderLeft: "1px solid black" }}
              >
                {header?.WI_FillerMetalSize[2]}
              </td>
              <td className="tableheadingd">{header?.WI_WP_BatchNumber[2]}</td>
              <td className="tableheadingd">
                {detail?.wld_WPSType === "Groove"
                  ? detail?.WPSD_WP_LayerNumber[2]
                  : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_CurrentPolarity[2]
                  ? detail?.WPSD_WP_CurrentPolarity[2]
                  : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_Amperes[2] ? detail?.WPSD_WP_Amperes[2] : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_Volts[2] ? detail?.WPSD_WP_Volts[2] : ""}
              </td>
              <td className="tableheadingd">
                {detail?.WPSD_WP_TravelSpeed[2]
                  ? detail?.WPSD_WP_TravelSpeed[2]
                  : ""}
              </td>
              <td className="tableheadingd"></td>
            </tr>
          </tbody>
        </table>

        <table className="Table50">
          <tbody>
            <tr>
              <th className="wider-cell" style={{ borderTop: "0px" }}>
                <span
                  className="tabledata"
                  style={{
                    borderRight: "0px",
                    borderLeft: "0px",
                    borderTop: "0px",
                  }}
                >
                  Gas
                </span>
              </th>
              <th
                className="wider-cel"
                style={{
                  width: "90px",
                  borderTop: "0px",
                  borderLeft: "0px",
                  borderRight: "1px solid black",
                }}
              >
                <span className="tabledata">Type / Composition</span>
              </th>
              <th
                className="wider-ce"
                style={{ width: "90px", borderTop: "0px",borderLeft: "0px",
            }}
              >
                <span className="tabledata">
                  Flow Rate <br></br>(Litres / min.)
                </span>
              </th>
              <th
                className="wider-ce"
                style={{
                  border: "none",
                  borderRight: "1px solid black",
                  borderTop: "0px",
                  width: "160px",
                }}
              >
                <span className="tableheading2">Flux : {header?.WI_Flux}</span>
              </th>
            </tr>

            <tr>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                Shielding
              </td>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                {detail?.WPSD_WP_ShieldingGasType}
              </td>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                {detail?.WPSD_WP_ShieldingFlowRate}
              </td>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                Brand: {header?.WI_Flux_Brand}
              </td>
            </tr>
            <tr>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                Backing
              </td>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                {detail?.WPSD_WP_BackingGasType}
              </td>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                {detail?.WPSD_WP_BackingFlowRate}
              </td>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                Flux Item: {header?.WI_Flux_ItemCode}
              </td>
            </tr>
            <tr>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                Trailing
              </td>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                {detail?.WPSD_WP_TrailingFlowRate}
              </td>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                {detail?.WPSD_WP_TrailingGasType}
              </td>
              <td
                className="tableheadingL"
                style={{ borderRight: "1px solid black" }}
              >
                Flux Batch: {header?.WI_Flux_Batch}
                
              </td>
            </tr>
          </tbody>
        </table>
<br></br>
        <div>
          <div >
            <div className="ReportHeadersleft" align="left">
              Notes:
            </div>
            <div className="ReportHeadersleft" align="left">
              DC Pulsing Current: {detail.WPSD_WP_FillerMetalSIze[0]} : {detail.WPSD_WP_Pulsing_current[0]} , {detail.WPSD_WP_FillerMetalSIze[1]} : {detail.WPSD_WP_Pulsing_current[1]}
            </div>
            <div className="ReportHeadersleft" align="left">
              DC Pulsing Current - Others: {detail.WPSD_WP_FillerMetalSIze[0]} : {detail.WPSD_WP_Pulsing_current_others[0]} , {detail.WPSD_WP_FillerMetalSIze[1]} : {detail.WPSD_WP_Pulsing_current_others[1]}

            </div>
            <div
              className="Reportdataleftnotes"
              align="left"
              style={{width: "800px",height:"125px"}}
            >

      {Notes.map((point, index) => (
        <div className="notefonts" key={index}>{point}</div>
      ))}

            
            </div>
          </div>
        </div>

      
        <br></br>

        <div style={{ display: "flex", position: "relative", top:"40px" }}>
          <div style={{ display: "flex" }}>
            <div className="ReportHeadersleft" align="left">
              (Shopfloor Supervisor )
            </div>
          </div>
        </div>

        <table
          class="tablenoborder"
          style={{ position: "relative", top:"70px"}}
        >
          <tr>
     
            <td
              class="tablenoborder pageTitle"
              align="center"
              valign="top"
              style={{verticalAlign: "top", padding: 0, height: ".1%" ,  borderBottom:"1px solid black",  borderTop:"1px solid black"}}
            >
              Welder Consumable Requisition /Instruction Slip
            </td>
          </tr>
        </table>

        


        <div style={{ position: "relative", top: "70px" }}>
        <div style={{ display: "flex" }}>
              <div className="Reportslipheadrightdate" align="right">
              Creation Date {formattedDate}

              </div>
            
            </div>
  
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <div className="ReportHeadersleft" align="left">
                Welder:
              </div>
              <div
                className="Reportdataleft"
                align="left"
                style={{ width: "250px" }}
              >
                {header?.WI_Welder_Id}/{header?.WI_Welder_Name}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="Reportslipheadright" align="right">
                Slip No:
              </div>
              <div className="Reportslipnodata" align="right">
                {header?.WI_Slip}
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <div className="ReportHeadersleft" align="left">
                Project:
              </div>
              <div
                className="Reportdataleft"
                align="left"
                style={{ width: "120px" }}
              >
                {header?.WI_Project_No}
              </div>
            </div>
         
            <div style={{ display: "flex" }}>
              <div className="ReportHeadersright" align="right">
                Seam No:
              </div>
              <div className="Reportdataright" align="right">
                {header?.WI_Joint_No}
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <div className="ReportHeadersleft" align="left">
                WPS No:
              </div>
              <div
                className="Reportdataleft"
                align="left"
                style={{ width: "120px" }}
              >
                {detail?.WPSH_WPS_No}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className="ReportHeadersright1pg2" align="right">
                Rev No:
              </div>
              <div className="Reportdataright1pg2" align="right">
              {header?.WPS_Rev_no}{" "}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className="ReportHeadersright2pg2" align="right">
                Process:
              </div>
              <div className="Reportdataright2pg2" align="right">
                {detail?.WPSD_Process}
              </div>
            </div>
          </div>


        
<br></br>
          <table className="Table50">
            <tbody>
              <tr>
                <th className="wider-cell3" style={{width:"200px"}}>
                  <span className="tabledata"></span>
                </th>
                <th className="wider-cell" style={{borderRight:"1px solid black",borderLeft:"0"}} >
                  <span className="tableheading " style={{borderRight:"0"}}>Diameter (mm)</span>
                </th>
                <th className="wider-cell" style={{borderLeft:"0"}}>
                  <span className="tableheading" style={{borderLeft:"0"}}>Batch No</span>
                </th>
                <th className="wider-cell2" style={{borderLeft:"0"}}>
                  <span className="tableheading">Item Code</span>
                </th>
                <th className="wider-cell" style={{borderLeft:"0"}}>
                  <span className="tableheading">Quantity Issued</span>
                </th>
              </tr>

              <tr>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                  Filler Metal (AWS): {header.WI_FM_AWS}
                </td>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                {header?.WI_FillerMetalSize[0]}
                </td>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
{header?.WI_WP_BatchNumber[0]}                </td>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                  {header?.WI_WeldConsumable[0] ? header?.WI_WeldConsumable[0] : ""}
                </td>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                </td>
              </tr>
              <tr>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                  Brand: {header.WI_FM_Brand}
                </td>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                {header?.WI_FillerMetalSize[1]}
                </td>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                  {header?.WI_WP_BatchNumber[1]}  
                </td>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                  {header?.WI_WeldConsumable[1] ? header?.WI_WeldConsumable[1] : ""}
                </td>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                </td>
              </tr>
              <tr>
                <td
                  className="tabledatad"
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                ></td>
                <td
                  className="tabledatad"
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                {header?.WI_FillerMetalSize[2]}
                </td>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black",                    borderBottom: "1px solid black",
                }}
                >
                  {header?.WI_WP_BatchNumber[2]}  
                </td>
                <td
                  className="tabledatad"
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {header?.WI_WeldConsumable[2] ? header?.WI_WeldConsumable[2] : ""}
                </td>
                <td
                  className="tabledatad"
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                </td>
              </tr>
              <tr>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                  Flux: {header.WI_Flux}{" "}
                </td>
                <td
                  className="tabledatad"
                  colSpan={2}

                  style={{
                    borderRight: "1px solid black",
                    borderRight: "none",
                  }}
                >
                  Item Code: 
                 
                </td>
                <td
                  className="tabledatad"
                  style={{
                    position:"relative",right:"50px",
                    borderRight: "1px solid black",
                    borderRight: "none",



                  }}
                >
                    {header?.WI_Flux_ItemCode}
                </td>
                <td
                  className="tabledatad"
                  style={{
                    borderRight: "1px solid black",
                    borderRight: "none",

                  }}
                ></td>
              </tr>
              <tr>
                <td
                  className="tabledatad"
                  style={{ borderRight: "1px solid black" }}
                >
                  Brand: {header?.WI_Flux_Brand}
                </td>
                <td
                  className="tabledatad"
                  colSpan={2}
                  style={{
                    borderRight: "1px solid black",
                    borderRight: "none",
                  }}
                >
                  Quantity Issued:
                </td>
                <td
                  className="tabledatad"
                  style={{
                    borderRight: "1px solid black",
                    borderRight: "none",
                  }}
                ></td>
                <td
                  className="tabledatad"
                  style={{
                    borderRight: "1px solid black",
                    borderRight: "none",

                  }}
                ></td>
              </tr>
            </tbody>
          </table>

          <div className="ReportHeadersleft" align="left" style={{position:"relative", top:"80px"}} >
            (Stores Incharge)
          </div>
        </div>
      </header>
    </div>
  );
};
export default WI_PDF_TTS_1;

