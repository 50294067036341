import { Row, Col, Form } from "react-bootstrap";
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import {
  AddNewCount,
  AddNewGroove,
} from "../../Services/wps_groove-services";
import { useLocation } from "react-router-dom";
import {
  isAlphanumericAllSpecialCharacters,
} from "../../functions/validations";
import axios from "axios";
import { getPresignedURL } from "../../Services/Welder-services";

import defaultimg from "../../icons/noimage.png";
import { AddLogs } from "../../Services/wps-logs-services";
import {
  General_Single_Input_select,
  Single_Input,
  Single_Input_textaraNotes,
} from "../utility/CommonComponents/Inputs_Transaction"
import {
  IsUserMasterBand,
  getAllUserMaster,
} from "../../Services/user-service";
import {
  MessageTypes,
  wire_brush,
  WPSH_PWHeatTre_Type,
  WPSH_Tech_BackGougingMethod,
  WPSPage1,
  WPSPage1_Overlay,
  WPSPage3,
  WPS_Groove_Detail,
  WPS_Overlay_Detail,
  yesno,
  wps_field_Val,
  wps_field_Val_Overlay,
  WPSPage2,
} from "./WPSObjects/WPSGroove_entity";
import { Action, Status } from "./WPSObjects/WPSGroove_entity";
import {
  WPS_Image_Groove,
} from "./WPSObjects/WPS_Image";
import { DisableFields, emptyError } from "./WPSObjects/WPS_common";
import { WPSHeader } from "./WPSObjects/WPSHeader";
import {  SearchSelectprename } from "../utility/search-select";
import {
  Date_InputUser,
} from "../utility/CommonComponents/Date_Component";
import { todayDate } from "../utility/CommonComponents/TodayDate";
import { DotLoader } from "react-spinners";

const WeldingProcedureSpec = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      copydata,
      logObj,
      setKey,
      tabno,
      setlogObj,
      setIsEditedPage3,
      setBtnDisabled
    },
    ref
  ) => {
    const location = useLocation();

   
    let Band = sessionStorage.getItem("Band");


    let folderwld = "wld_signatures";
    let folderwps = "WPS_Groove";

    let formattedDate;

    const avatar = { defaultimg };
    const [action] = useState(Action.ActionEdit);

    const FilemaxSize = 1 * 1024 * 1024;

    const [WPStype] = useState(location.state?.WPStype);

    const [users, setUsers] = useState(null);



    const [IsEdited, setIsEdited] = useState(false);



    const [ImageView1, setImageView1] = useState(false);
    const [ImageView2, setImageView2] = useState(false);
    const [ImageView3, setImageView3] = useState(false);

    const [profile, setProfile] = useState("");
    const [profile1, setProfile1] = useState("");
    const [profile2, setProfile2] = useState("");

 

    const [previewUrl1, setPreviewUrl1] = useState();
    const [previewUrl2, setPreviewUrl2] = useState();
    const [previewUrl3, setPreviewUrl3] = useState();
    const [loading, setLoading] = useState(false);


    const [previewuser, setPreviewuser] = useState( avatar.defaultimg
    );
    const [previewapproved, setPreviewapproved] = useState( avatar.defaultimg
    );
    


    const [today, setToday] = useState("");


  

    const [Readonly, setReadonly] = useState("")


    useEffect(() => {
    

     let isDisabled =  DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      setReadonly(
        isDisabled
      );
      

    }, [headerObj.WPSH_Status,headerObj.WPSH_Prepared_By,action]);
   


    const fetchUserMaster = async () => {
      const result = await getAllUserMaster();
   
      const active = result.filter((item) => item.um_status === "Active");
      setUsers(active);
    };

    const fetchUserId = async () => {
      try {
        // Ensure users and headerObj are defined and have the necessary properties
        if (!headerObj || !headerObj.WPSH_ApprovedBy_Name) {
        
          return;
        }

        // Filter users to find the one matching the approved name
        const Approveduserid = users?.filter(
          (item) => item?.um_name === headerObj?.WPSH_ApprovedBy_Name
        );


        // Check if the user was found
        if (Approveduserid.length === 0) {
          console.error("No user found with the approved name");
          return;
        }

        if (Approveduserid && Approveduserid.length > 0) {
         
        } else {
          console.error("No data returned from getbyuserid");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    useEffect(() => {
      fetchUserMaster();
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );
    }, []);

    useEffect(() => {
      fetchUserId();
    }, [users, headerObj?.WPSH_ApprovedBy_Name]);

    const handleChange = (e) => {
      console.log("change1",IsEdited);
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage3(true);
      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const [valueNotes, setValueNotes] = useState("");
    const maxNewLines = 13; // Maximum allowed new lines

    const handleChangeNotes = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage3(true);
    
      const { name, value } = e.target;
      
      // Split the input text into lines based on existing newlines
      const lines = value.split('\n');
      
      // Limit each line to 100 characters
      const formattedLines = lines.map(line => {
        if (line.length > 100) {
          // Break the line into chunks of 100 characters
          return line.match(/.{1,100}/g).join('\n');
        }
        return line;
      });
    
      // Join the lines back together
      let formattedValue = formattedLines.join('\n'); // Use 'let' here instead of 'const'
      
      // Count the number of new lines
      const newLinesCount = (formattedValue.match(/\n/g) || []).length;
      
      if (newLinesCount <= maxNewLines) {
        setValueNotes(formattedValue);
    
        // Update the header object state with the new value
        setheaderObj((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      } else {
        e.preventDefault(); // Prevent further input if the new line limit is reached
      }
    };



    const handleChangeRadio = async (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      if (!(await validate(detaildata)) && !(await validate2(detaildata))) {
        const { name, value } = e.target;

        setheaderObj((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        alert(
          "Cannot Approve or Reject the Record, Please Fill Required Fields of all 3 Pages."
        );
      }
    };

    const handleSelectChange = (e) => {

      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage3(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };


    const [error, setError] = useState({
      wps_No: {},
      wps_QualifiedTo: {},
      wps_SupportingPQR: {},
      wps_ReferenceDoc: {},
      wps_Scope: {},
      wps_JointDesc: {},
      wps_Type: {},
      wps_WeldedTo: {},
      wps_GNoType: {},
      wps_GNoWeldedTo: {},
      wps_GNoBacking: {},
      wps_PNoBacking: {},
      wps_Retainer: {},
      wps_Notes: {},
      WPSH_PHT_ApplicationStd: {},
      WPSH_PHT_Desc: {},
      WPSH_PWHeatTre_Temperature: {},
      WPSH_PWHeatTre_Time: {},
      WPSH_PWHeatTre_Heating_Rate: {},
      WPSH_PWHeatTre_Heating_Method: {},
      WPSH_PWHeatTre_Cooling_Rate: {},
      WPSH_PWHeatTre_Cooling_Method: {},
      // WPSH_PWHeatTre_Type: {},
      WPSH_PWHeatTre_Notes: {},
      WPSH_PWHeatTre_Weld_Notes: {},
      WPSH_PWHeatTre_Post_Heat_temp: {},
      WPSH_PWHeatTre_Post_Heat_time: {},
      WPSH_PWHeatTre_Wrap_insulation_material: {},
      WPSH_PHT_Root_spacing: {},
      WPSH_PWHeatTre_ISR_Temp: {},
      WPSH_PWHeatTre_ISR_Time: {},
      WPSH_Tech_Change_Electrode_dia: {},

      WPSH_Tech_BackGougingMethod: {},
      WPSH_Tech_ClosedtooutChamber: {},
      WPSH_Tech_GroovePreparation: {},
      WPSH_Tech_Inital_InterpassCleaning: {},
      WPSH_Tech_Peening: {},
      WPSH_Tech_SupplementaryMFControl: {},
      WPSH_Tech_SurfacePreparation: {},
      WPSH_Tech_UseOfThermalProcess: {},
      WPSH_Tech_Weld_deposit_chemistry: {},
      WPSH_Tech_Oscillation: {},
      WPSH_Notes: {},
      WPSH_PreparedBy_Date: {},
      WPSH_PreparedBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_ApprovedBy_Name: {},
      WPSH_PreparedBy_Remark: {},
      WPSH_ApprovedBy_Remark: {},
      WPSH_Tech_Oscillation_Width: {},
      WPSH_Tech_Oscillation_Freq: {},
      WPSH_Tech_Filler_Metal_Del: {},
    });

    const validate = async () => {
      // let entries = Object.entries(restObj,detaildata);
      let entries1;
      let entries3;

      entries3 = Object.entries(WPSPage3);

      if (WPStype === "Groove") {
        entries1 = Object.entries(WPSPage1);
      } else if (WPStype === "Overlay") {
        entries1 = Object.entries(WPSPage1_Overlay);
      }

      let isError = false;

      entries1.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      entries3.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });


      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };

    const validate2 = async (detaildata) => {

    
      let errortype = WPStype === "Groove" ? WPS_Groove_Detail : WPS_Overlay_Detail;
      let errortypeVal = WPStype === "Groove" ? wps_field_Val : wps_field_Val_Overlay;
    
 
    
      let entries = Object.entries(detaildata);
      let entries3 = Object.entries(WPSPage2);
      let localError = { ...error }; // Use a local variable to track errors
      let regExp = /^[A-Za-z0-9\s,:.@#$()*!/_]+$/;
      let isError = false;
    
      errortype.forEach((cols) => {
        entries.forEach(([key, val]) => {

    
          let value = "";
          let msg = "";

    
          if (cols.Type === "S") {

            value = detailObj[key][cols.Column];
            msg = `1) At least one field of each process is Mandatory.\n2) No Special Characters other than space, comma, +, -, colon, semicolon, dot, @, #, $, &, (), *, !, _, / are allowed.`;
    
            if (!value.match(regExp)) {

              isError = true;
              localError[cols.Column] = {
                status: true,
                message: msg,
              };
            }
    
          } else if (cols.Type === "A") {
          
            const values = [
              detailObj[key]?.[cols.Column]?.[0],
              detailObj[key]?.[cols.Column]?.[1],
              detailObj[key]?.[cols.Column]?.[2],
            ];
    
            // Check if at least one value is non-empty
            const hasValue = values.some(val => val !== undefined && val !== "" && val !== null);
            const msg = `1) At least one field of each process is Mandatory.\n2) No Special Characters other than space, comma, +, -, colon, semicolon, dot, @, #, $, &, (), *, !, _, / are allowed.`;
    
            if (!hasValue) {
           
              isError = true;
              localError[cols.Column] = {
                status: true,
                message: msg,
              };
            } else {
              // Check each value for special characters
              let errorFound = values.some(val => val !== undefined && val !== "" && val !== null && !val.match(regExp));
              if (errorFound) {
                isError = true;
                localError[cols.Column] = {
                  status: true,
                  message: msg,
                };
              } else {
             
                localError[cols.Column] = {
                  status: false,
                  message: "",
                };
              }
            }
          }
        
        });
      });
    
     
    
      entries3.forEach(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          localError[key] = {
            status: true,
            message: "This field is required",
          };
        }
      });
    
      errortypeVal.forEach((cols) => {
        entries.forEach(([key, val]) => {
       
          if (cols.Type === "A") {
           
      
            // Extract values at indices 0, 1, 2
            const values = [
              detailObj[key]?.[cols.Column]?.[0],
              detailObj[key]?.[cols.Column]?.[1],
              detailObj[key]?.[cols.Column]?.[2],
            ];
      
            // Initialize error status for this column
            let columnHasError = false;
            const msg = `1) At least one field of each process is Mandatory.\n2) No Special Characters other than space, comma, +, -, colon, semicolon, dot, @, #, $, &, (), *, !, _, / are allowed.`;
      
            // Check if at least one value is non-empty
            const hasValue = values.some(val => val !== undefined && val !== "" && val !== null);
      
            if (!hasValue) {
 
              columnHasError = true;
            } else {
              // Check each value for special characters
              for (let i = 0; i < values.length; i++) {
                const val = values[i];
                if (val !== undefined && val !== "" && val !== null && !val.match(regExp)) {
                 
                  columnHasError = true;
                  break; // Break out of the loop as soon as an error is found
                }
              }
            }
      
            // Update the error status for this column
            if (columnHasError) {
             
              isError = true;
              localError[cols.Column] = {
                status: true,
                message: msg,
              };
            } else {
             
              // Only update to false if there wasn't an error previously
              if (!localError[cols.Column]?.status) {
                localError[cols.Column] = {
                  status: false,
                  message: "",
                };
              }
            }
          }
        });
      });
      
      // Update the state only once at the end
      setError(localError);
      
      // Check if any error status is true in the localError object
      const hasError = Object.values(localError).some((err) => err.status === true);
      if (!hasError) {
        isError = false;
      }
      

      return isError;
    };


    const handleCount = () => {
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj.WPSH_Status === Status.StatusApproved) {
        

          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          result = AddNewCount(headerObj);
        }
      }
    };

    const validateTemp = async () => {
      
      let isError = false;

       let temperror = emptyError(error, setError, isError);

      
      return {isError ,temperror};
    };

    const handleAdd = async (approvalstatus) => {
      
      let result = true;
      let previousStatus = headerObj.WPSH_Status;
     
      if (headerObj?.WPSH_Status === Status.StatusApproved) {
       
        handleCount();
      }
      
      if (
        headerObj.WPSH_Approval_Status !== Status.StatusTempSaved &&
        headerObj.WPSH_Approval_Status !== undefined
      )
        headerObj.WPSH_Status = headerObj.WPSH_Approval_Status;
      else headerObj.WPSH_Status = approvalstatus;
     
      if (Band === "P" || Band === "T") {
        headerObj.wps_signatureApproved = headerObj.wps_signatureApproved;
        headerObj.WPSH_ApprovedBy_Name = headerObj.WPSH_ApprovedBy_Name;
        
        headerObj.WPSH_ApprovedBy = headerObj.WPSH_ApprovedBy_Name;
      }
      if (Band !== "T" || Band !== "P") {
        headerObj.WPSH_PreparedBy_Name = headerObj.WPSH_PreparedBy_Name;
      
        headerObj.wps_signaturePrepared = headerObj.wps_signaturePrepared;
      }
    
      let res
      try{
       res = await AddNewGroove(headerObj).then(async (r) => {
          try {
            
  
            if (profile !== "") {
              if (headerObj?.wld_pdf_image[0]?.Image1[0] !== "") {
              
                const result = await getPresignedURL(
                  folderwps,
                  headerObj.wld_pdf_image[0].Image1[0]
                );
           
                const posturl = result.url;
                setPreviewUrl1(posturl);
             

                if (profile !== "") {
                  await axios.put(posturl, profile);
                }
               
                setImageView1(true);
              }
            }
            if (profile1 !== "") {
              if (headerObj?.wld_pdf_image[1].Image2[0] !== "") {
              
                const result = await getPresignedURL(
                  folderwps,
                  headerObj?.wld_pdf_image[1].Image2[0]
                );
               
                const posturl = result.url;
                setPreviewUrl2(posturl);
             

                
                if (profile1 !== "") {
                  await axios.put(posturl, profile1);
                }

              
                setImageView2(true);
              }
            }

            if (profile2 !== "") {
              if (headerObj?.wld_pdf_image[2].Image3[0] !== "") {
               
                const result = await getPresignedURL(
                  folderwps,
                  headerObj?.wld_pdf_image[2].Image3[0]
                );
             
                const posturl = result.url;
                setPreviewUrl3(posturl);
               
                if (profile2 !== "") {
                  await axios.put(posturl, profile2);
                }
               
                setImageView3(true);
              }
            }
            


          } catch (error) {
            result = false
            if (error.request) {
              console.log("hii", error.request);
            } else {
              console.log("hiiiii", `Error: ${error.message}`);
            }
          }
        
          
       
          if (
            action == Action.ActionEdit &&
            previousStatus == Status.StatusApproved //only if its Approved add logs
          ) {
           
            await AddLogs(logObj);
           
          }
          
          if(r!= undefined && r != undefined && r.data.message == "Validate"){
            
            result = MessageTypes.SpecialCharacters
          }  
        });
        
      }
      catch (error) {
        result = false

        console.error("Error adding header:", error);
      }
   
      ///Any erro from backend go back to previous status
      if(result == false || result == MessageTypes.SpecialCharacters)
        headerObj.WPSH_Status = previousStatus

  
      return Promise.resolve(result);
    };

    const checkIfUsernameExists = async () => {
    
      let res = await IsUserMasterBand();
     
      if (res != undefined) return res.data.message;
      else return false;
    };

    const handleSubmitTemp = async () => {
      let result = false;
      let val = await validateTemp();
   
        if (val.isError === false){
          if ( val.temperror === false) {

  
            setLoading(true)
        
          result = await handleAdd(headerObj?.WPSH_Status);
          setLoading(false)

          setIsEdited(false);
          setIsEditedPage3(false);
        if (result == true) {
          return MessageTypes.Temp;
        }
        else if(result ==  MessageTypes.SpecialCharacters)
          return MessageTypes.SpecialCharacters
        else  
          return MessageTypes.Fail
      }
      return MessageTypes.SpecialCharacters;
    }
    return MessageTypes.Required;
  
    };

    let detaildata = detailObj.filter((item) => item.WPSD_Process !== "");

    const handleSubmit = async () => {
      setIsEdited(false);
      setIsEditedPage3(false);


      let result = false;
      const isUsernamePresent = await checkIfUsernameExists();

      if (isUsernamePresent) {
        headerObj.WPSH_Status = Status.StatusTempSaved;
      }

      //Va;idate All pages
      if (!(await validate(detaildata)) && !(await validate2(detaildata))) {
        setLoading(true)
        result = await handleAdd(Status.StatusPending);
        setLoading(false)

        if (result == true) {

          return MessageTypes.Success;
        } else if(result == MessageTypes.SpecialCharacters )
          return MessageTypes.SpecialCharacters
       else{
          return MessageTypes.Fail
        }
      } else {

        setTimeout(() => {
          if (action === Action.ActionAdd) {
            alert("Please Temp Save Data Before Leaving this Page");
          }
        }, 500);
        return MessageTypes.RequiredAll;
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        let res = false;
        res = await handleSubmit();
        if(res == MessageTypes.Success){
          setBtnDisabled(DisableFields(headerObj, Status, Action, action) ? "disabled" : "")
       
        }
        return res;

      },

      funcOnNextTemp: async () => {
        let res = false;

        res = await handleSubmitTemp();

        return res;
      },
    }));


  


    const handleFile1 = (file, index, e) => {
      const maxSize = FilemaxSize;
      if (file.size > maxSize) {
        alert("File size exceeds the allowed limit. Add File Less Than 1 Mb");
        file = null;
        return;
      }

      let uploadextension = file.name.split(".");


      if (
        uploadextension[1] !== "jpeg" &&
        uploadextension[1] !== "png" &&
        uploadextension[1] !== "jpg"
      ) {
   
        alert("Please Upload Jpeg,png or jpg images");
        file = null;
        return;
      }
      if (index == 0) {
        setProfile(file);
        headerObj.wld_pdf_image[0].Image1[0] = file.name;
        setPreviewUrl1(URL.createObjectURL(file));
      } else if (index == 1) {
        setProfile1(file);
        setPreviewUrl2(URL.createObjectURL(file));
        headerObj.wld_pdf_image[1].Image2[0] = file.name;
      } else if (index == 2) {
        setProfile2(file);
        setPreviewUrl3(URL.createObjectURL(file));
        headerObj.wld_pdf_image[2].Image3[0] = file.name;
      }
    };

 
    const fileInputRef = useRef(null);

    const fetchbyuserprofile = async (userprofile) => {
  
      try {

        if (userprofile !== "") {
          const result = await getPresignedURL(folderwld, userprofile);
       
          const geturl = result.url;
          setPreviewuser(geturl);
          
        }
        else{
          setPreviewuser(avatar.defaultimg);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    const fetchapproveUserProfile = async (userprofile) => {
      try {
  
        if (userprofile !== "") {
          const result = await getPresignedURL(folderwld, userprofile);
          const geturl = result.url;
          setPreviewapproved(geturl);
          
        }
        else{
          setPreviewapproved(avatar.defaultimg);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    useEffect(() => {
      formattedDate = new Date().toLocaleDateString("en-GB");
      setToday(formattedDate);
      console.log("today",today)
      let isDisabled =  DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      setReadonly(
       isDisabled
      );
    
    }, []);

    useEffect(() => {
      if (
        action === Action.ActionEdit &&
        headerObj?.wps_signaturePrepared !== ""
      ) {
    

        fetchbyuserprofile(headerObj?.wps_signaturePrepared);
      }

      if (
        action === Action.ActionEdit &&
        headerObj?.wps_signatureApproved !== ""
      ) {

        fetchapproveUserProfile(headerObj?.wps_signatureApproved);
      }
    }, []);

    const fetchImagestube1 = async (item) => {
      if (item) {
       
        const result = await getPresignedURL(folderwps, item);
        
        const posturl = result.url;
        setPreviewUrl1(posturl);
        
      }
    };

    const fetchImagestube2 = async (item) => {
      if (item) {
        
        const result = await getPresignedURL(folderwps, item);
        
        const posturl = result.url;
        setPreviewUrl2(posturl);
        
      }
    };

    const fetchImagestube3 = async (item) => {
      if (item) {
       
        const result = await getPresignedURL(folderwps, item);
       
        const posturl = result.url;
        setPreviewUrl3(posturl);
      
      }
    };

    useEffect(() => {
      if (
        action === Action.ActionEdit &&
        headerObj?.wld_pdf_image[0]?.Image1[0] !== ""
      ) {
        fetchImagestube1(headerObj?.wld_pdf_image[0]?.Image1[0]);
      }

      if (
        action === Action.ActionEdit &&
        headerObj?.wld_pdf_image[1]?.Image2[0] !== ""
      ) {
        fetchImagestube2(headerObj?.wld_pdf_image[1]?.Image2[0]);
      }
      if (
        action === Action.ActionEdit &&
        headerObj?.wld_pdf_image[2]?.Image3[0] !== ""
      ) {
        fetchImagestube3(headerObj?.wld_pdf_image[2]?.Image3[0]);
      }
    }, []);

    useEffect(() => {
      if (
        ImageView1 === true &&
        headerObj?.wld_pdf_image[0]?.Image1[0] !== ""
      ) {
       
        fetchImagestube1(headerObj?.wld_pdf_image[0]?.Image1[0]);
      }
    }, [ImageView1]);

    useEffect(() => {
      if (
        ImageView2 === true &&
        headerObj?.wld_pdf_image[1]?.Image2[0] !== ""
      ) {
   
        fetchImagestube2(headerObj?.wld_pdf_image[1]?.Image2[0]);
      }
    }, [ImageView2]);

    useEffect(() => {
      if (
        ImageView3 === true &&
        headerObj?.wld_pdf_image[2]?.Image3[0] !== ""
      ) {
       
        fetchImagestube3(headerObj?.wld_pdf_image[2]?.Image3[0]);
      }
    }, [ImageView3]);



    let groclass = WPStype === "Groove" ? "row-margin" : "d-none";
    let overclass = WPStype === "Overlay" ? "row-margin" : "d-none";

   

    const [grooveclass, setgrooveclass] = useState(groclass);

    const [overlayclass, setoverlayclass] = useState(overclass);



    return (
      <Row className="font">
           {loading && (
      <div className="loader-overlay">
        <DotLoader size={80} className="pageloader" />
      </div>
    )}
        <WPSHeader headerObj={headerObj}></WPSHeader>
        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-2" style={{ position: "relative", left: "15px" }}>
            <span
              className="WPS-blue-text"
              style={{ position: "relative", right: "11px" }}
            >
              Joints (QW-402)
            </span>
            <div
              className="d-flex mb-4"
              style={{ position: "relative", right: "11px" }}
            >
              <div>
                <input
                  type="radio"
                  name="wld_Joints_Type"
                  value="Joint section"
                  checked={headerObj.wld_Joints_Type === "Joint section"}
                  onChange={(e) => handleChange(e)}
                />
                <span className="ms-2">Joint section</span>
              </div>
              <div className="ms-5">
                <input
                  type="radio"
                  name="wld_Joints_Type"
                  value="Production drawing"
             
                  checked={headerObj.wld_Joints_Type === "Production drawing"}
                  onChange={(e) => handleChange(e)}
                />
                <span className="ms-2">Production drawing</span>
              </div>
            </div>

            {headerObj.wld_Joints_Type === "Production drawing" ? (
              ""
            ) : (
              <>
                <WPS_Image_Groove
                  handleFile={handleFile1}
                  previewUrl1={previewUrl1}
                  previewUrl2={previewUrl2}
                  previewUrl3={previewUrl3}
                  headerObj={headerObj}
                  setheaderObj={setheaderObj}
           
                  name="fileInput"
                  name1="fileInput1"
                  name2="fileInput2"
                  name3="fileInput3"
                  type="file"
                  accept="image/*"
                  fileInputRef={fileInputRef}
                  readOnly={Readonly}
                  // disabled={isDisabled}
                ></WPS_Image_Groove>
                <Row className={`${grooveclass}` && "mb-1 mt-3"}>
                  <Col className={grooveclass} md={6}>
                    <Single_Input
                      name="WPSH_PHT_Root_spacing"
                      type="text"
                      maxLength={50}
                      status={error.WPSH_PHT_Root_spacing.status}
                      value={headerObj.WPSH_PHT_Root_spacing}
                      onChange={handleChange}
                      label="Root Spacing"
                      message={error.WPSH_PHT_Root_spacing.message}
                      error={error}
                      setError={setError}
                     
                    ></Single_Input>
                  </Col>
                </Row>
              </>
            )}
          </Row>
        </Row>

        <Row style={{padding: "20px 60px" }} className="WPS-Page-row-padding">
          <Row className="mb-2" style={{ position: "relative", left: "15px" }}>
            <span
              className="WPS-blue-text"
              style={{ position: "relative", right: "11px" }}
            >
              Post Weld Heat Treatment (QW-407)
            </span>
            <Row className="mb-1">
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Temperature"
                  type="text"
                  maxLength={5}
                  status={error.WPSH_PWHeatTre_Temperature.status}
                  value={headerObj.WPSH_PWHeatTre_Temperature}
                  onChange={handleChange}
             
                  label="Post Weld H/T Temp(°C)"
                  message={error.WPSH_PWHeatTre_Temperature.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_PWHeatTre_Time"
                    name="WPSH_PWHeatTre_Time"
                    maxLength={5}
                    placeholder=" "
                    className={
                      error.WPSH_PWHeatTre_Time.status
                        ? " T-input placeHolderInput inputText text-field-with-error"
                        : "T-input placeHolderInput inputText"
                    }
       
                    value={headerObj.WPSH_PWHeatTre_Time}
                    onChange={handleChange}
                    error={error}
                    setError={setError}
                  />
                  <label for="WPSH_PWHeatTre_Time">Time (Minutes)</label>
                </div>
                {headerObj.WPSH_PWHeatTre_Temperature !== "" ? (
                  <div className="required-text1">
                    {error.WPSH_PWHeatTre_Time.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PWHeatTre_Time.message}
                      </Form.Text>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col className="mb-1" md={3}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_PWHeatTre_Heating_Rate"
                    name="WPSH_PWHeatTre_Heating_Rate"
                    maxLength={28}
                    placeholder=" "
                    className={
                      error.WPSH_PWHeatTre_Heating_Rate.status
                        ? " T-input placeHolderInput inputText text-field-with-error"
                        : "T-input placeHolderInput inputText"
                    }
                    // value={ProjectObj.wld_ProjectNumber}
                    // onChange={handleChange}
                    value={headerObj.WPSH_PWHeatTre_Heating_Rate}
                    onChange={handleChange}
                    error={error}
                    setError={setError}
                  />
                  <label for="WPSH_PWHeatTre_Heating_Rate">
                    Heating Rate (°C/Hr)
                  </label>
                </div>
                {headerObj.WPSH_PWHeatTre_Temperature !== "" ? (
                  <div className="required-text1">
                    {error.WPSH_PWHeatTre_Heating_Rate.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PWHeatTre_Heating_Rate.message}
                      </Form.Text>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Heating_Method"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_PWHeatTre_Heating_Method.status}
                  value={headerObj.WPSH_PWHeatTre_Heating_Method}
                  onChange={handleChange}
                  // label= "Post Weld Heat/Treatment Temperature (°C)"
                  label="Loading Temperature(°C)"
                  message={error.WPSH_PWHeatTre_Heating_Method.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Cooling_Rate"
                  type="text"
                  maxLength={28}
                  status={error.WPSH_PWHeatTre_Cooling_Rate.status}
                  value={headerObj.WPSH_PWHeatTre_Cooling_Rate}
                  onChange={handleChange}
                  label=" Cooling Rate (°C/Hr)"
                  message={error.WPSH_PWHeatTre_Cooling_Rate.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Cooling_Method"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_PWHeatTre_Cooling_Method.status}
                  value={headerObj.WPSH_PWHeatTre_Cooling_Method}
                  onChange={handleChange}
                  // label= "Post Weld Heat/Treatment Temperature (°C)"
                  label="UnLoading Temperature(°C)"
                  message={error.WPSH_PWHeatTre_Cooling_Method.message}
                  error={error}
                      setError={setError}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <General_Single_Input_select
                  name="WPSH_PWHeatTre_Type"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_PWHeatTre_Type}
                  // status={error.WPSH_PWHeatTre_Type.status}
                  // message={error.WPSH_PWHeatTre_Type.message}
                  handleSelectChange={handleSelectChange}
                  label="Type"
                  array={WPSH_PWHeatTre_Type}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={12}>
                <Single_Input
                  name="WPSH_PWHeatTre_Notes"
                  type="text"
                  maxLength={90}
                  status={error.WPSH_PWHeatTre_Notes.status}
                  value={headerObj.WPSH_PWHeatTre_Notes}
                  onChange={handleChange}
                  label="PWHT Notes"
                  message={error.WPSH_PWHeatTre_Notes.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Post_Heat_temp"
                  type="text"
                  maxLength={10}
                  status={error.WPSH_PWHeatTre_Post_Heat_temp.status}
                  value={headerObj.WPSH_PWHeatTre_Post_Heat_temp}
                  onChange={handleChange}
                  label="Post Heating Temp (°C)"
                  message={error.WPSH_PWHeatTre_Post_Heat_temp.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_Post_Heat_time"
                  type="text"
                  maxLength={4}
                  status={error.WPSH_PWHeatTre_Post_Heat_time.status}
                  value={headerObj.WPSH_PWHeatTre_Post_Heat_time}
                  onChange={handleChange}
                  label="Post Heat Time (Minutes)"
                  message={error.WPSH_PWHeatTre_Post_Heat_time.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <General_Single_Input_select
                  name="WPSH_PWHeatTre_Wrap_insulation_material"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_PWHeatTre_Wrap_insulation_material}
                  status={error.WPSH_PWHeatTre_Wrap_insulation_material.status}
                  message={
                    error.WPSH_PWHeatTre_Wrap_insulation_material.message
                  }
                  handleSelectChange={handleSelectChange}
                  label="Wrap In Insulation Material"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className={grooveclass}>
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_ISR_Temp"
                  type="text"
                  maxLength={15}
                  status={error.WPSH_PWHeatTre_ISR_Temp.status}
                  value={headerObj.WPSH_PWHeatTre_ISR_Temp}
                  onChange={handleChange}
                  label=" ISR Temperature (°C)"
                  message={error.WPSH_PWHeatTre_ISR_Temp.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
              <Col md={3}>
                <Single_Input
                  name="WPSH_PWHeatTre_ISR_Time"
                  type="text"
                  maxLength={48}
                  status={error.WPSH_PWHeatTre_ISR_Time.status}
                  value={headerObj.WPSH_PWHeatTre_ISR_Time}
                  onChange={handleChange}
                  label=" ISR Time (Minutes) "
                  message={error.WPSH_PWHeatTre_ISR_Time.message}
                  error={error}
                      setError={setError}
                ></Single_Input>
              </Col>
              <Col md={12}>
                <Single_Input
                  name="WPSH_PWHeatTre_Weld_Notes"
                  type="text"
                  maxLength={90}
                  status={error.WPSH_PWHeatTre_Weld_Notes.status}
                  value={headerObj.WPSH_PWHeatTre_Weld_Notes}
                  onChange={handleChange}
                  label="ISR Notes"
                  message={error.WPSH_PWHeatTre_Weld_Notes.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
            </Row>
          </Row>
        </Row>

        {/*----------------------------- Field For Groove -------------------------------------------------------------------------------------*/}

        <Row className={grooveclass} style={{ padding: "20px 60px" }}>
          <Row className="mb-2" style={{ position: "relative", left: "15px" }}>
            <span
              className="WPS-blue-text"
              style={{ position: "relative", right: "11px" }}
            >
              Other Variables
            </span>
            <Row className="mb-1">
         
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_BackGougingMethod"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_BackGougingMethod}
                  status={error.WPSH_Tech_BackGougingMethod.status}
                  message={error.WPSH_Tech_BackGougingMethod.message}
                  handleSelectChange={handleSelectChange}
                  label="Back Gouging Method"
                  array={WPSH_Tech_BackGougingMethod}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <Single_Input
                  name="WPSH_Tech_GroovePreparation"
                  type="text"
                  maxLength={80}
                  status={error.WPSH_Tech_GroovePreparation.status}
                  value={headerObj.WPSH_Tech_GroovePreparation}
                  onChange={handleChange}
                  label="Groove Preparation"
                  message={error.WPSH_Tech_GroovePreparation.message}
                  error={error}
                      setError={setError}
                ></Single_Input>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Inital_InterpassCleaning"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Inital_InterpassCleaning}
                  status={error.WPSH_Tech_Inital_InterpassCleaning.status}
                  message={error.WPSH_Tech_Inital_InterpassCleaning.message}
                  handleSelectChange={handleSelectChange}
                  label="Initial/ Interpass Cleaning"
                  array={wire_brush}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Peening"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Peening}
                  status={error.WPSH_Tech_Peening.status}
                  message={error.WPSH_Tech_Peening.message}
                  handleSelectChange={handleSelectChange}
                  label="Peening"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>

              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_SupplementaryMFControl"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_SupplementaryMFControl}
                  status={error.WPSH_Tech_SupplementaryMFControl.status}
                  message={error.WPSH_Tech_SupplementaryMFControl.message}
                  handleSelectChange={handleSelectChange}
                  label="Supplementary MF Control"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_SurfacePreparation"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_SurfacePreparation}
                  status={error.WPSH_Tech_SurfacePreparation.status}
                  message={error.WPSH_Tech_SurfacePreparation.message}
                  handleSelectChange={handleSelectChange}
                  label="Surface Preparation"
                  array={wire_brush}
                ></General_Single_Input_select>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_UseOfThermalProcess"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_UseOfThermalProcess}
                  status={error.WPSH_Tech_UseOfThermalProcess.status}
                  message={error.WPSH_Tech_UseOfThermalProcess.message}
                  handleSelectChange={handleSelectChange}
                  label="Use of Thermal Process"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Oscillation"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Oscillation}
                  status={error.WPSH_Tech_Oscillation.status}
                  message={error.WPSH_Tech_Oscillation.message}
                  handleSelectChange={handleSelectChange}
                  label="Oscillation"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
              <Col md={6}>
                <Single_Input
                  name="WPSH_Tech_Change_Electrode_dia"
                  type="text"
                  maxLength={25}
                  status={error.WPSH_Tech_Change_Electrode_dia.status}
                  value={headerObj.WPSH_Tech_Change_Electrode_dia}
                  onChange={handleChange}
                  label="Change in Electrode Diameter"
                  message={error.WPSH_Tech_Change_Electrode_dia.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_ClosedtooutChamber"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_ClosedtooutChamber}
                  status={error.WPSH_Tech_ClosedtooutChamber.status}
                  message={error.WPSH_Tech_ClosedtooutChamber.message}
                  handleSelectChange={handleSelectChange}
                  label="Closed to out Chamber"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
          </Row>
        </Row>

        {/*----------------------------- Field For Overlay -------------------------------------------------------------------------------------*/}

        <Row className={overlayclass} style={{ padding: "20px 60px" }}>
          <Row className="mb-2" style={{ position: "relative", left: "15px" }}>
            <span className="WPS-blue-text">Other Variables</span>
            <Row className="mb-1">
              <Col md={6}>
                <Single_Input
                  name="WPSH_Tech_Weld_deposit_chemistry"
                  type="text"
                  maxLength={30}
                  status={error.WPSH_Tech_Weld_deposit_chemistry.status}
                  value={headerObj.WPSH_Tech_Weld_deposit_chemistry}
                  onChange={handleChange}
                  label="Weld Deposit Chemistry"
                  message={error.WPSH_Tech_Weld_deposit_chemistry.message}
                  error={error}
                  setError={setError}
                ></Single_Input>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Inital_InterpassCleaning"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Inital_InterpassCleaning}
                  status={error.WPSH_Tech_Inital_InterpassCleaning.status}
                  message={error.WPSH_Tech_Inital_InterpassCleaning.message}
                  handleSelectChange={handleSelectChange}
                  label="Initial/ Interpass Cleaning"
                  array={wire_brush}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_SupplementaryMFControl"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_SupplementaryMFControl}
                  status={error.WPSH_Tech_SupplementaryMFControl.status}
                  message={error.WPSH_Tech_SupplementaryMFControl.message}
                  handleSelectChange={handleSelectChange}
                  label="Supplementary MF Control"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_SurfacePreparation"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_SurfacePreparation}
                  status={error.WPSH_Tech_SurfacePreparation.status}
                  message={error.WPSH_Tech_SurfacePreparation.message}
                  handleSelectChange={handleSelectChange}
                  label="Surface Preparation"
                  array={wire_brush}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Oscillation"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Oscillation}
                  status={error.WPSH_Tech_Oscillation.status}
                  message={error.WPSH_Tech_Oscillation.message}
                  handleSelectChange={handleSelectChange}
                  label="Oscillation"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
              <Col md={6}>
                <General_Single_Input_select
                  name="WPSH_Tech_Peening"
                  // readOnly={isReadonly}
                  value={headerObj.WPSH_Tech_Peening}
                  status={error.WPSH_Tech_Peening.status}
                  message={error.WPSH_Tech_Peening.message}
                  handleSelectChange={handleSelectChange}
                  label="Peening"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_Tech_Oscillation_Width"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_Tech_Oscillation_Width.status}
                  value={headerObj.WPSH_Tech_Oscillation_Width}
                  onChange={handleChange}
                  label="Oscillation Width(mm)"
                  message={error.WPSH_Tech_Oscillation_Width.message}
                  error={error}
                      setError={setError}
                ></Single_Input>
              </Col>
              <Col className="mb-1" md={3}>
                <Single_Input
                  typeclass={overlayclass}
                  name="WPSH_Tech_Oscillation_Freq"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_Tech_Oscillation_Freq.status}
                  value={headerObj.WPSH_Tech_Oscillation_Freq}
                  onChange={handleChange}
                  label="Oscillation Frequency in CPM"
                  message={error.WPSH_Tech_Oscillation_Freq.message}
                 setError={setError}
                 error={error}
                ></Single_Input>
              </Col>

              <Col className="mb-1" md={3}>
                <Single_Input
                  name="WPSH_Tech_Filler_Metal_Del"
                  type="text"
                  maxLength={20}
                  status={error.WPSH_Tech_Filler_Metal_Del.status}
                  value={headerObj.WPSH_Tech_Filler_Metal_Del}
                  onChange={handleChange}
                  label="Filler Metal Delivery(GTAW)"
                  message={error.WPSH_Tech_Filler_Metal_Del.message}
                  error={error}
                      setError={setError}
                ></Single_Input>
              </Col>
            </Row>
          </Row>
        </Row>

        <Row style={{padding: "20px 60px" }} className="WPS-Page-row-padding">
          <Row className="mb-2" style={{ position: "relative", left: "5px" }}>
            <span className="WPS-blue-text">
              Notes (Only first 6 lines in below Notes will appear in WI Slip)
            </span>
            <Col md={12}>
              <Single_Input_textaraNotes
                name="WPSH_Notes"
                type="text"
                style={{ height: "250px", resize: "none" }}
                maxLength={1500}
                className="textarea-notes"
                status={error.WPSH_Notes.status}
                // value={headerObj.WPSH_Notes}
                value={headerObj.WPSH_Notes ? headerObj.WPSH_Notes : valueNotes}
                handleChange={handleChangeNotes}
                // onKeyDown={handleKeyDown}
                label="Notes"
                message={error.WPSH_Notes.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPSH_Notes: error,
                  }));
                }}
              ></Single_Input_textaraNotes>
            </Col>
          </Row>
        </Row>
        {/* -------------------------prepared by --------------------------------*/}

        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-2" style={{ position: "relative", left: "20px" }}>
            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">
                  Prepared By - Welding Engineer
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group className="preby-input-select">
                  <div className="search-select">
                    <SearchSelectprename
                      required
                      data={users}
                      valueField={"um_name"}
                
                      value={headerObj.WPSH_PreparedBy_Name || ""}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        if (action === Action.ActionEdit) setIsEdited(true);

                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: prename.value,
                        }));

                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });
                       
                        fetchbyuserprofile(a[0].wld_profile );
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: a[0].um_name,
                          wps_signaturePrepared:  a[0].wld_profile,
                          WPSH_PreparedBy: a[0].SK,
                        }));
                      }}
                    ></SearchSelectprename>
                  </div>
                  <label>Prepared By</label>
                  <div className="required-text-select">
                    {error.WPSH_PreparedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PreparedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3} style={{ position: "relative" }}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_PreparedBy_Date"
                 
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_PreparedBy_Date.status}
                  message={error.WPSH_PreparedBy_Date.message}
                />
              </Col>

              <Row
                className="mb-4"
                style={{ position: "relative", left: "10px" }}
              >
                <Col>
                  <span>Signature Image</span>
                </Col>
              </Row>

              <Row
                style={{ position: "relative", left: "10px", bottom: "10px" }}
              >
                <Col md={6}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewuser} />
                    </div>
                  </div>
                </Col>
                <Col style={{ position: "relative", left: "28px" }}>
                  <Single_Input
                    name="WPSH_PreparedBy_Remark"
                    type="text"
                    style={{ height: "95px" }}
                    maxLength={50}
                    status={error.WPSH_PreparedBy_Remark.status}
                    value={headerObj.WPSH_PreparedBy_Remark}
                    onChange={handleChange}
                    label="Remark"
                    message={error.WPSH_PreparedBy_Remark.message}
                    error={error}
                      setError={setError}
                  ></Single_Input>
                </Col>
              </Row>
            </Row>
          </Row>
        </Row>
        {/* -------------------------approved by --------------------------------*/}
        <Row
          style={{ padding: "0px 60px", position: "relative", bottom: "10px" }}
        >
          <Row
            className="mb-2"
            style={{ position: "relative", left: "20px", bottom: "10px" }}
          >
            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">
                  Approved By - Welding Engineer
                </span>
              </Col>
              {Band === "P" || Band === "T" ? (
                <Col
                  style={{ position: "relative", top: "36px", left: "22px" }}
                >
                  <span className="WPS-blue-textf">Action</span>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group className="preby-input-select">
                  <div className="search-select">
                    <SearchSelectprename
                      required
                      data={users}
                      valueField={"um_name"}
              
                      value={headerObj.WPSH_ApprovedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        if (action === Action.ActionEdit) setIsEdited(true);

                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: prename.value,
                        }));

                        
                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });
                        fetchapproveUserProfile( a[0].wld_profile );

                      
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: a[0].um_name,
                          wps_signatureApproved: a[0].wld_profile,
                          WPSH_ApprovedBy: a[0].SK,
                        }));

                      }}
                    ></SearchSelectprename>
                  </div>
                  <label>Approved By</label>
                  <div className="required-text-select">
                    {error.WPSH_ApprovedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_ApprovedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3} style={{ position: "relative" }}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_ApprovedBy_Date"
                  todayDate={todayDate}
                 
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_ApprovedBy_Date.status}
                  message={error.WPSH_ApprovedBy_Date.message}
             
                />
              </Col>
              {Band === "P" || Band === "T" ? (
                <Col
                  style={{ position: "relative", top: "25px", left: "20px" }}
                >
                  <div className="d-flex mb-4">
                    <div>
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Approved"
                       
                        checked={
                          headerObj.WPSH_Approval_Status ===
                          Status.StatusApproved
                        }
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <span className="ms-2">Approve</span>
                    </div>
                    <div className="ms-5">
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Rejected"
                 
                        checked={
                          headerObj.WPSH_Approval_Status ===
                          Status.StatusRejected
                        }
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <span className="ms-2">Reject</span>
                    </div>
                  </div>
                </Col>
              ) : (
                ""
              )}

              <Row
                className="mb-4"
                style={{ position: "relative", left: "10px" }}
              >
                <Col>
                  <span>Signature Image</span>
                </Col>
              </Row>

              <Row
                style={{ position: "relative", left: "10px", bottom: "10px" }}
              >
                <Col md={6}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewapproved} />
                    </div>
                  </div>
                </Col>
                {Band === "P" || Band === "T" ? (
                  <Col style={{ position: "relative", left: "28px" }}>
                    <div class="T-input w-100">
                      <textarea
                        style={{ height: "92px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        maxLength={200}
                      
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " T-input placeHolderInput inputText text-field-with-error"
                            : "T-input placeHolderInput inputText"
                        }
                    
                        value={headerObj.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                    {headerObj.WPSH_Approval_Status ===
                    Status.StatusApproved ? (
                      <div className="required-text1">
                        {error.WPSH_ApprovedBy_Remark.status && (
                          <Form.Text className="text-danger">
                            {error.WPSH_ApprovedBy_Remark.message}
                          </Form.Text>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                ) : (
                  <Col>
                    <div class="T-input w-100">
                      <textarea
                        style={{ height: "95px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        readOnly
                        maxLength={200}
                        
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " T-input placeHolderInput inputText text-field-with-error"
                            : "T-input placeHolderInput inputText"
                        }
                     
                        value={headerObj.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                    {headerObj.WPSH_Approval_Status ===
                    Status.StatusApproved ? (
                      <div className="required-text1">
                        {error.WPSH_ApprovedBy_Remark.status && (
                          <Form.Text className="text-danger">
                            {error.WPSH_ApprovedBy_Remark.message}
                          </Form.Text>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                )}
              </Row>
            </Row>
          </Row>
        </Row>
      </Row>
    );
  }
);

export default WeldingProcedureSpec;
