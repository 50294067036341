import { useState, useEffect, useRef } from "react";
import { Col, Form, Modal, Row, Alert } from "react-bootstrap";
import "../../Assests/Css/welder.css";
import {
  RaiseDeviation,
  downloadAttachment,
  getDeviationCategories,
  addCapa,
  sendClosureEmail,
} from "../../Services/deviationcapa-service";

import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";
import Validate, {
  isAlphanumericWithDash,
  isAlphanumericWithDashSlash,
  
  isAlphanumericWithDotSpaceDashCommaBracketsSlashAmpersand,
} from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCurrentDateTime,
  dateCompare,
} from "../../functions/welding-functions";

import Button from "react-bootstrap/Button";
import Delete from "../../icons/DeleteIcon.svg";
import DeleteGrey from "../../icons/Delete_gray.svg";
import axios from "axios";
import {  getAllUsersByRole } from "../../Services/user-service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { getRoleDescByRoleId } from "../../Services/role-service";
import { getAllOnlySlipNos, getWIDataHeader } from "../../Services/WI-services";
import { getPresignedURLDev,getAllWelder } from "../../Services/Welder-services";


const AddRaiseDeviationCapa = (props) => {
  if (props.rowdata.deviationstatus === "Active") {
    props.rowdata.deviationstatus = true;
  } else if (props.rowdata.deviationstatus === "Inactive") {
    props.rowdata.deviationstatus = false;
  }

  let action = props.action;
  let formName = "Deviation";

  let todayDate = getCurrentDateTime("-");
  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [slipNumberResult, setSlipNumberResult] = useState([]);
  const [observerCodeResult, setObserverCodeResult] = useState([]);
  const [deviationCategoryResult, setDeviationCategoryResult] = useState([]);
  const [supervisorCodeResult, setSupervisorCodeResult] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [file, setFile] = useState([]);
  const [metadata, setMetadata] = useState("");



  const history = useNavigate();
  let username = sessionStorage.getItem("UserName");
  let userid = sessionStorage.getItem("UserId");
  let userrole = sessionStorage.getItem("Role");


  const [weldEnggResult, setWeldEnggResult] = useState([]);

  const handleOnDragOver = (event) => {
    event.preventDefault();
  };

  const [deviationObj, setDeviationObj] = useState(
    action === "Editcapa"
      ? props.rowdata
      : {
          PK: "deviation",
          SK: "deviationNumber#",
          slipNumber: "",
          projectNumber: "",
          deviationNumber: "",
          plantNumber: "",
          jointNumber: "",
          welderCode: "",
          welderName: "",
          supervisorCode: "",
          supervisorName: "",
          supervisorDept: "",
          observerCode: "",
          observerName: "",
          observerDept: "",
          observationDate: "",
          observationTime: "",
          deviationCategory: "",
          deviationCategoryDesc: "",
          deviationSeverityLevel: "",
          deviationText: "",
          deviationImageFile: "",
          devstatus: "Pending for CAPA Analysis",
          rejectstatus:"",
          "ageing(days)": 0,
          updatedBy: "",
          updatedByName: "",
          updatedOn: "",
          createdBy: "",
          createdByName: "",
          createdOn: "",
        }
  );

  const [capaObj, setCapaObj] = useState(
    action === "Editcapa" && Object.keys(props.caparowdata).length !== 0
      ? props.caparowdata
      : {
          PK: "deviation",
          SK: "capa#deviationNumber#",
          deviationNumber: "",
          correctiveAction: "",
          rootCauseAnalysis: "",
          preventiveAction: "",
          actionTakenBy: deviationObj.supervisorCode,
          actionTakenDate: todayDate,
          actionTimeTaken: "",
          lastUpdatedBy: username + " (" + userid + ")",
          lastUpdatedOn: "",
          capaStatus: "Open",
          capaTempFlg: true,
          updatedBy: "",
          updatedOn: "",
          createdBy: "",
          createdOn: "",
        }
  );

  const [error, setError] = useState({
    projectNumber: {},
    plantNumber: {},
    jointNumber: {},
    welderCode: {},
    supervisorCode: {},
    observerCode: {},
    observationDate: {},
    observationTime: {},
    deviationCategory: {},
    deviationSeverityLevel: {},
    deviationText: {},
    deviationImageFile: {},
  });

  const [capaerror, setCapaError] = useState({
    correctiveAction: {},
    rootCauseAnalysis: {},
    preventiveAction: {},
    actionTakenBy: {},
    actionTakenDate: {},
    actionTimeTaken: {},
    capaStatus: {},
  });

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setProject",
    });
  };




  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };

  const handleCloseConf = async () => {
    setshowConfirmModel(false);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (e.target.name === "welderCode") {
      let result = await getAllWelder();
      if (result?.length > 0) {
        let chk = result.filter(
          (x) =>
            x.wld_salarycode === value ||
            x.wld_code.toLowerCase() === value.toLowerCase()
        );
        if (chk?.length > 0) {
          setDeviationObj((prevState) => ({
            ...prevState,
            [name]: value,
            welderName: chk[0].wld_name,
          }));
          setError((prevState) => ({
            ...prevState,
            [name]: {
              status: false,
              message: "",
            },
          }));
        } else {
          setDeviationObj((prevState) => ({
            ...prevState,
            [name]: value,
          }));
          setError((prevState) => ({
            ...prevState,
            [name]: {
              status: true,
              message: "Enter valid welder code.",
            },
          }));
        }
      }
    } else if (e.target.name === "projectNumber") {
      e.target.value = ("" + e.target.value).toUpperCase();
      setDeviationObj((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }));
    } else {
      setDeviationObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError((prevState) => ({
        ...prevState,
        [name]: {},
      }));
    }
  };

  const handleCapaChange = (e) => {
    const { name, value } = e.target;
    setCapaObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setCapaError((prevState) => ({
      ...prevState,
      [name]: {},
    }));
  };

  const validateDeviation = (action) => {

    let isError = false;
    if (deviationObj.deviationText.trim() === "") {
      deviationObj.deviationText = "";
      isError = true;
      error.deviationText = {
        status: true,
        message: "Deviation text is required.",
      };
    }
    if (error.welderCode.status && deviationObj.slipNumber === "") {
      deviationObj.welderCode = "";
      isError = true;
      error.welderCode = {
        status: true,
        message: "Enter valid welder code.",
      };
    }
    if (deviationObj.welderCode.trim() !== "") {
      let error = isAlphanumericWithDashSlash(deviationObj.welderCode);
      if (error.status) {
        deviationObj.welderCode = "";
        isError = true;
        error.welderCode = {
          status: true,
          message: "Enter valid welder code. Only - and / are allowed.",
        };
      }
    } else {
      deviationObj.welderCode = "";
      error.welderCode = {
        status: false,
        message: "",
      };
    }

    if (
      error.plantNumber.status ||
      error.supervisorCode.status ||
      error.projectNumber.status
    ) {
      isError = true;
    }
    return isError;
  };

  const validateCapa = (action) => {

    let isError = false;
    if (capaObj.correctiveAction.trim() === "") {
      capaObj.correctiveAction = "";
      isError = true;
      capaerror.correctiveAction = {
        status: true,
        message: "Corrective Action is required.",
      };
    }
   else if (capaerror.correctiveAction.status) {
      capaObj.correctiveAction = "";
      isError = true;
      capaerror.correctiveAction = {
        status: true,
        message: capaerror.correctiveAction.message,
      };
    } else {
      capaerror.correctiveAction = {
        status: false,
        message: "",
      };
    }
    if (capaObj.rootCauseAnalysis.trim() === "") {
      capaObj.rootCauseAnalysis = "";
      isError = true;
      capaerror.rootCauseAnalysis = {
        status: true,
        message: "Root cause analysis is required.",
      };
    } 
    else if (capaerror.rootCauseAnalysis.status) {
      capaObj.rootCauseAnalysis = "";
      isError = true;
      capaerror.rootCauseAnalysis = {
        status: true,
        message: capaObj.rootCauseAnalysis.message,
      };
    } else {
      capaerror.rootCauseAnalysis = {
        status: false,
        message: "",
      };
    }
    if (capaObj.preventiveAction.trim() === "") {
      capaObj.preventiveAction = "";
      isError = true;
      capaerror.preventiveAction = {
        status: true,
        message: "Preventive Action is required.",
      };
    }
    if (capaerror.preventiveAction.status) {
      capaObj.preventiveAction = "";
      isError = true;
      capaerror.preventiveAction = {
        status: true,
        message: capaerror.preventiveAction.message,
      };
    } else {
      capaerror.preventiveAction = {
        status: false,
        message: "",
      };
    }
    if (capaObj.actionTimeTaken === "") {
      capaObj.actionTimeTaken = "";
      isError = true;
      capaerror.actionTimeTaken = {
        status: true,
        message: "Action Time Taken is required.",
      };
    } else {
      capaerror.actionTimeTaken = {
        status: false,
        message: "",
      };
    }

    setCapaError((prevState) => ({
      ...prevState,
      ...capaerror,
    }));

    if (
      capaerror.correctiveAction.status ||
      capaerror.rootCauseAnalysis.status ||
      capaerror.preventiveAction.status ||
      capaerror.actionTimeTaken.status
    ) {
      isError = true;
    }

    return isError;
  };

  const handleDateChange = (value, name) => {
    let result = false;
    if (name === "lastUpdatedOn" && capaObj.lastUpdatedOn !== "") {
      result = dateCompare(value, deviationObj.observationDate);
    } else if (name === "lastUpdatedOn" && capaObj.lastUpdatedOn !== "") {
      result = dateCompare(deviationObj.observationDate, value);
    }
    if (result) {
      setCapaError((prev) => ({
        ...prev,
        lastUpdatedOn: {
          status: true,
          message:
            "To date should be greater than or equal to Observation Date",
        },
      }));
    } else if (capaObj.lastUpdatedOn !== "") {
      setError((prev) => ({
        ...prev,
        lastUpdatedOn: {
          status: false,
          message: "",
        },
      }));
    }
    if (deviationObj.observationDate !== "") {
      setError((prev) => ({
        ...prev,
        observationDate: {
          status: false,
          message: "",
        },
      }));
    }
    setCapaObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAdd = async (e) => {
    let result;
    if (action === "Add") {
      deviationObj.SK = "deviationNumber#" + deviationObj.deviationNumber;
      deviationObj.createdBy = userid;
      deviationObj.updatedBy = userid;
      deviationObj.updatedByName = username;
      deviationObj.createdByName = username;
      deviationObj.createdOn = getCurrentDateTime("-");
      deviationObj.updatedOn = getCurrentDateTime("-");
      deviationObj.observationDate = getCurrentDateTime("-");
      deviationObj.creatorsL1 = props.usersL1Result != undefined ? props.usersL1Resul?.empEmailId : "";
      deviationObj.creatorsL1Name = props.usersL1Result[0] != undefined ? props.usersL1Result?.empshortName : "";
      deviationObj.creatorsL1SalCode = props.usersL1Result[0] != undefined ? props.usersL1Result?.empSalCode : "";
      deviationObj.weldingEnggId = weldEnggResult;
      result = await RaiseDeviation(deviationObj);
    } else {
      deviationObj.updatedBy = userid;
      deviationObj.updatedByName = username;
      deviationObj.updatedOn = getCurrentDateTime("-");
    }
    if(result.status){
    if (metadata !== "" ) {
      const imgresult = await getPresignedURLDev(
        "Deviations",
        deviationObj.deviationNumber,
        deviationObj.deviationImageFile
      );
       uploadFiles(imgresult.url);
    }
    toast.success(`Deviation details are added successfully`, {
      position: toast.POSITION.TOP_LEFT,
      className: "toast-message",
    });
    props.setReload(!props.reload);
    setShowForm(false);
    props.onHide();
  }else {
      toast.error(`Deviation details are not added`, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      props.setReload(!props.reload);
      setShowForm(false);
      props.onHide();
    }}


  const handleDeviaitonSeverityChange = async (e) => {
    const { name, value } = e.target;
    setDeviationObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFile = async (files) => {
  

    let isError = false;
    const newFile = files;
    setMetadata(files)
    let fileError = Validate(newFile);
    if (fileError === "") {
      setFile(newFile);
    
      let fileName = files.name;
      setDeviationObj((prevState) => ({
        ...prevState,
        deviationImageFile: fileName,
      }));
      setError((prevState) => ({
        ...prevState,
        deviationImageFile: { status: false, message: "" },
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        deviationImageFile: { status: true, message: fileError },
      }));
      return;
    }
    return isError;
  };

  const handleCapaStatusChange = (e) => {
    const { name, value } = e.target;
    setCapaObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fileInputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateDeviation(action)) {
      setshowConfirmModel(true);
    }
  };

  const handleReset = (e) => {
    let result = props.alldeviationsdata;
    let maxval = 0;
    if (result === undefined || result?.length === 0) {
      result = [];
      maxval = 1;
    } else {
      maxval = Math.max(...result?.map((o) => o.deviationNumber)) + 1;
    }
    setDeviationObj({
      PK: "deviation",
      SK: "deviationNumber#",
      slipNumber: "",
      projectNumber: "",
      deviationNumber: maxval,
      plantNumber: "",
      jointNumber: "",
      welderCode: "",
      welderName: "",
      supervisorCode: "",
      supervisorName: "",
      supervisorDept: "",
      observerCode: "",
      observerName: "",
      observerDept: "",
      observationDate: "",
      observationTime: "",
      deviationCategory: "",
      deviationCategoryDesc: "",
      deviationSeverityLevel: "",
      deviationText: "",
      deviationImageFile: "",
      devstatus: "Pending for CAPA Analysis",
      rejectstatus:"",
      "ageing(days)": 0,
      updatedBy: "",
      updatedByName: "",
      updatedOn: "",
      createdBy: "",
      createdByName: "",
      createdOn: "",
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  const handleOndrop = (event) => {
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  };

  const handleSaveCapa = async (e) => {
    let result;
  
    if (e.target.name === "save") {
      if (!validateCapa(action)) {
        debugger
        if (e.target.name === "save" && capaObj.capaStatus === "Open") {
          capaObj.capaTempFlg = false;
          deviationObj.devstatus = "Pending for Closure";
          deviationObj.updatedBy = userid;
          deviationObj.updatedOn = todayDate;
           result = await RaiseDeviation(deviationObj);
        } else if (e.target.name === "save" && capaObj.capaStatus === "Closed") {
          capaObj.capaTempFlg = false;
          deviationObj.devstatus = "Closed";
          deviationObj.updatedBy = userid;
          deviationObj.updatedOn = todayDate;
           result = await RaiseDeviation(deviationObj);
        }
        else if  (e.target.name === "save" && capaObj.capaStatus === "Reject" &&  deviationObj.rejectstatus !== "yes") { 
          capaObj.capaTempFlg = true;
          deviationObj.devstatus = "Rejected";
          deviationObj.rejectstatus = "yes"
          deviationObj.updatedBy = userid;
          deviationObj.updatedOn = todayDate;
           result = await RaiseDeviation(deviationObj);
        }
        else if  (e.target.name === "save" && deviationObj.devstatus === "Rejected" && deviationObj.rejectstatus === "yes") { 
      capaObj.capaTempFlg = false;
      deviationObj.devstatus = "Pending for Closure";
      deviationObj.updatedBy = userid;
      deviationObj.updatedOn = todayDate;
       result = await RaiseDeviation(deviationObj);
    }
        if (action === "Editcapa") {
          capaObj.SK = "capa#deviationNumber#" + deviationObj.deviationNumber;
          capaObj.deviationNumber = deviationObj.deviationNumber;
          capaObj.createdBy = userid;
          capaObj.updatedBy = userid;
          capaObj.createdOn = todayDate;
          capaObj.updatedOn = todayDate;
          capaObj.lastUpdatedOn = todayDate;
          result = await addCapa(capaObj);
        }
        if (result?.status) {
          if (e.target.name === "save" && capaObj.capaStatus === "Closed") {
             sendClosureEmail(deviationObj, capaObj);
          }
          if (e.target.name === "save" && capaObj.capaStatus === "Reject") {
             sendClosureEmail(deviationObj, capaObj);
          }
          toast.success(`Capa details are added successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          props.setReload(!props.reload);
          setShowForm(false);
          props.onHide();
        } else {
          toast.error(`Capa details are not added`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
      }
    } else if (e.target.name === "tempsave") {
      if (e.target.name === "tempsave") {
        capaObj.capaTempFlg = true;
      }
      if (action === "Editcapa") {
        capaObj.SK = "capa#deviationNumber#" + deviationObj.deviationNumber;
        capaObj.deviationNumber = deviationObj.deviationNumber;
        capaObj.createdBy = userid;
        capaObj.updatedBy = userid;
        capaObj.createdOn = todayDate;
        capaObj.updatedOn = todayDate;
        capaObj.lastUpdatedOn = todayDate;
        result = await addCapa(capaObj);
      }
      if (result?.status) {
        if (e.target.name === "save" && capaObj.capaStatus === "Closed") {
           sendClosureEmail(deviationObj, capaObj);
        }
        if (e.target.name === "save" && capaObj.capaStatus === "Reject") {
          sendClosureEmail(deviationObj, capaObj);
        }
        toast.success(`Capa details are added successfully`, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      } else {
        toast.error(`Capa details are not added`, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
    }
  };

  const uploadFiles = async (signedURL) => {
    try {
      if (signedURL) {
        await axios.put(signedURL, file);
      }
      return "";
    } catch (error) {
      return " Error in file upload.";
    }
  };

  const handleDownloadDocument = async (fileName) => {
    downloadAttachment(
      { foldername: "Deviations", 
      subfoldername: deviationObj.deviationNumber,
       filename: fileName }).catch((err) => {
      toast.error(`Error in fetching file.`, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    });
  };

  const handleDeleteDocEvent = async (fileName) => {
    setDeviationObj((prevState) => ({
      ...prevState,
      deviationImageFile: "",
    }));
  };

  const handleDropDownChange = async (e) => {
    const { name, value } = e.target;
    if (e.target.name === "slipNumber") {
      let WIresult = await getWIDataHeader({ WI_Slip: e.target.value });
      if (WIresult?.length > 0) {
        setDeviationObj((prevState) => ({
          ...prevState,
          slipNumber: WIresult[0].WI_Slip,
          jointNumber: WIresult[0].WI_Joint_No,
          plantNumber: WIresult[0].WI_plant,
          projectNumber: WIresult[0].WI_Project_No,
          welderCode: WIresult[0].WI_Welder_Id,
          welderName: WIresult[0].WI_Welder_Name,
        }));
      }
    } else if (e.target.name === "supervisorCode") {
      let roledesc1 = "";
      let nm = e.target[e.target.selectedIndex].getAttribute("datakey");
      let cd = value;
      let roledesc = await getRoleDescByRoleId(
        e.target.children[e.target.selectedIndex].getAttribute("result")
      );
      if (roledesc?.length > 0) roledesc1 = roledesc[0].rm_name;

      setDeviationObj((prevState) => ({
        ...prevState,
        supervisorCode: cd,
        supervisorName: nm,
        supervisorDept: roledesc1,
      }));
  
    } else if (e.target.name === "deviationCategory") {
      if (value !== "") {
        setDeviationObj((prevState) => ({
          ...prevState,
          [name]: value,
          deviationCategoryDesc:
            e.target.children[e.target.selectedIndex].getAttribute("result"),
        }));
      } else {
        setDeviationObj((prevState) => ({
          ...prevState,
          [name]: "",
          deviationCategoryDesc: "",
        }));
      }
    } else if (e.target.name === "deviationSeverityLevel") {
      if (value !== "") {
        setDeviationObj((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setDeviationObj((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      }
    } else if (e.target.name === "observerCode") {
      let roledesc1 = "";
      let nm = e.target[e.target.selectedIndex].getAttribute("datakey");
      let cd = value;
      let roledesc = await getRoleDescByRoleId(
        e.target.children[e.target.selectedIndex].getAttribute("result")
      );
      if (roledesc?.length > 0) roledesc1 = roledesc[0].rm_name;

      setDeviationObj((prevState) => ({
        ...prevState,
        observerCode: cd,
        observerName: nm,
        observerDept: roledesc1,
      }));
    } else {
      setDeviationObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setError((prevState) => ({
      ...prevState,
      [name]: {},
    }));
  };

  const fetchSlipNumbers = async () => {
    let result = await getAllOnlySlipNos();
    result?.sort(sortByPropertyInAscending("WI_Slip"));
    let temp = [];
    if (result !== undefined) {
      for (let item of result) {
        temp.push({ Name: item.WI_Slip, Code: item.WI_Slip });
      }
    }
    setSlipNumberResult(temp);
  };

  const fetchSupervisorCode = async () => {
    let roles = [{ role: process.env.REACT_APP_ROLE_SUPERVISOR }];
    let result = await getAllUsersByRole(roles);
    let temp = [];
    if (result !== undefined) {
      for (let item of result) {
        temp.push({ Name: item.um_name, Code: item.SK, Dept: item.um_role });
      }
    }
    setSupervisorCodeResult(temp);
  };

  const fetchUsersWE = async () => {
    let roles = [{ role: process.env.REACT_APP_ROLE_WEDLINGENGINEER }];
    let result = await getAllUsersByRole(roles);

    let temp = [];
    if (result !== undefined) {
      for (let item of result) {
        temp.push(item.SK);
      }
    }
    setWeldEnggResult(temp);
  };


  const fetchObserverCode = async () => {
    let roles = [
      { role: process.env.REACT_APP_ROLE_WEDLINGENGINEER },
      { role: process.env.REACT_APP_ROLE_QCENGINEER },
    ];
    let result = await getAllUsersByRole(roles);
    let temp = [];
    if (result !== undefined) {
      for (let item of result) {
        temp.push({ Name: item.um_name, Code: item.SK, Dept: item.um_role });
      }
    }
    setObserverCodeResult(temp);
  };

  const fetchDeviationCategory = async () => {
    let result = await getDeviationCategories();
    let temp = [];
    if (result !== undefined) {
      for (let item of result) {
        temp.push({
          Name: item.devCategory,
          Code: item.devCategory,
          CategoryDesc: item.devCategoryDesc,
        });
      }
    }
    setDeviationCategoryResult(temp);
  };

  useEffect(() => {
    let result = props.alldeviationsdata;
    let maxval = 0;
    if (result === undefined || result?.length === 0) {
      result = [];
      maxval = 1;
    } else {
      maxval = Math.max(...result?.map((o) => o.deviationNumber)) + 1;
    }
    if (action === "Add") {
      setDeviationObj((prevState) => ({
        ...prevState,
        deviationNumber: maxval,
      }));
    }
    let caparesult = props.caparowdata;
    if (Object.keys(caparesult).length !== 0) {
      setCapaObj(caparesult);
    }
    fetchSlipNumbers();
    fetchSupervisorCode();
    fetchObserverCode();
    fetchDeviationCategory();
    fetchUsersWE();
  }, []);

  function sortByPropertyInAscending(property) {
    return function (a, b) {
      if (Number(a[property]) > Number(b[property])) return 1;
      else if (Number(a[property]) < Number(b[property])) return -1;
      return 0;
    };
  }

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed Project successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Raise Deviation</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row className="gx-3">
                <Col md={4} xs={12}>
                  <Form.Group className="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="slipNumber"
                        name="slipNumber"
                        type="text"
                        placeholder=" "
                        value={deviationObj.slipNumber}
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                        disabled={action === "Editcapa" ? true : false}
                      >
                        <option value="">Select</option>
                        {slipNumberResult
                          ? slipNumberResult.map((slipNum) => (
                              <option key={slipNum.Code} value={slipNum.Name}>
                                {slipNum.Name}
                              </option>
                            ))
                          : null}
                      </Form.Select>
                      <label for="slipNumber">
                        Slip Number <span className="italic">(Optional)</span>
                      </label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="projectNumber"
                        name="projectNumber"
                        type="text"
                        maxLength="10"
                        readOnly={
                          action === "Editcapa"
                            ? true
                            : deviationObj.slipNumber !== ""
                            ? true
                            : false
                        }
                        placeholder=" "
                        value={deviationObj.projectNumber}
                        onChange={handleChange}
                        onBlur={(e) => {
                          let error = isAlphanumericWithDash(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            projectNumber: error,
                          }));
                        }}
                      />
                      <label for="projectNumber">Project Number</label>
                      {error.projectNumber.status && (
                        <Form.Text className="text-danger">
                          {error.projectNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="deviationNumber"
                        name="deviationNumber"
                        type="text"
                        maxLength="50"
                        readOnly
                        value={deviationObj.deviationNumber}
                      />
                      <label for="deviationNumber">Deviation Number</label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="plantNumber"
                        name="plantNumber"
                        type="text"
                        maxLength="10"
                        placeholder=" "
                        value={deviationObj.plantNumber}
                        onChange={handleChange}
                        readOnly={
                          action === "Editcapa"
                            ? true
                            : deviationObj.slipNumber !== ""
                            ? true
                            : false
                        }
                        onBlur={(e) => {
                          let error = isAlphanumericWithDashSlash(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            plantNumber: error,
                          }));
                        }}
                      />
                      <label for="plantNumber">Plant Number</label>
                      {error.plantNumber.status && (
                        <Form.Text className="text-danger">
                          {error.plantNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="jointNumber"
                        name="jointNumber"
                        type="text"
                        maxLength="10"
                        placeholder=" "
                        value={deviationObj.jointNumber}
                        onChange={handleChange}
                        readOnly={
                          action === "Editcapa"
                            ? true
                            : deviationObj.slipNumber !== ""
                            ? true
                            : false
                        }
                        onBlur={(e) => {
                          let error = isAlphanumericWithDashSlash(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            jointNumber: error,
                          }));
                        }}
                      />
                      <label for="jointNumber">Joint Number</label>
                      {error.jointNumber.status && (
                        <Form.Text className="text-danger">
                          {error.jointNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="welderCode"
                        name="welderCode"
                        type="text"
                        maxLength="10"
                        readOnly={
                          action === "Editcapa"
                            ? true
                            : deviationObj.slipNumber !== ""
                            ? true
                            : false
                        }
                        placeholder=" "
                        value={deviationObj.welderCode}
                        onChange={handleChange}
                      />
                      <label for="welderCode">Welder Code</label>
                      {error.welderCode.status && (
                        <Form.Text className="text-danger">
                          {error.welderCode.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="supervisorCode"
                        name="supervisorCode"
                        type="text"
                        style={{
                          whiteSpace: "break-spaces",
                          paddingLeft: "15px",
                        }}
                        required
                        placeholder=" "
                        value={deviationObj.supervisorCode}
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                        disabled={action === "Editcapa" ? true : false}
                      >
                        <option value="">Select</option>
                        {supervisorCodeResult
                          ? supervisorCodeResult.map((supe) => (
                              <option
                                result={supe.Dept}
                                datakey={supe.Name}
                                value={supe.Code}
                              >
                                {supe.Name + " (" + supe.Code + ")"}
                              </option>
                            ))
                          : null}
                      </Form.Select>
                      <label for="supervisorCode">Supervisor Code</label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="supervisorDept"
                        name="supervisorDept"
                        type="text"
                        readOnly
                        placeholder=" "
                        value={deviationObj.supervisorDept}
                      />
                      <label for="supervisorDept">Supervisor Department</label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="observerCode"
                        name="observerCode"
                        type="text"
                        style={{
                          whiteSpace: "break-spaces",
                          paddingLeft: "15px",
                        }}
                        class="observerCode"
                        required
                        className={
                          error.observerCode.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={deviationObj.observerCode}
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                        disabled={action === "Editcapa" ? true : false}
                      >
                        <option value="">Select</option>
                        {observerCodeResult
                          ? observerCodeResult.map((observe) => (
                              <option
                                result={observe.Dept}
                                datakey={observe.Name}
                                value={observe.Name}
                              >
                                {observe.Name + " (" + observe.Code + ")"}
                              </option>
                            ))
                          : null}
                      </Form.Select>
                      <label for="observerCode">Observer Code</label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="observerDept"
                        name="observerDept"
                        type="text"
                        readOnly
                        placeholder=" "
                        value={deviationObj.observerDept}
                      />
                      <label for="observerDept">Observer Department</label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} xs={12}>
                  <div className="groove_date">
                    <div class="Date-input w-100">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Observation Date"
                            value={
                              deviationObj.observationDate
                                ? dayjs(deviationObj.observationDate)
                                : dayjs(todayDate)
                            }
                            disabled
                            format="DD-MM-YYYY"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="observationTime"
                        name="observationTime"
                        type="Time"
                        maxLength="5"
                        required
                        readOnly={action === "Editcapa" ? true : false}
                        placeholder=" "
                        value={deviationObj.observationTime}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithDash(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            observationTime: error,
                          }));
                        }}
                      />
                      <label for="observationTime">Observation Time</label>
                      {error.observationTime.status && (
                        <Form.Text className="text-danger">
                          {error.observationTime.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="deviationCategory"
                        name="deviationCategory"
                        type="text"
                        class="deviationCategory"
                        required
                        placeholder=" "
                        value={deviationObj.deviationCategory}
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                        disabled={action === "Editcapa" ? true : false}
                      >
                        <option value="">Select</option>
                        {deviationCategoryResult
                          ? deviationCategoryResult.map(
                              (deviationCategoryResult) => (
                                <option
                                  key={deviationCategoryResult.Code}
                                  result={deviationCategoryResult.CategoryDesc}
                                  value={deviationCategoryResult.Name}
                                >
                                  {deviationCategoryResult.Name}
                                </option>
                              )
                            )
                          : null}
                      </Form.Select>
                      <label for="deviationCategory">Deviation Category</label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={8} md={8} xs={12}>
                  <Form.Group class="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="deviationCategoryDesc"
                        name="deviationCategoryDesc"
                        type="text"
                        readOnly
                        placeholder=" "
                        value={deviationObj.deviationCategoryDesc}
                      />
                      <label for="deviationCategoryDesc">
                        Deviation Category Description
                      </label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group class="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="deviationSeverityLevel"
                        name="deviationSeverityLevel"
                        type="text"
                        placeholder=" "
                        required
                        value={deviationObj.deviationSeverityLevel}
                        onChange={(e) => {
                          handleDeviaitonSeverityChange(e);
                        }}
                        disabled={action === "Editcapa" ? true : false}
                      >
                        <option value="">Select</option>
                        <option key="1" value="1">
                          1
                        </option>
                        <option key="2" value="2">
                          2
                        </option>
                        <option key="3" value="3">
                          3
                        </option>
                        <option key="4" value="4">
                          4
                        </option>
                        <option key="5" value="5">
                          5
                        </option>
                      </Form.Select>
                      <label for="deviationSeverityLevel">
                        Deviation Severity Level
                      </label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4}></Col>
                <Col lg={4}></Col>
                <Col lg={12} md={12} xs={12}>
           
                    <div class="T-input w-100">
                <textarea
                  type="text"
                  name="deviationText"
                  style={{ height: "40px" }}
                  id="deviationText"
                  readOnly={action === "Editcapa" ? true : false}
                  maxLength={500}
                  placeholder=" "
                 
                  value={deviationObj.deviationText}
                  onChange={handleChange}
                  onBlur={(e) => {
                    let error =
                      isAlphanumericWithDotSpaceDashCommaBracketsSlashAmpersand(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      deviationText: error,
                    }));
                  }}
                />
                  <label for="deviationText">Deviation Text</label>
                      {error.deviationText.status && (
                        <Form.Text className="text-danger">
                          {error.deviationText.message}
                        </Form.Text>
                      )}     
                               </div>
                </Col>
                <Col md={12} xs={12}>
                  <Form.Group>
                    {deviationObj.deviationImageFile === "" &&
                    action !== "Editcapa" ? (
                      <>
                        <label className="fileuploadTitle" >Upload Image</label>
                        <form encType="multipart/form-data" style={{position:"relative",top:"10px"}} >
                          <div className="pic_upload_deviation" >
                            <div
                              className="drop_zone"
                              onDragOver={handleOnDragOver}
                              onDrop={handleOndrop}
                            >
                              <label
                                className="pic_text_black_deviation"
                                for="fileInput"
                                style={{fontSize:"14px", position:"relative", top:"8px"}}
                              >
                                Upload image here or
                              </label>{" "}
                              <label
                                className="pic_text_blue_deviation"
                                for="fileInput"
                                style={{fontSize:"14px", position:"relative", top:"8px"}}

                              >
                                Select a file
                              </label>
                              <br></br>
                              <label className="pic_text_weld_deviation"                                 style={{fontSize:"12px"}}
>
                                Supports: Jpeg, PNG and JPG, Size:Max 1MB
                              </label>
                              <br></br>
                              <label className="pic_text_optional_deviation italic"                                 style={{fontSize:"12px", position:"relative", bottom:"8px"}}
>
                                (Optional)
                              </label>
                              <input
                                id="fileInput"
                                name="fileInput"
                                type="file"
                                accept="image/*"
                                hidden
                                ref={fileInputRef}
                                onChange={(e) => handleFile(e.target.files[0])}
                              />
                            </div>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <label className="fileuploadTitle">Upload Image</label>
                        <Col lg={12} md={12} xs={12}>
                          <label
                            className="add-new-link"
                            onClick={() =>
                              handleDownloadDocument(
                                deviationObj.deviationImageFile
                              )
                            }
                          >
                            {deviationObj.deviationImageFile === ""
                              ? "No file chosen"
                              : deviationObj.deviationImageFile}
                            &nbsp;&nbsp;&nbsp;
                          </label>
                          {/* {!(action === "Editcapa") && (
                            <img
                              onMouseOver={(e) =>
                                (e.currentTarget.src = Delete)
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = DeleteGrey)
                              }
                              src={DeleteGrey}
                              alt="Delete"
                              onClick={() =>
                                handleDeleteDocEvent(
                                  deviationObj.deviationImageFile
                                )
                              }
                            />
                          )} */}
                            <img
                              onMouseOver={(e) =>
                                (e.currentTarget.src = Delete)
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = DeleteGrey)
                              }
                              src={DeleteGrey}
                              alt="Delete"
                              onClick={() =>
                                handleDeleteDocEvent(
                                  deviationObj.deviationImageFile
                                )
                              }
                            />
                        </Col>
                      </>
                    )}
                    {error.deviationImageFile.status && (
                      <Form.Text className="text-danger">
                        {error.deviationImageFile.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
            <div className="px-2 pb-2">
              {action === "Editcapa" ? (
                <>
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                     
                      <div class="T-input w-100">
                <textarea
                  type="text"
                  name="correctiveAction"
                  style={{ height: "40px" }}
                  id="correctiveAction"
                  readOnly={capaObj.capaTempFlg ? false : true}
                  maxLength={500}
                  placeholder=" "
              
                  value={capaObj.correctiveAction}
                  onChange={handleCapaChange}
                  onBlur={(e) => {
                    let error =
                      isAlphanumericWithDotSpaceDashCommaBracketsSlashAmpersand(
                        e.target.value
                      );
                    setCapaError((prevState) => ({
                      ...prevState,
                      correctiveAction: error,
                    }));
                  }}
                />
                 <label for="correctiveAction">
                            Corrective Action
                          </label>
                          {capaerror.correctiveAction.status && (
                            <Form.Text className="text-danger">
                              {capaerror.correctiveAction.message}
                            </Form.Text>
                          )}
                               </div>
                    </Col>
                    <Col lg={12} md={12} xs={12}>
                   
                      <div class="T-input w-100">
                <textarea
                  type="text"
                  name="rootCauseAnalysis"
                  style={{ height: "40px" }}
                  id="rootCauseAnalysis"
                  maxLength={500}
                  
                  readOnly={capaObj.capaTempFlg ? false : true}
                  placeholder=" "
                  value={capaObj.rootCauseAnalysis}
                  onChange={handleCapaChange}
                  onBlur={(e) => {
                    let error =
                      isAlphanumericWithDotSpaceDashCommaBracketsSlashAmpersand(
                        e.target.value
                      );
                    setCapaError((prevState) => ({
                      ...prevState,
                      rootCauseAnalysis: error,
                    }));
                  }}
                />
                <label for="rootCauseAnalysis">
                            Root Cause Analysis
                          </label>
                          {capaerror.rootCauseAnalysis.status && (
                            <Form.Text className="text-danger">
                              {capaerror.rootCauseAnalysis.message}
                            </Form.Text>
                          )}
                               </div>
                    </Col>
                    <Col lg={12} md={12} xs={12}>
                    

                    <div class="T-input w-100">
                <textarea
                  type="text"
                  name="preventiveAction"
                  style={{ height: "40px" }}
                  id="preventiveAction"
                  maxLength={500}
                 
                  readOnly={capaObj.capaTempFlg ? false : true}
                  placeholder=" "
                  value={capaObj.preventiveAction}
                  onChange={handleCapaChange}
                  onBlur={(e) => {
                    let error =
                      isAlphanumericWithDotSpaceDashCommaBracketsSlashAmpersand(
                        e.target.value
                      );
                    setCapaError((prevState) => ({
                      ...prevState,
                      preventiveAction: error,
                    }));
                  }}
                />
                 <label for="preventiveAction">
                            Prevention Action
                          </label>
                          {capaerror.preventiveAction.status && (
                            <Form.Text className="text-danger">
                              {capaerror.preventiveAction.message}
                            </Form.Text>
                          )}
                               </div>
                    </Col>
                    <Col lg={4} md={4} xs={12}>
                     
                     
                   <Form.Group class="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="actionTakenBy"
                        name="actionTakenBy"
                        type="text"
                        style={{
                          whiteSpace: "break-spaces",
                          paddingLeft: "15px",
                        }}
                        required
                        placeholder=" "
                        value={deviationObj.actionTakenBy}
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                   
                      >
                        <option value="">Select</option>
                        {supervisorCodeResult
                          ? supervisorCodeResult.map((supe) => (
                              <option
                                result={supe.Dept}
                                datakey={supe.Name}
                                value={supe.Code}
                              >
                                {supe.Name + " (" + supe.Code + ")"}
                              </option>
                            ))
                          : null}
                      </Form.Select>
                      <label for="supervisorCode">Action Taken By</label>
                    </div>
                  </Form.Group>
                    </Col>
                    <Col lg={4} md={4} xs={12}>
                      <div className="groove_date">
                        <div class="Date-input w-100">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                label="Action Taken Date"
                                value={
                                  capaObj.actionTakenDate
                                    ? dayjs(capaObj.actionTakenDate)
                                    : dayjs(todayDate)
                                }
                                disabled={capaObj.capaTempFlg ? false : true}
                                onChange={(newValue) => {
                                  let date =
                                    dayjs(newValue).format("YYYY-MM-DD");
                                  handleDateChange(date, "actionTakenDate");
                                }}
                                format="DD-MM-YYYY"
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          {capaerror.actionTakenDate.status && (
                            <Form.Text className="text-danger">
                              {capaerror.actionTakenDate.message}
                            </Form.Text>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} md={4} xs={12}>
                      <Form.Group class="g-input">
                        <div className="d-grid align-items-center">
                          <Form.Control
                            id="actionTimeTaken"
                            name="actionTimeTaken"
                            type="Time"
                            maxLength="5"
                            required
                            readOnly={capaObj.capaTempFlg ? false : true}
                            placeholder=" "
                            value={capaObj.actionTimeTaken}
                            onChange={handleCapaChange}
                          />
                          <label for="actionTimeTaken">Action Time Taken</label>
                          {capaerror.actionTimeTaken.status && (
                            <Form.Text className="text-danger">
                              {capaerror.actionTimeTaken.message}
                            </Form.Text>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={4} xs={12}>
                      <Form.Group class="g-input">
                        <div className="d-flex align-items-center">
                          <Form.Control
                            id="lastUpdatedBy"
                            name="lastUpdatedBy"
                            type="text"
                            maxLength="50"
                            readOnly
                            placeholder=" "
                            value={capaObj.lastUpdatedBy}
                          />
                          <label for="lastUpdatedBy">Last Updated By</label>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={4} xs={12}>
                      <div className="groove_date">
                        <div class="Date-input w-100">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                label="Last Updated Date"
                                value={
                                  capaObj.lastUpdatedOn
                                    ? dayjs(capaObj.lastUpdatedOn)
                                    : dayjs(todayDate)
                                }
                                readOnly
                                format="DD-MM-YYYY"
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} md={4} xs={12}>
                      <Form.Group class="g-select">
                        <div className="d-grid align-items-center">
                          <Form.Select
                            id="capaStatus"
                            name="capaStatus"
                            type="text"
                            disabled={
                              capaObj.capaTempFlg
                                ? true
                                : userrole === "WE" &&
                                  deviationObj.devstatus !== "Closed"
                                ? false
                                : true
                            }
                            placeholder=" "
                            required
                            value={capaObj.capaStatus}
                            onChange={(e) => {
                              handleCapaStatusChange(e);
                            }}
                          >
                            <option key="Open" value="Open">
                              Open
                            </option>
                            <option key="Closed" value="Closed">
                              Closed
                            </option>
                            <option key="Reject" value="Reject">
                              Reject
                            </option>
                          </Form.Select>
                          <label for="capaStatus">Status</label>
                          {capaerror.capaStatus.status && (
                            <Form.Text className="text-danger">
                              {capaerror.capaStatus.message}
                            </Form.Text>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {deviationObj.devstatus !== "Closed" ? (
                      <>
                        <Col>
                          <Button
                            type="button"
                            name="tempsave"
                            id="tempsave"
                            className="semiBoldToggleButton tempSaveBtn"
                            onClick={handleSaveCapa}
                          >
                            Temp Save
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="button"
                            name="save"
                            id="save"
                            className="btn-add-color semiBoldToggleButton rightAlignedButton"
                            onClick={handleSaveCapa}
                          >
                            Save
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </>
              ) : (
                <Row className="formobile" style={{position:"relative",top:"20px"}} >
                  <Col>
                    <Button
                      variant="outline-success"
                      type="reset"
                      className="semiBoldToggleButton tempSaveBtn"
                      
                    >
                      Reset
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="submit"
                      className="btn-add-color semiBoldToggleButton rightAlignedButton"
                    >
                      Raise
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Modal.Body>
          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>
      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddRaiseDeviationCapa;
