import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";

import SuccessAlertModal from "../Modals/successAlertModal";

import { useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";


import { Button, Row, Col, Container } from "react-bootstrap";
import Export from "../../icons/Export.svg";



import GlobalColumnDefinations, { getHeader_FieldName } from "../utility/AgGridDefination";

import "react-toastify/dist/ReactToastify.css";

import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";

import { getwpqlink } from "../../Services/WI-services";
import { RenderIf } from "../../functions/welding-functions";
import MultiValuePopUp from "../utility/MultiValuePopUp";
import { GridLoader } from "react-spinners";
import ActionRendererWPQLinking from "../utility/ActionRendererWPQLinking";

const Wpq_linking = forwardRef((props, ref) => {
 

  const [welData, setWelData] = useState({});


  const [filteredResult, setFilteredResult] = useState([]);
 

  const [columnDefs, setColumnDefs] = useState([]);
  const [Groove, setGroove] = useState(null);


  const [showForm, setShowForm] = useState(false);







  const [excelData1, setExcelData] = useState([]);






  const navigate = useNavigate();










  const [loading, setLoading] = useState(true);


  const [JointType, setJointType] = useState("");

  const [WIWelderid, setWIWelderid] = useState("");



  const childRef = useRef();

  const year = new Date().getFullYear()
  const[selectedYear,setSelectedYear] = useState(year) 







  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });


  const showPopUp = (name, type, key, value) => {
    setPopUp({ name, type, key, value });
    setShowForm(true);
  };


  const WPQ_Linking_Radio = {
    Linked: "Link WPQ with Project",
    UnLinked: "Linking WPQ with project not Required",
  };

  const HyperLink = (params, row) => {


    return (
      <Button
      variant="link"
      onClick={() => {
        setJointType(params.data.WI_Joint_Type);
        setWIWelderid(params.data.WI_WPQ_Data);
        setWelData(params.data);
    
        if (params.data.WI_Wpq_Radio !== WPQ_Linking_Radio.UnLinked) {
          const welderIds = params.data.WI_Welder_Id.split(",");
          const filteredData = params.data.WI_WPQ_Data.filter(item => welderIds.includes(item.WPQ_WelderId));
          showPopUp(
            "WPQLinkGrid",
            "Multiple",
            "WI_Welder_Id",
            filteredData
          );
        }
      }}
      size="sm"
    >
      {params.data.WI_Welder_Id}
    </Button>
    );
  };


  const tmpcolumnDefs = [

    {
      headerName: "Project No.",
      field: "WI_Project_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Joint Type",
      field: "WI_Joint_Type",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Joint No.",
      field: "WI_Joint_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Linked WPQs",
      field: "WI_Welder_Id",
      type: "Text",
      isCheckbox: false,
      cellRenderer: HyperLink,
    },
   
  ];


  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };




const fetchLinking = async () => {
  try {
      
      
 
      const result = await getwpqlink();
      console.log("NA",result)
      
      if (result && result.length > 0) {
          const filter = result.filter(item => {
              if (item.WI_Createdon) {
                  const yearPart = item.WI_Createdon.split("-")[0];
                  return yearPart === selectedYear.toString();
              }
              return false;
          });
          

          setGroove(filter);
          
          setFilteredResult(filter);
      
          fetchColumnDefns();
      }
      setLoading(false);
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};


  const fetchColumnDefns =  () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);


    result[3].cellRenderer = HyperLink;

    result.push({
      headerName: "",
      cellRenderer: actionRenderer,
      minWidth: 250,
      suppressMenu: true,
      sortable:false,
    });
    setColumnDefs(result);
  };

  useEffect(() => {
   
    fetchLinking();
    
  }, [selectedYear]);




  function handleClick() {
      navigate("/Wpqlinking/AddEdit", {
        state: { data: "", action: "Add" },
      });
  }

  function handleClickedit(row) {


      navigate("/Wpqlinking/AddEdit", {
        state: {
          data: row,
          action: "Edit",
        },
      });
   
    
  }


  useImperativeHandle(ref, () => ({
    handleAdd: () => {
      handleClick();
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(Groove);
  };


  const [showSuccess, setShowSuccess] = useState(false);
 const msg = "";
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setGroove",
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);



  const handleGlobalSearch = (text) => {
   
    childRef.current.setSearch(text);
  };




  
  const handleGlobalExport = () => {

    const allFields = [
      'WI_Project_No', 
      'WI_Joint_Type', 
      'WI_Joint_No', 
      "WPQ_Inspection",
      "WPQ_PlantLocation",
      "WPQ_Qualificationcode",
      "WPQ_specialNote",
     
    ];
  
    // Create column definitions for all fields
    const allColumnDefs = allFields.map(field => ({
      headerName: field,
      field: field,
    }));
  
    // Add a column for WPQ_WelderName
    allColumnDefs.push({
      headerName: 'WPQ_WelderName',
      field: 'WPQ_WelderName',
    });

    allColumnDefs.push({
      headerName: 'WPQ_WelderId',
      field: 'WPQ_WelderId',
    });

    allColumnDefs.push({
      headerName: 'WPQ_RecordNo',
      field: 'WPQ_RecordNo',
    });

    allColumnDefs.push({
      headerName: 'WPQ_Inspection',
      field: 'WPQ_Inspection',
    });

    allColumnDefs.push({
      headerName: 'WPQ_PlantLocation',
      field: 'WPQ_PlantLocation',
    });

    allColumnDefs.push({
      headerName: 'WPQ_Qualificationcode',
      field: 'WPQ_Qualificationcode',
    });

    allColumnDefs.push({
      headerName: 'WPQ_specialNote',
      field: 'WPQ_specialNote',
    });
  
    // Process filteredResult to include WPQ_WelderName
    const processedResult = filteredResult.map(item => {
      // Join all WPQ_WelderName values into a single string
      const welderId = item.WI_WPQ_Data.map(wpq => wpq.WPQ_WelderId).join(', ');

      const welderNames = item.WI_WPQ_Data.map(wpq => wpq.WPQ_WelderName).join(', ');

      const welderRecordno = item.WI_WPQ_Data.map(wpq => wpq.WPQ_RecordNo).join(', ');

      const WelderInspection = item.WI_WPQ_Data.map(wpq => wpq.WPQ_Inspection).join(', ');

      const welderPlantlocation = item.WI_WPQ_Data.map(wpq => wpq.WPQ_PlantLocation).join(', ');

      const welderQualificationcode = item.WI_WPQ_Data.map(wpq => wpq.WPQ_Qualificationcode).join(', ');

      const welderSpecialnote = item.WI_WPQ_Data.map(wpq => wpq.WPQ_specialNote).join(', ');


  
      // Return a new object with the original data and the new WPQ_WelderName field
      return {
        ...item,
        WPQ_WelderId: welderId,
        WPQ_WelderName: welderNames,
        WPQ_RecordNo: welderRecordno,
        WPQ_Inspection:WelderInspection,
        WPQ_PlantLocation:welderPlantlocation,
        WPQ_Qualificationcode:welderQualificationcode,
        WPQ_specialNote:welderSpecialnote,
        

      };
    });
  
    let filename = "Project to WPQ linking - Groove, Overlay, Tube to Tubesheet";
    
  };




  const actionRenderer = (params) => {
    return (
      <ActionRendererWPQLinking
        selectedData={params}
        handleEditEvent={(params) => handleClickedit(params.data)}
      />
    );
  };








  
  const handleYearChange = (e) =>{

     setSelectedYear(e.value)
   }

  return (
    <>
    {loading && (
      <div className="loader-overlay">
        <GridLoader size={10} className="pageloader" />
      </div>
    )}
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          WPQ Transaction list
        </Col>

        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for " +
                " Project No, Joint Type, Joint No, Linked WPQ"
              }
             
              withYear={true}
              filterFunc={handleGlobalSearch}
              YearHandleChange={handleYearChange}
              YearSelected={selectedYear} 
            />
            <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>


      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={setExcelData}
        ref={childRef}
      />


<RenderIf isTrue={popUp.type === "Multiple" && showForm}>
          <MultiValuePopUp
            show={showForm}
            onHide={() => setShowForm(false)}
            // setSelected={setPopUpData}
            masterName={popUp.name}
            paramkey={popUp.key}
            paramvalue={popUp.value}
            backdrop="static"
            JointType={JointType}
            WIWelderid={WIWelderid}
            wiobj={welData}
            // action={Edit}
          />
        </RenderIf>

      
      <Toaster />
    </Container>
    </>
  );
});

export default Wpq_linking;
